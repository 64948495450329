import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";
import { ConfirmDialogBox } from "../../../../../../src/app/admin/confirm-dialog/confirm-dialogbox";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent {
  @Output() AfterProcessStopped = new EventEmitter<any>();
  @Output() afterSynced = new EventEmitter<any>();
  @Input() totalCount:any;
  @Output() pageChange = new EventEmitter<any>();
  displayedColumns: string[] = [
    "S. No",
    "Upload id",
    "Name",
    "added_date",
    "Updated_date",
    "count",
    "new_count",
    "Log_files",
    "Status",
    "Action",
  ];
  dataSource: any;
  columnVisibility: { [key: string]: boolean } = {};
  showSettings: boolean = false;
  @Input() layerdata: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // totalCount: any;
  pages: any = [];
  total: number;
  sizeOptions: any = [5, 10, 15, 25];
  pageSizeOptions: number[] = [10, 25, 50, 100];
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  limit: number = 10;
  calculate_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  loader = false;
  offset: number = 0;
  constructor(
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private dialog: MatDialog
  ) {
    // Set default visibility for columns
    this.displayedColumns.forEach((column) => {
      this.columnVisibility[column] = true;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit() {
    this.getAllowResourcesApi();
    // this.dataSource.paginator = this.paginator;
    this.dataSource = new MatTableDataSource(this.layerdata);
    this.list.subscribe((res) => {
      if (!res) {
        this.dataSource = new MatTableDataSource([]);
        return;
      }
      this.dataSource = new MatTableDataSource(res);
    });
    console.log(this.totalCount);

  }

  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.getAllowResource();
    });
  }

  getAllowResource() {
    this.calculate_access = this.fnc.checkResourceAccess(
      "spark_calculate_sync",
      false
    );
  }
  @Input() list: Observable<any>;

  ngAfterContentChecked(): void {}
  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  /**
   * log file is downloading here
   * @param log_url
   */
  downloadLog(log_url) {
    window.open(log_url.toString(), "_blank");
  }

  /**
   * stop running process
   * @param element
   */
  stopProcess(element) {
    if (!this.calculate_access.DELETE) {
      console.log(this.calculate_access);

      this.notify.notify("You are not authorized to stop Process", "warn");
      return;
    }
    let batch_id = element.batch_id;
    let upload_id = element.id;
    let url = `delete-calculate-process-spark?batch_id=${batch_id}&upload_id=${upload_id}`;
    this.loader = true;
    this.api.deleteGesData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.status == 200) {
          this.notify.notify(res.message, "success");
          this.AfterProcessStopped.emit();
        } else {
          // this.layerListData = [];
          this.loader = false;
        }
      },
      error: (err) => {
        this.notify.notify(err.error.message, "error");
        this.loader = false;
      },
    });
  }
  /**
   * date formate change here
   * @param date
   * @returns
   */
  getFormatedDate(date) {
    return this.fnc.formatDateUTC(date, true);
  }

  /**
   * open confirmation model before stop process
   * @param element
   */
  openConfirmation(element) {
    let _data = {
      parent_data: this,
      message: "Do you really want to Stop Running Process?",
    };
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: "350px",
      data: _data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "YES") {
        this.stopProcess(element);
      }
    });
  }

  confirmDialogYes(confirm, action) {
    if (confirm == "YES") {
      //
    }
  }

  syncProcess(element) {
    let url = `sync-data-spark?upload_id=${element.id}&year=${element.year}`;
    this.loader = true;
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
      if(res.status == 200){
        this.afterSynced.emit()
        this.loader = false;
        this.notify.notify(res.message, "success");
      }else{
        this.loader = false;
        this.notify.notify(res.message, "warn");
      }
      },
      error: (err) => {
        this.loader = false;
      },
    });
  }

  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
      this.offset = 0;
      console.log(this.limit, this.offset);

    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    let pagedetails = {
      limit:this.limit,
      offset:this.offset
    }
   this.pageChange.emit(pagedetails)
  }

}
