<div class="p-0">
    <div class="text-center top-header p-2"> <h4>{{name}} - {{neigh_uid}}</h4></div>
    <div class="p-3">
        <mat-select class="form-control my-2" (selectionChange)="selectLang($event)" [(ngModel)]="selected_lang"  placeholder="Select Language">
            <mat-option *ngFor="let val of language_list" [value]="val.val">
                {{ val.label }}
            </mat-option>
        </mat-select>
        <angular-editor [(ngModel)]="description" [config]="editorConfig"></angular-editor>
        <div class="text-center p-3">
            <button  mat-raised-button color="primary" class="mx-1" (click)="save()">Save</button>
            <button  mat-raised-button color="accent" class="mx-1" (click)="cancel()">Cancel</button>
        </div>
    </div>
    
</div>