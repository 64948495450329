import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import * as $ from "jquery";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ApiserviceService } from "../../../apiservice.service";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { NotificationService } from "../../services/notification.service";
import { ImageEditorComponent } from "../../image-editor/image-editor.component";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input-2";
import { CustomValidator } from "./customValidator";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  user_detail: any;
  language_list = [];
  city_list = [];
  loader: boolean = false;
  loader1: boolean = false;
  change: boolean = false;

  profileForm: FormGroup;
  changePasswordForm: FormGroup;

  @ViewChild("imageInput") imageInput;
  profile_image: string;

  SearchCountryField = SearchCountryField;
  cuntry: string = "us";
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Bahrain,
    CountryISO.India,
    CountryISO.Kuwait,
    CountryISO.SaudiArabia,
    CountryISO.Switzerland,
    CountryISO.UnitedKingdom,
    CountryISO.UnitedStates,
  ];
  @Output() close = new EventEmitter();
  passwordType: string = "password";
  passwordCnfrmType: string = "password";
  oldPasswordType: string = "password";
  constructor(
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private elementRef: ElementRef,
    private notify: NotificationService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserProfileComponent>,
    private dialog: MatDialog
  ) {
    this.getLanguageList();
    this.user_detail = JSON.parse(localStorage.getItem("user_info"));
    let phone_number = this.user_detail?.contact
      ? this.user_detail.contact.split("-")[1]
      : "";
    this.api.profile_image.subscribe((img: string) => {
      if (img) {
        this.profile_image = img;
      }
    });
    this.profileForm = this.fb.group({
      fname: [
        this.user_detail.fname,
        Validators.compose([
          Validators.required,
          Validators.maxLength(45),
          Validators.minLength(2),
          Validators.pattern("^[a-zA-Z ]*$"),
        ]),
      ],
      lname: [
        this.user_detail.lname,
        Validators.compose([
          Validators.required,
          Validators.maxLength(45),
          Validators.minLength(2),
          Validators.pattern("^[a-zA-Z ]*$"),
        ]),
      ],
      email: [{ value: this.user_detail.email, disabled: true }],
      contact: [this.user_detail.contact, Validators.required],
      city: [this.user_detail.country, Validators.required],
      language: [this.user_detail.language, Validators.required],
      image: [this.profile_image],
    });
    this.changePasswordForm = this.fb.group(
      {
        oldPassword: ["", Validators.required],
        password: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidator.patternValidator(/\d/, { hasNumber: true }),
            CustomValidator.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            CustomValidator.patternValidator(/[a-z]/, { hasSmallCase: true }),
            CustomValidator.patternValidator(
              /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
              { hasSpecialCharacters: true }
            ),
            Validators.minLength(8),
            Validators.maxLength(20),
          ]),
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
      },
      {
        validator: CustomValidator.passwordMatchValidator,
      }
    );
    setTimeout(() => {
      this.profileForm.get("contact")?.setValue(phone_number);
    }, 150);
  }

  getLanguageList() {
    this.api.getUmsData("language").subscribe((res: any) => {
      if (res && res.data) {
        res.data.forEach((element) => {
          if (element.status == 1) {
            this.language_list.push(element);
          }
        });
      }
    });
  }

  changePass() {
    this.change = !this.change;
  }
  onPaste($event: ClipboardEvent) {
      $event.preventDefault();
  }
  /**
   * This is a function for change password
   * @returns
   */
  changePassword() {
    var body = {
      user_id: this.api.user_id,
      new_password: this.changePasswordForm.value.password,
      old_password: this.changePasswordForm.value.oldPassword,
    };
    this.api.postUmsData("change-password", body).subscribe({
      next: (res: any) => {
        if (res && res.status == 201) {
          this.notify.notify(res.message, "success", 5000);
          this.dialogRef.close();
        } else {
          this.notify.notify(res.message, "warn", 5000);
        }
      },
      error: (err) => {
        // this.notify.notify(err.error.message, 'error', 5000)
      },
    });
  }

  ngOnInit() {
    this.getCityList();
  }

  getCityList() {
    this.api.getUmsData("place").subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          res.data.forEach((element) => {
            if (element.status == 1) {
              this.city_list.push(element);
            }
          });
        }
      },
      error: (err) => {},
    });
  }
  // uploadImage(event) {
  //   this.loader = true;
  //   let fileList: FileList = event.target.files;
  //   let formData: FormData = new FormData();
  //   if (fileList.length > 0) {
  //     let file: File = fileList[0];
  //     let pattern = /image-*/;
  //     if (!file.type.match(pattern)) {
  //       return;
  //     }
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = (event: any) => {
  //       this.user_detail.imageURL = event.target.result;
  //     }

  //     formData.append('file', file, file.name);
  //     this.api.postFileUms('deadmin/images', formData, 'text')
  //       .subscribe((data: any) => {
  //         this.user_detail.imageURL = data;
  //         this.share.adminComp.profile_image = this.user_detail.imageURL;
  //         localStorage.setItem('profile_image', this.user_detail.imageURL);
  //         let body = "profile_image=" + this.user_detail.imageURL;
  //         this.api.patchUmsData('user/' + this.api.user_id, body).subscribe((res: any) => {
  //           this.notify.notify('Profile picture successfully updated!', 'Dismiss');
  //           this.loader = false;
  //         }, err => {
  //           this.loader = false;
  //         })
  //       },
  //         err => {
  //           console.log(err)
  //         }
  //       );
  //   }
  // }

  /**
   * This is a funtion that use to attach a file and open crop option to edit image

   * @param event event is parameter
   * @returns return void
   */

  uploadExplorImage(event) {
    const dialogRef = this.dialog.open(ImageEditorComponent, {
      width: "800px",
      panelClass: "image-croper-dialog",
      data: { info: event, ratio: 1 / 1, size: 512, type: "profile" },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: FormData) => {
      if (result) {
        this.uploadImage(result);
      }
      this.imageInput.nativeElement.value = "";
    });
  }

  /**
 * This is a funtion that use to update profile image

 * @param {FormData} formData event is parameter
 * @returns return void
 */
  uploadImage(formData: FormData) {
    this.loader = true;

    if (formData) {
      this.api
        .postFileUms(`profile-image?user_id=${this.api.user_id}`, formData)
        .subscribe({
          next: (res: any) => {
            this.loader = false;
            if (res && res.status == 201 && res.data) {
              this.profileForm.patchValue({
                image: res.data,
              });
              this.notify.notify(res.message, "success", 5000);
              localStorage.setItem("profile_image", res.data);
              this.api.profile_image.next(res.data);
              this.api.getUserDetail(this.api.user_id);
            }
          },
          error: (err) => {
            this.loader = false;
          },
        });
    }
  }
  openExplorer(type) {
    if (type == "image") {
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
  }
  saveUserdetail(e): void {
    let user_name;
    this.loader1 = true;
    let lang_code = e.value.language;
    let lang_detail = this.fnc.getArrayValue(
      "language_code",
      lang_code,
      this.api.language_list
    );

    let attribute = {};
    attribute[1] = { first_name: e.value.fname, last_name: e.value.lname };
    let number = e.value.contact.e164Number.split(e.value.contact.dialCode)[1];
    let body = {
      attributes: attribute,
      phone_number: e.value.contact.dialCode + "-" + number,
      gender: e.value.gender,
      nationality: e.value.city,
      profile_image: this.profile_image,
    };
    this.api
      .patchUmsData(
        `user?updated_by=${this.api.user_id}&id=${this.api.user_id}`,
        body
      )
      .subscribe(
        (res: any) => {
          this.loader1 = false;
          if (res && res.status == 201) {
            this.api.getUserDetail(this.api.user_id);
            this.notify.notify(res.message, "success", 5000);
            this.dialogRef.close()
            this.close.emit();
          } else {
            this.notify.notify(res.message, "warn", 5000);
          }
        },
        (err) => {
          this.loader1 = false;
        }
      );
  }
  isShow(type) {
    if (type == "password") {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    } else if (type == "cnfirmPassword") {
      if (this.passwordCnfrmType == "password") {
        this.passwordCnfrmType = "text";
      } else {
        this.passwordCnfrmType = "password";
      }
    } else {
      if (this.oldPasswordType == "password") {
        this.oldPasswordType = "text";
      } else {
        this.oldPasswordType = "password";
      }
    }
  }
  closeModal() {
    this.dialogRef.close();
  }
}
