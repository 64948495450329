<div class="parent-container">
  <mat-card appearance="outlined" class="example-card">
    <mat-card-header>
      <div class="w-100 mlr-2 heading" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title">Gis Attributes</span>
        <div class="d-flex justify-content-between search-add">
          <div class="search">
            <input class="search-input" [formControl]="searchField" type="text" name="search"
              placeholder="Search Attribute Name...">
          </div>
          <div class="show-deleted">
            <!-- Show Deleted-->
            <mat-slide-toggle class="deleted-row" (change)="getDeleted($event)">
              Show Deleted
            </mat-slide-toggle>
          </div>
          <button mat-raised-button (click)="openDialog('add')">
            Add Attibute
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content class="tabs">


      <div class="table-container">
        <table mat-table matSort [dataSource]="dataSource" class=w-100>
          <ng-container *ngFor='let disCol of displayedColumns'>

            <ng-container *ngIf="disCol == 'Action'">
              <ng-container matColumnDef="{{disCol}}">
                <th mat-header-cell *matHeaderCellDef>
                  <span class="header Action cp">{{disCol}}</span>
                </th>
                <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                  <div class="d-flex justify-content-start align-items-center">
                    <button *ngIf="status==1" color='white' mat-mini-fab (click)="openDialog('edit',element)">
                      <mat-icon class="editIcon blue">edit</mat-icon>
                    </button>
                    <button *ngIf="status==1" mat-mini-fab color="warn" (click)="openConfirmation(element)">
                      <mat-icon class="deleteIcon">delete</mat-icon>
                    </button>
                    <mat-slide-toggle *ngIf="element.status==-1" [checked]="element.status==1?true:false"
                      (change)="updateStatus(element,$event)">
                    </mat-slide-toggle>
                  </div>
                </td>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="disCol != 'Action'">
              <ng-container matColumnDef="{{disCol}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol}}</th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  <span *ngIf="disCol == 'SN'"> {{offset+i+1}} </span>
                  <span *ngIf="disCol != 'SN'"> {{returnVal(element,disCol)}}</span>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>
      <div class="w-100" fxLayout="row">
        <mat-paginator class="w-100" [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; handlePagination($event)">
        </mat-paginator>
      </div>
      <div *ngIf="loader" class='loader-div'>
        <ngx-loading [show]="loader"></ngx-loading>
      </div>
    </mat-card-content>
  </mat-card>

</div>