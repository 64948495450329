import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../apiservice.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileComponent } from '../user-profile/user-profile.component';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.css']
})
export class UsermenuComponent implements OnInit {

  constructor(private router: Router, private api:ApiserviceService,
    private dialog:MatDialog) { 
  }

  ngOnInit() {
  }
  logout(val){
    this.api.logout(val);
  }
  openProfile(){
    this.dialog.open(UserProfileComponent, {
      width: '450px',
      panelClass: 'create-master-panel',
      data: { parent:null }//optional
    })
    
  }
}
