import { Component, OnInit } from "@angular/core";
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { GisDataService } from '../gis-data-upload/gis-data-upload.service';
import { PageEvent } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogBox } from "../confirm-dialog/confirm-dialogbox";
import { FormControl } from '@angular/forms'
import { Sort } from "@angular/material/sort";
import { debounceTime } from "rxjs/operators";
@Component({
  selector: "app-log-setting",
  templateUrl: "./log-setting.component.html",
  styleUrls: ["./log-setting.component.scss"],
})
export class LogSettingComponent implements OnInit {
  loader: boolean;
  dataSource: any = [];
  displayedColumns = [
    "sno",
    "module_name",
    "program_name",
    "program_desc",
    "added_date",
    "last_updated",
    "action",
  ];
  limit=10;
  offset=0;
  log_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean; };
  searchField: FormControl;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  program_sort: boolean=false;
  added_sort: boolean=false;
  last_date_sort: boolean=false;
  filter_log='true'
  selected_column: string;

  constructor(private fnc: CommonfunctionService, private api: ApiserviceService,
    private alert : NotificationService,
    private menus: AdminMenusService,
    private active: GisDataService,
    private dialog: MatDialog
    ) {

      this.menus.setActive('log-settings');
      this.active.setActive('');
      this.getAllowResourcesApi();
  }

  // get resource list for checking log-settings resource
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.log_access = this.fnc.checkResourceAccess('log_settings', false);
        this.getLogService();
      })
  }

 //change pagination
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    if(this.selected_column == 'program_name'){
      this.getLogService(null,this.program_sort,this.selected_column);
    }else if(this.selected_column == 'added_date'){
      this.getLogService(null,this.added_sort,this.selected_column);
    }else if(this.selected_column == 'last_updated'){
      this.getLogService(null,this.last_date_sort,this.selected_column);
    }else{
       this.getLogService();
    }

  }

  ngOnInit(): void {
    this.searchField = new FormControl();
    this.searchField.valueChanges
    .pipe(debounceTime(500))
      .subscribe(term => {
        this.offset = 0;
        term = term.trim().toLowerCase();
        if (term) {
          if(this.selected_column == 'program_name'){
            this.getLogService(term,this.program_sort,this.selected_column);
          }else if(this.selected_column == 'added_date'){
            this.getLogService(term,this.added_sort,this.selected_column);
          }else if(this.selected_column == 'last_updated'){
            this.getLogService(term,this.last_date_sort,this.selected_column);
          }else{
             this.getLogService(term);
          }
        } else {
          if(this.selected_column == 'program_name'){
            this.getLogService(null,this.program_sort,this.selected_column);
          }else if(this.selected_column == 'added_date'){
            this.getLogService(null,this.added_sort,this.selected_column);
          }else if(this.selected_column == 'last_updated'){
            this.getLogService(null,this.last_date_sort,this.selected_column);
          }else{
             this.getLogService();
          }
        }
      })

  }

  resetSorting(){
    this.program_sort = false;
    this.added_sort = false;
    this.last_date_sort = false;
    this.selected_column = '';
  }

  filterLog(val){
    this.offset = 0;
    this.resetSorting();
    this.getLogService();
  }

  tableSort(col){
    this.selected_column = col
    if(col == 'program_name'){
      this.program_sort = !this.program_sort;
      this.getLogService(null,this.program_sort,col);
    }
    if(col == 'added_date'){
      this.added_sort = !this.added_sort;
      this.getLogService(null,this.added_sort,col);
    }
    if(col == 'last_updated'){
      this.last_date_sort = !this.last_date_sort;
      this.getLogService(null,this.last_date_sort,'updated_date');
    }
  }

  /**
   * This is a function to get logs data
   * @param search
   * @returns
   */
  getLogService(search=null,sort_asc=null,order_by=null){
    if(!this.log_access.GET){
      this.alert.notify("You are not authorized for getting logs", "warn")
      return;
    }
    this.loader=true;
    let url = `log-config?limit=${this.limit}&offset=${this.offset}&sort_asc=${sort_asc}&order_by=${order_by}`;
    if(this.offset == 0){
      url += `&is_count=true`;
    }
    if(search){
      url += `&program_name=${search}`;
      url += `&program_desc=${search}`;
    }
    if(this.filter_log){
      url += `&details_log=${this.filter_log}`
    }
    this.api.getGesData(url).subscribe((res:any)=>{
      if(this.offset == 0){
        this.totalCount = res?.totalRecord
      }
      this.loader=false;
      if(res && res?.data.length){
        this.dataSource = res?.data;
      }else{
        this.dataSource = [];
      }
    },err=>{
      this.dataSource = [];
      this.loader=false;
    })
  }

  /**
   * This is a function to disable all logs
   * @returns
   */
  disableAll() {
    if(!this.log_access.PATCH){
      this.alert.notify("You are not authorized for update logs","warn")
      return;
    }
   const def = this.dialog.open(ConfirmDialogBox,{
      data: {parent_data:null, message: 'Are you sure you want to disable all logs?'},
      panelClass: "",
      width: "400px",
    })
    def.afterClosed().subscribe(val=>{
      if(val == 'YES'){
        this.loader=true;
        this.api.putGesData(`log-config?disable_all=true&user_id=${this.api.user_id}`,null).subscribe((res:any)=>{
          this.loader=false;
          if(res){
            this.getLogService();
            this.alert.notify("Update successfull", "success")
          }
        },err=>{
          this.loader=false;
        })
      }
    })


  }

    /**
   * This is a function to disable one by one logs
   * @returns
   */
  updateDetailsLog(id, event, details_log) {
    if(!this.log_access.PATCH){
      this.alert.notify("You are not authorized for update logs", "warn")
      event.source.checked = details_log;
      return;
    }
    this.loader=true;
    this.api.putGesData(`log-config?log_id=${id}&user_id${this.api.user_id}&details_log=${event.checked}`,null).subscribe((res:any)=>{
      this.loader=false;
      if(res){
        this.getLogService();
        this.alert.notify("Update successfull", "success")
       }
    },err=>{
      this.loader=false;
    })

  }

  returnVal(element, cols) {
    switch (cols) {
      case "module_name":
        return element.module_name ? element.module_name : "";
      case "program_name":
        return element.program_name ? element.program_name : "";
      case "program_desc":
        return element.program_desc ? element.program_desc : "";
      case "last_updated":
        return element.last_updated
          ? this.fnc.formatDateUTC(element.last_updated, true)
          : "";
      case "added_date":
        return element.added_date
          ? this.fnc.formatDateUTC(element.added_date, true)
          : "";
      default:
        return element[cols];
    }
  }
}
