<mat-toolbar class="est-toolbar">
    <h1 class="mat-h1">Conflict data for <b class="cp">&nbsp;{{selectedLayer}}</b></h1>
</mat-toolbar>
<mat-card-content class="flx-height">

    <nav mat-tab-nav-bar [backgroundColor]="background">
        <a mat-tab-link *ngFor="let tab of tabLabels" (click)="changeTab(tab);" [active]="activeLink == getLabel(tab)">
            {{getLabel(tab)}}
        </a>
    </nav>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
                <button mat-icon-button disabled></button>
                {{node.name}}
            </li>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <li>
                <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    {{node.name}}
                </div>
                <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>
</mat-card-content>
<mat-progress-bar *ngIf="loader" mode="indeterminate" color="primary"></mat-progress-bar>
<mat-card-actions>
    <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button color="primary" [disabled]="loader" (click)="revalidate()">Ignore &
            Re-Validate</button>
    </div>
</mat-card-actions>