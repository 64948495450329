import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShareService {
  heightService: number;
  notifications: any = [];
  notify_detail_obj: any;
  notify_obj: any;
  adminComp: any;
  fieldObj: any;
  gpObj: any;
  qaqc_obj:any;
  appComp:any;
  
  constructor() { }

}
