// shared.model.ts
export interface SharedModel {
    limit: number;
    offset: number;
    loader: boolean;
  }
  
  export const initialSharedModel: SharedModel = {
    limit: 10,
    offset: 0,
    loader: false,
  };
  