<div class="form">
  <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
    <div>
      <h5 *ngIf="mode == 'add'">Add Attribute</h5>
      <h5 *ngIf="mode == 'edit'">Edit Attribute</h5>
    </div>
  </div>
  <mat-progress-bar class="mtc-progress" mode="indeterminate" color="warn" *ngIf="loader"></mat-progress-bar>
  <div>
    <form [formGroup]="AttributeForm" autocomplete="off" (ngSubmit)="addAttribute(AttributeForm)">
      <div class="calc-f">
        <div class="add-lists pt-4 pl-3 pr-3">
          <mat-form-field class="w-100">
            <input matInput type="text" placeholder="Attribute Name" formControlName="name">
            <mat-error>Enter Attribute Name</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-select required placeholder="Layer" formControlName="layer">
              <mat-option *ngFor="let list of layers" [value]="list.value">
                {{ list.value }}
              </mat-option>
            </mat-select>
            <mat-error>Select Layer</mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-select required placeholder="Data Type" formControlName="dataType">
              <mat-option *ngFor="let list of data_types" [value]="list.value">
                {{ list.value }}
              </mat-option>
            </mat-select>
            <mat-error>Select Data type</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="footer" fxLayout="row" fxLayoutAlign="space-evenly">
        <div class="pr-2">
          <button mat-raised-button color="primary" [disabled]="AttributeForm.invalid" *ngIf="mode == 'add'">
            Submit
          </button>
          <button type="button" mat-raised-button color="primary" [disabled]="AttributeForm.invalid"
            *ngIf="mode == 'edit'" (click)="updateAttibutes(AttributeForm)">
            Update
          </button>
        </div>
        <div class="pl-2">
          <button type="button" mat-raised-button (click)="cancel()">
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>