import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ApiserviceService } from '../../../apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';
import * as $ from 'jquery';
import { Router } from '@angular/router';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * <li>gisapi_description_patch</li>
 * <li>gisapi_uploads_images_post</li>
 * <li>gisapi_uploads_videos_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.css']
})
export class MediaDialogComponent {

  cellData: any;
  mode: any;
  imageGallery: any = [];
  videoGallery: any = [];
  selectedSource: string = '';
  currentImageUrl: string = '';
  count: number = 0;
  loader: boolean = false;
  showConfirm: boolean = false;
  addImageDiv: Boolean = false;
  addVideoDiv: Boolean = false;
  imageForm: FormGroup;
  photoList: any = [];
  videoList: any = [];

  //medium editor
  options = { 'toolbar': { 'buttons': ['bold', 'italic', 'underline', 'h1', 'h2', 'h3', 'h4'] } }
  placeholder = "image description";

  public upload_media_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  @ViewChild('video') videoElem: any;
  @ViewChild('slideshow') slideshow: any;
  constructor(public dialogRef: MatDialogRef<MediaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder, private api: ApiserviceService, private fnc: CommonfunctionService, private elementRef: ElementRef, private notify: NotificationService,
    private route: Router) {
    this.getAllowResourcesApi()
    this.photoList = [];
    this.videoList = [];
    this.cellData = this.data.cellData;
    this.mode = this.data.mode;
    if (this.cellData.data && this.mode == 'image') {
      this.cellData.data.forEach(img => {
        this.imageGallery.push({ url: img.url, caption: img.description })
      });
    }
    if (this.cellData.data && this.mode == 'video') {
      this.cellData.data.forEach(vid => {
        this.videoGallery.push({ url: vid.url })
      });
    }
    if (this.videoGallery.length) {
      let obj = this.videoGallery[0];
      if (obj) this.selectedSource = obj.url;
    }
    if (this.imageGallery.length) {
      let obj = this.imageGallery[0]
      this.currentImageUrl = obj.url;
    }

    this.imageForm = this.fb.group({
      'image': [],
      'video': [],
      'description': [],
      'isDefault': []
    });
  }
 getAllowResourcesApi() {

    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {

        this.api.allowResources = res.data;
        this.upload_media_access = this.fnc.checkResourceAccess('upload_media', false);
      })
  }
  public playSelected(url) {
    this.selectedSource = url;
    this.videoElem.nativeElement.load();

  }

  // get Resources

  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data:any) => {
  //          this.api.allowResources = data;
  //          this.upload_media_access = this.fnc.checkResourceAccess('upload_media', false);
  //       },
  //       }
  //     )
  // }

  //delete image
  public deleteImage() {
    this.loader = true;
    let data = {}, body = [], newData = this.fnc.getDeletedArray('url', this.currentImageUrl, this.data.cellData.data);
    data = {
      "added_by": this.api.user_id,
      "entity_id": this.data.cellData.id,
      "entity_name": this.data.parent.selectedlayer,
      "image": newData,
      "status": 1
    }

    body.push(data);
    this.api.patchData('description', body)
      .subscribe((data: any) => {
        this.data.parent.notify.notify("Image deleted", 'warn');

        if (this.fnc.jsonLength(newData)) {
          let arr = [];
          newData.forEach(e => {
            arr.push({ url: e.url, caption: e.description })
          });
          this.imageGallery = arr;
          this.data.cellData.data = newData
        } else {
          this.imageGallery = [];
        }
        this.loader = false;
        this.showConfirm = false;
        this.data.parent.getUploadedData(this.data.parent.selectedlayer)
        // this.dialogRef.close();
      },
        err => {
          this.loader = false;
          this.data.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        });
  }

  //delete video
  public deleteVideo() {
    this.loader = true;
    let data = {}, body = [], newData = this.fnc.getDeletedArray('url', this.selectedSource, this.data.cellData.data);

    data =
    {
      "added_by": this.api.user_id,
      "entity_id": this.data.cellData.id,
      "entity_name": this.data.parent.selectedlayer,
      "video": this.data.cellData.data,
      "status": 1
    }
    body.push(data)
    this.api.patchData('description', body)
      .subscribe((data: any) => {
        this.data.parent.notify.notify("Video deleted", 'warn');

        if (this.fnc.jsonLength(newData)) {
          let arr = [];
          newData.forEach(e => {
            arr.push({ url: e.url })
          });
          this.videoGallery = arr;
          this.data.cellData.data = newData
        } else {
          this.videoGallery = [];
        }
        this.loader = false;
        this.showConfirm = false;
        this.data.parent.getUploadedData(this.data.parent.selectedlayer)
        // this.dialogRef.close();
      },
        err => {
          this.loader = false;
          this.data.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        });
  }

  // get current url from image array on right and left swipe or click
  public slideChange(direction) {
    let obj;
    if (direction == 'left' && this.count == 0) {
      this.count = this.imageGallery.length - 1;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    } else if (direction == 'left' && this.count <= this.imageGallery.length - 1) {
      this.count--;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    }

    if (direction == 'right' && this.count < this.imageGallery.length - 1) {
      this.count++;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    } else if (direction == 'right' && this.count == this.imageGallery.length - 1) {
      this.count = 0;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    }
  }
  public showModal(action) {
    if (action === 'delele') {
      if (!this.upload_media_access.DELETE) {
        this.notify.notify('You are not authorized to delete', 'warn');
        return;
      }
      this.showConfirm = true;
    } else if (action === 'addImage') {
      if (!this.upload_media_access.PATCH) {
        this.notify.notify('You are not authorized to add Image', 'warn');
        return;
      }
      if (this.imageGallery.length >= 10) {
        this.notify.notify('you can not upload more then 10 images', 'warn');
        return;
      }
      this.addImageDiv = true;
    } else if (action === 'addVideo') {
      if (!this.upload_media_access.PATCH) {
        this.notify.notify('You are not authorized to add video', 'warn');
        return;
      }
      if (this.videoGallery.length >= 5) {
        this.notify.notify('you can not upload more then 5 videos', 'warn');
        return;
      }
      this.addVideoDiv = true;
    }
  }

  // Image update
  public openExplorer(type) {
    console.log('fhlasdjfhalsf dkfajhsd fjahsdj');

    if (type == 'image') {
      if (this.imageGallery.length >= 10) {
        this.notify.notify('you can not upload more then 10 images', 'warn');
        return;
      }
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
    if (type == 'video') {
      if (this.videoGallery.length >= 5) {
        this.notify.notify('you can not upload more then 5 videos', 'warn');
        return;
      }
      let e = $(this.elementRef.nativeElement);
      e.find("#video_input").click();
    }
  }

  public uploadImages(event) {
    if (this.imageGallery.length >= 10) {
      this.notify.notify('you can not upload more then 10 images', 'warn');
      return;
    }
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /image-*/;
      if (!file.type.match(pattern)) {
        return;
      }
      // adding image from list
      let img = {
        imageName: file.name,
        imageURL: 'https://goo.gl/HLhJAL',
        id: Math.random(),
        loader: true,
        description: 'image',
        isDefault: true,
        sort_order: 1
      };
      let reader = new FileReader();
      reader.onload = (event: any) => {
        img.imageURL = event.target.result;
      }
      reader.readAsDataURL(file);
      if (this.photoList.length > 0) {
        this.photoList.splice(0, 0, img);
      } else {
        this.photoList.push(img);
      }
      formData.append('file', file, file.name);
      this.api.postUmsMedia('uploads/images?folder_name=property_image&city-code=' + this.api.city_id, formData, 'application/json')
        .subscribe((data: any) => {
          if(data){
            data = JSON.parse(data);
            let index = this.fnc.getArrayindex('id', img.id, this.photoList);
            if (index !== null) {
              this.photoList[index].imageURL = data.data;
              setTimeout(() => {
                this.photoList[index].loader = false;
                this.updateImage()
              }, 200);
            }
          }
        },
          err => {
            let index = this.fnc.getArrayindex('id', img.id, this.photoList);
            this.photoList[index].loader = false;
          }
        );
    }
  }

  public updateImage() {
    this.loader = true;
    let body = [], record = {}, url;
    if (this.data.cellData.data == null) {
      this.data.cellData.data = [];
    }
    this.data.cellData.data.push({ 'url': this.photoList[0].imageURL, 'description': this.photoList[0].description, 'isDefault': this.photoList[0].isDefault, 'sort_order': this.photoList[0].sort_order })

    record = {
      "added_by": this.api.user_id,
      "entity_id": this.data.cellData.id,
      "entity_name": this.data.parent.selectedlayer,
      "image": this.data.cellData.data,
      "status": 1
    }
    body.push(record);
    url = 'description';
    this.api.patchData(url, body)
      .subscribe((data: any) => {
        this.loader = false;
        this.notify.notify('Image Information Updated', 'success');
        this.imageGallery.push({ url: this.photoList[0].imageURL });
        this.data.parent.getUploadedData(this.data.parent.selectedlayer)

      },
        err => {
          this.loader = false;
          this.notify.notify(this.fnc.getErrorMessage(err), 'error');
        });
  }

  //upload Video
  public uploadVideos(event) {
    if (this.videoGallery.length >= 5) {
      this.notify.notify('you can not upload more then 5 videos', 'warn');
      return;
    }
    let body = {}, record = {};
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /video-*/;
      if (!file.type.match(pattern)) {
        ('Not Video');
        return;
      }
      // adding image from list
      let vid = {
        videoName: file.name,
        videoThumbURL: '../../../assets/images/vid.png',
        videoURL: '../../../assets/images/vid.png',
        id: Math.random(),
        loader: true,
        description: 'video',
        isDefault: false,
        sort_order: 1
      }

      if (this.videoList.length > 0) {
        this.videoList.splice(0, 0, vid);
      } else {
        this.videoList.push(vid);
      }

      formData.append('file', file, file.name);
      this.api.postUmsMedia('uploads/videos?folder_name=property_image&city-code=' + this.api.city_id, formData, 'application/json')
        .subscribe((data: any) => {
          if(data){
            data = JSON.parse(data);
            let index = this.fnc.getArrayindex('id', vid.id, this.videoList);
            if (index !== null) {
              this.videoList[index].videoURL = data.data;
              setTimeout(() => {
                this.updateVideoList();
                this.videoList[index].loader = false;
              }, 200);
            }
          }
        },
          err => {
            let index = this.fnc.getArrayindex('id', vid.id, this.videoList);
            this.videoList[index].loader = false;
          }
        );
    }
  }

  public uploadVideoByUrl() {
    if (this.videoGallery.length >= 5) {
      this.notify.notify('you can not upload more then 5 videos', 'warn');
      return;
    }
    let e = $(this.elementRef.nativeElement);
    let vid_url = e.find("#video_url").val();
    if (vid_url) {
      let vid = {
        videoName: 'estVideo',
        videoThumbURL: '../../../assets/images/vid.png',
        videoURL: vid_url,
        id: Math.random(),
        loader: false,
        description: '',
        isDefault: false,
        sort_order: 1
      }

      if (this.videoList.length > 0) {
        this.videoList.splice(0, 0, vid);
      } else {
        this.videoList.push(vid);
      }
      e.find("#video_url").val('');
      this.updateVideoList();
    }
  }

  public updateVideoList() {
    let body = [], record = {}, url;
    if (this.data.cellData.data == null) {
      this.data.cellData.data = [];
    }
    this.data.cellData.data.push({ 'url': this.videoList[0].videoURL, 'description': this.videoList[0].description, 'isDefault': this.videoList[0].isDefault, 'sort_order': this.videoList[0].sort_order });
    record = {
      "added_by": this.api.user_id,
      "entity_id": this.data.cellData.id,
      "entity_name": this.data.parent.selectedlayer,
      "video": this.data.cellData.data,
      "status": 1
    }
    body.push(record);
    url = 'description';
    this.api.patchData(url, body)
      .subscribe((d: any) => {
        this.notify.notify('Video Information Updated', 'success');
        this.videoGallery.push({ url: this.videoList[0].videoURL });
        this.data.parent.getUploadedData(this.data.parent.selectedlayer)
      },
        err => {

        });
  }

  public confirm(delele) {
    if (delele == 'image') {
      this.showConfirm = false;
      this.deleteImage();
    } else if (delele == 'video') {
      this.showConfirm = false;
      this.deleteVideo();
    }
  }
  public cancel() {
    this.showConfirm = false;
    this.addImageDiv = false;
    this.addVideoDiv = false;
    this.photoList = [];
    this.videoList = [];
  }

  ngOninit() {

  }
}
