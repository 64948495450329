<div class="h-100 bg-white">
    <div fxLayout="row" class="w-100 over_auto crumb-c px-3" fxLayoutAlign="space-between center">
        <!-- <h4 class="open-sans mb-0 text-nowrap" style="color: #616474;">SIC Result
        </h4> -->
        <div class="title">SIC Result</div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
                <div class="d-flex align-items-center">
                    <mat-select class="form-control search-inputbox mx-1" [(ngModel)]="entity" placeholder="Option"
                        (selectionChange)="entityChange()">
                        <mat-option *ngFor="let type of entity_list" [value]="type.id">
                            {{ type.name }}
                        </mat-option>
                    </mat-select>

                </div>
            </div>
            <div class="position-relative mx-1">
                <mat-icon class="search-icon">search</mat-icon>
                <input [formControl]="searchfilter" name="search" class="form-input-l search-inputbox"
                    placeholder="Search by name" type="text">
            </div>
        </div>
    </div>
    <div class="">
        <div class="table-div w-100 px-3 table-responsive">
            <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>
            <table mat-table [dataSource]="dataSource" [class.text-right]="selectedLangCode== 'ar'"
                class="sic-table w-100" matSort (matSortChange)="entityChange($event)">
                <ng-container *ngFor=" let cols of displayedColumns">
                    <ng-container matColumnDef="{{ cols }}">
                        <ng-container *ngIf="cols == 'Name' || cols == 'Date'">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                <span class="open-sans font-weight-bold">{{cols}}</span>
                            </th> 
                        </ng-container>
                        <ng-container *ngIf="cols != 'Name' || cols != 'Date'"> 
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="open-sans font-weight-bold">{{cols}}</span>
                            </th>
                        </ng-container>
                        <ng-container *ngIf="dataSource">
                            <td mat-cell *matCellDef="let element; let i=index;">
                                <span class="f-14" *ngIf="cols == 'SN'">
                                    {{offset+i+1}}.
                                </span>
                                <div *ngIf="cols == 'Date'">
                                    {{getdate(element.date)}}
                                </div>
                                <div *ngIf="cols == 'Action'">
                                    <button class="details-btn"><a
                                            [routerLink]="entity+'/'+element.id">Details</a></button>
                                </div>
                                <p matTooltip={{returnVal(element,cols)}}
                                    *ngIf="cols != 'SN' && cols != 'Action' && cols != 'Date'"
                                    class="f-14 open-sans p-0 m-0">
                                    {{returnVal(element, cols)}}</p>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
            </table>
            <ng-container *ngIf="dataSource?.length == 0 && !loader && entity">
                <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 8rem;">
                    <span class="material-icons mr-2 availability">
                        error_outline
                    </span>
                    <span class="availability">Data not available</span>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="dataSource">
            <div class="d-flex mt-2 justify-content-end">
                <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                    (page)="pageEvent = $event; handlePagination($event)">
                </mat-paginator>
            </div>
        </ng-container>
    </div>
</div>