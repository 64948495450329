import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { GisDataService } from '../gis-data-upload/gis-data-upload.service';
import { NotificationService } from '../services/notification.service';
import { JsonToCsvMarks } from './jsonToCsv';

export interface options {
  label: string;
  value: string;
}
export interface actions {
  label: string;
  value: string;
}
@Component({
  selector: 'app-marks-upload',
  templateUrl: './marks-upload.component.html',
  styleUrls: ['./marks-upload.component.css']
})
export class MarksUploadComponent implements OnInit {
  status: any[] = [
    { status_code: -1, value: 'Invalid' },
    { status_code: 1, value: 'Valid' },
    { status_code: 2, value: 'Accepted' },
    { status_code: -2, value: 'Rejected' },
    { status_code: 0, value: 'Uploaded' },
    { status_code: -3, value: 'Accept Invalid' },
    { status_code: 4, value: 'Accept Progress' }
  ];
  options: options[] =
  [
    { label: "Upload Weightage", value: "upload" },
    { label: "Calculate Marks", value: "calculate" },
  ];
  actions: actions[] =
  [
    { label: "Street Marks", value: "street" },
    { label: "Amenity Marks", value: "amenity" },
  ];
  fileToUpload: File = null;
  fileName: string = '';
  fileUploadForm: FormGroup;
  pages: any = [];
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  displayedColumns = ["sno",
  "Upload ID",
  "Name",
  // "UID",
  "Added Date",
  "Existing Count",
  "Add Count",
  "Update Count",
  // "Target Count",
  "Updated Date",
  // "Parameter",
  // "Process Log",
  // "Log File",
  // "Error File",
  "Status",
  "Logs",
  "Actions"];
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  uploadMarksType: any;
  uploadLoading: boolean=false;
  calculate:boolean=false;
  neighbourhoods: any=[];
  upperLoader: boolean=false;
  selectedNeigh: any;
  searchNeigh='';
  searchBlock=''
  blocks: any;
  selectedneightUid: any;
  selectedBlock: any;
  actionType: any;
  statuses: any=[];
  statusAll: string;
  selectedYear: string = new Date().getFullYear().toString();

  @ViewChild('myFileInput') myFileInput;

  returnVal(element, cols) {
    switch (cols) {
      case 'Added Date': return this.getdateForm(element.added_date);
      case 'Existing Count': return element.existing_count;
      case 'Upload ID': return element.upload_id;
      case 'Name': return element.name;
      case 'Add Count': return element.add_count;
      case 'Update Count': return element.update_count;
      // case 'Target Count': return element.target_count;
      case 'UID': return element.uid;
      case 'Updated Date': return this.getdateForm(element.updated_date);
      case 'Status': return element.status;
    }
  };
  marks_type:any;
  @ViewChild(MatPaginator) paginator: MatPaginator
  reportLoader: boolean=false;
  dataSource: any;
  constructor(
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private fb: FormBuilder,
    private notify: NotificationService,
    private active: GisDataService,
    private dialog:MatDialog,
    private csv:JsonToCsvMarks) {
      this.getAllowResourcesApi();
      this.menus.setActive('marksupload');
      this.active.setActive('');
      this.fileUploadForm = this.fb.group({
        file: new FormControl(null, [Validators.required]),
        neigh: [''],
        block:[''],
        markType:[''],
      });
     }
     getAllowResourcesApi() {
      let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
      this.api.getUmsData(body)
        .subscribe((res: any) => {
          this.api.allowResources = res.data;
          this.upload_access = this.fnc.checkResourceAccess('marks_upload', false);
          this.getNeighbourhoods();
          this.getMarksUploadReport();
          this.getStatuses();
        })
    }

    getNeighbourhoods() {
      this.upperLoader = true;
      this.neighbourhoods=[];
      this.api.getGpsData('neighbourhoods/json')
        .subscribe((data: any) => {
          this.upperLoader = false;
          if (data.length) {
            data.forEach(e => {
              this.neighbourhoods.push(e);
            });
          }
        }, err => {
          this.upperLoader = false;
        });
    }

    getBlocks(neighUid) {
      this.upperLoader = true;
       let url = `blocks/json?neigh_uid=${neighUid}&return_geometry=true`;

    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
      this.api.getGpsData(url).subscribe((res: any) => {
        this.blocks = res;
        this.upperLoader = false;
      }, err => {
        this.blocks = [];
        this.selectedBlock = null;
        this.upperLoader = false;
        this.notify.notify('No Blocks found in this Neighbourhood', 'error');
      })
    }

    openConfirmation() {
      if(this.uploadMarksType == null || this.uploadMarksType == undefined){
        this.notify.notify("Select a Mark Type to Calculate",'warn');
        return;
      }
      if(this.selectedneightUid == null || this.selectedneightUid == undefined){
        this.notify.notify("Select a Neighbourhood To Calculate Marks",'warn');
        return;
      }
        let _data = {
          parent_data: this,
          message: 'Do you really want to run Calculation?',
        }
        const dialogRef = this.dialog.open(ConfirmDialogBox, {
          width: '350px',
          data: _data
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    confirmDialogYes(confirm) {
      if (confirm == 'YES') {
        this.calculateMarks()
      }
    }

    calculateMarks(){
      if(this.selectedneightUid == null || this.selectedneightUid == undefined){
        this.notify.notify("Select a Neighbourhood To Calculate Marks",'warn');
        return;
      }
      let url=`marks-upload/marks-insert`;
      let block;
      if(this.selectedBlock!=null || this.selectedBlock !=undefined){
        block=this.selectedBlock;
      }
      else{
        block="null";
      }
       var body={
          action: this.uploadMarksType,
          blockUid: block,
          marksType: this.uploadMarksType,
          neighUid: this.selectedneightUid,
          userId: this.api.user_id,
       }
       this.calculate=true;

      //  return;
      this.notify.notify("Request Submitted to the Server",'warn');
       this.api.postGmusData(url,body).subscribe((res:any)=>{
         this.calculate=false;
         if(res==true){
          //  console.log(res);
           this.notify.notify("Calculation Successfull",'success');

         }
         else{
          this.notify.notify("Calculation Unsucessfull! Check Log",'warn');
         }
       },err=>{
         this.notify.notify(err.error.message,'error');
         this.upperLoader=false;
       })
    }


    downloadLog(){
      if(this.uploadMarksType == null || this.uploadMarksType == undefined){
        this.notify.notify("Select a Mark Type to Calculate",'warn');
        return;
      }
      if(this.selectedneightUid == null || this.selectedneightUid == undefined){
        this.notify.notify("Select a Neighbourhood To Download Marks",'warn');
        return;
      }
      let url=`marks?action=${this.uploadMarksType}`,csvdata=[],headers=[];
      if(this.selectedneightUid){
        url+=`&neigh_uid=${this.selectedneightUid}`;
      }
      if(this.selectedBlock){
        url+=`&block_uid=${this.selectedBlock}`;
      }
      this.upperLoader=true;
      this.api.getGmusData(url).subscribe((res:any)=>{
        this.upperLoader=false;
        if(res){
          // let row = 'parcel_uid,neigh_uid,name,marks,neigh_name,block_uid,block_name,user_id,added_date';
          for (let key in res[0]) {
           headers.push(key);
        }

    csvdata.push(headers);
    let changedName;
    let formattedDate;
    res.map((ele: any) => {
      formattedDate=formatDate(ele.added_date, 'dd/MM/yyyy', 'en-us');
      if(ele.name.includes(',')){
        changedName=ele.name.split(",").join("|");
      }
      else{
        changedName=ele.name;
      }
      let tem = [];
       tem.push(
        (ele.parcel_uid)? ele.parcel_uid:'',
        (ele.neigh_uid)?ele.neigh_uid:'',
        (changedName)? changedName:'',
        (ele.marks)? ele.marks:'',
        (ele.neigh_name)? ele.neigh_name:'',
        (ele.block_uid)? ele.block_uid:'',
        (ele.block_name)?ele.block_name:'',
        (ele.user_id)? ele.user_id:'',
        (ele.added_date)? ele.added_date:'',
       );
       csvdata.push(tem);
      })
  let csvContent = '';

  csvdata.forEach(function(rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
  });
    this.csv.downloadFile(null, 'parcel_marks', csvContent);
        }
      },err=>{
        this.notify.notify(err.error.message,'error');
      })
    }


    selectedActionType(actionType){
      this.actionType=actionType;
    }


    selectedMarksType(marksType){
      this.uploadMarksType=marksType;
    }


    selectNeigh(neigh){
      this.selectedneightUid = neigh;
      if (this.selectedneightUid) {
        this.getBlocks(this.selectedneightUid);
      }
      this.searchNeigh='';
    }

    selectBlock(value) {
      this.selectedBlock = value;
    }

    getMarksUploadReport(){
      if (!this.upload_access.GET) {
        this.notify.notify("You are not authorized to VIEW Uploaded Marks Reports", "warn");
        return;
      }
      // this.marks_type="all";
      let url;
      // this.active.setActive(type);
      url=`marks-upload/all?limit=${this.limit}&offset=${this.offset}`;
      if(this.statusAll){
        url+=`&status=${this.statusAll}`
      }
      this.reportLoader=true;
      this.api.getGmusData(url).subscribe((res:any)=>{
        this.reportLoader=false;
        if(res && res.length){
          this.reportLoader=false;
          this.dataSource=res;
          this.totalCount=res.length;
        }
      },err=>{
        this.reportLoader=false;
        this.notify.notify(err.error.message,'error');
      })
    }

    filterByStatus(param, type) {
      if (type == 'status') {
        if (param == 'All') {
          this.statusAll = '-2,-1,0,1,2';
          this.getMarksUploadReport();
        }
        else {
          this.statusAll = param.toString();
          this.getMarksUploadReport();
        }
      }
    }
    getStatuses(){
      let url;
      // this.active.setActive(type);
      url=`marks-upload/status`;
      this.api.getGmusData(url).subscribe((res:any)=>{
        if(res && res.length){
          this.statuses=res;
        }
      })
    }

    handleFileInput(event) {
      // if(!this.uploadMarksType){
      //   this.notify.notify('Select a Marks Type to upload','Dismiss');
      //   return;
      // }
      let fileList: FileList = event.target.files;
      let formData: FormData = new FormData();
      if (fileList.length > 0) {
        let file: File = fileList[0];
        let pattern = /zip-*/;
        if (!file.type.match(pattern)) {
          this.notify.notify('Unsupported file type', 'warn');
          this.fileUploadForm = new FormGroup({
            file: new FormControl(null, [Validators.required])
          })
          return;
        }
        this.fileToUpload = file;
        this.fileName = this.fileToUpload.name;
      }
    }

    uploadFile(val) {
      if (!this.upload_access.POST) {
        this.notify.notify("You are not authorized to upload Marks Data", "warn");
        return;
      }
      let ln = this.fileToUpload.size;
      let url=`marks-upload?marks_type=all&uid=null&user_id=${this.api.user_id}`
      let formData = new FormData();
      formData.append('file', this.fileToUpload, this.fileToUpload.name);
      this.uploadLoading = true;
      this.api.postFile(url, formData).subscribe((res: any) => {
        this.uploadLoading = false;
        if(res.upload_id){
          this.notify.notify('File successfully uploaded!', 'success');
           this.fileToUpload=null;
          this.fileName = '';
          this.myFileInput.nativeElement.value = '';
        }
        this.fileUploadForm = new FormGroup({
          file: new FormControl(null, [Validators.required]),
        })
      }, err => {
        this.notify.notify(err.error.message, 'error');
         this.fileToUpload=null;
          this.fileName = '';
          this.myFileInput.nativeElement.value = '';
        this.fileUploadForm = new FormGroup({
          file: new FormControl(null, [Validators.required]),
          //selectedneightUid: new FormControl(null, [Validators.required]),
          //layer: new FormControl(null, [Validators.required]),
        })
        this.uploadLoading = false;
      })
    }

    action(uploadId,actionType){
      this.reportLoader=true;
      let url=`marks-upload/${uploadId}/${actionType}?user_id=${this.api.user_id}`;
      this.api.postUserAction(url,null).subscribe((res:any)=>{
        this.reportLoader=false;
        if(actionType && actionType=='reject'){
        this.notify.notify("Uploaded File Rejected",'warn');
        }
        if(actionType && actionType=='reject'){
        this.notify.notify("Uploaded File Rejected",'warn');
        }
        this.getMarksUploadReport();
      },err=>{
        this.reportLoader=false;
        this.notify.notify(err.error.message, 'error');
      })
    }

    stopProcess(uploadId){
      this.reportLoader=true;
      let url=`stop-process?process_name=gis_marks_validate&upload_id=${uploadId}&user_id=${this.api.user_id}`;
      this.notify.notify('Request Submitted to Server','warn');
      this.api.getGmsData(url).subscribe((res:any)=>{
        this.reportLoader=false;
      },err=>{
        this.reportLoader=false;
        this.notify.notify(err.error.message,'error');
      })
    }
    getStatus(val) {
      return this.getArrayValue('status_code', val, this.status).value;
    }
    getActive_(e) {
      return this.active.getIsActive(e.value);
    }

    getdateForm(date: any) {
      if (date) {
        date = new Date(date);
        return this.fnc.formatDate(date);
      }
      else {
        return '';
      }
    }

    isAccepted(v) {
      if (v == 'Valid') {
        return true;
      }
    }

    isRejected(v) {
      if (v == 'Invalid' || v == 'Valid') {
        return true;
      }
    }

    handlePagination(e) {
      this.limit = e.pageSize;
      if (e.previousPageIndex !== e.pageIndex) {
        this.offset = (e.pageSize * e.pageIndex);
      }
      this.getMarksUploadReport();
    }

    getArrayValue(key: any, val: any, arrayObj: any): any {
      let res = null;
      let flag = true;
      if (arrayObj) {
        arrayObj.forEach(obj => {
          if (obj[key] == val && flag) {
            res = obj;
            flag = false;
          }
        });
      }
      return res;
    }

  ngOnInit() {
  }

}
