<div class="parent-container">
    <div fxLayout="row" class="w-100 crumb-c p-2" fxLayoutAlign="space-between center">
        <div class="title">Log service</div>
        <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
            <div class="mx-2"><input placeholder="Search by program name & description" class="form-control logsear" [formControl]="searchField"  type="text"></div>
            <mat-select (selectionChange)="filterLog($event)"  [(ngModel)]="filter_log" class="form-control">
                <mat-option [value]="'true'">Enable</mat-option>
                <mat-option [value]="'false'">Disable</mat-option>
            </mat-select>
           
            <button mat-button (click)="disableAll()" class="mx-1 sync-btn"> DISABLE ALL </button>
        </div>
    </div>
</div>

<div>
    <div class="table-container">
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        <table mat-table [dataSource]="dataSource" class="sync-table w-100">
            <ng-container *ngFor=" let cols of displayedColumns">
                <ng-container matColumnDef="{{ cols }}">
                    <ng-container>
                        <th mat-header-cell *matHeaderCellDef [class.w-110]="cols == 'sno'">
                            <span *ngIf="cols == 'sno'" class="open-sans font-weight-bold">S.N.</span>
                            <span *ngIf="cols == 'module_name'" class="open-sans font-weight-bold">Module Name</span>
                            <span *ngIf="cols == 'program_name'" class="open-sans font-weight-bold sort-p" (click)="tableSort('program_name')">Program Name <img src="../../../assets/icon/down-arrow.svg" alt="down" [class.rotate-180]="program_sort"> </span>
                            <span *ngIf="cols == 'program_desc'" class="open-sans font-weight-bold">Description</span>
                            <span *ngIf="cols == 'added_date'" class="open-sans font-weight-bold sort-p"  (click)="tableSort('added_date')">Added Date <img src="../../../assets/icon/down-arrow.svg" alt="down" [class.rotate-180]="added_sort"></span>
                            <span *ngIf="cols == 'last_updated'" class="open-sans font-weight-bold sort-p"  (click)="tableSort('last_updated')">Last updated Date <img src="../../../assets/icon/down-arrow.svg" alt="down" [class.rotate-180]="last_date_sort"></span>
                            <span *ngIf="cols == 'action'" class="open-sans font-weight-bold">Log - Enable / Disable</span>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="dataSource.length>0">
                        <td mat-cell *matCellDef="let element; let i=index;">
                            <span class="f-14" *ngIf="cols == 'sno'">
                                {{(i+1) + offset}}.
                            </span>
                            <div *ngIf="cols == 'action'">
                                <mat-slide-toggle [checked]="element.details_log" (change)="updateDetailsLog(element.id,$event, element.details_log)"></mat-slide-toggle>
                           
                                    
                            </div>
                            <p matTooltip={{returnVal(element,cols)}} *ngIf="cols !== 'action' && cols != 'sno'"  class="more f-14 open-sans">
                                {{returnVal(element, cols)}}</p>

                        </td>
                    </ng-container>

                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        </table>
    </div>
</div>
<div class="w-100" fxLayout="row">
    <mat-paginator class="w-100" [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = $event; handlePagination($event)">
    </mat-paginator>
  </div>