<div class="min100">
  <mat-toolbar class="matpopup-header">
    <div *ngIf="mode == 'image'">Images</div>
    <div *ngIf="mode == 'video'">Videos</div>
  </mat-toolbar>
  <form [formGroup]="imageForm">
    <div class="rel">
      <div class="modal addCent" *ngIf="addImageDiv">
        <div class="addCent mat-elevation-z6">
          <div class="description_div">
            <mat-chip-option *ngFor="let name of photoList" class="pChip animated fadeIn delay-2s" [selectable]="selectable"
              [removable]="removable" (removed)="remove(null,name)">
              <div>
                <ngx-loading [show]="name.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                </ngx-loading>
                <img id="i-def" src="{{name.imageURL}}" alt="Image">
              </div>
            </mat-chip-option>

            <div class="savebtn">
              <input id="image_input" (change)="uploadImages($event)" class="hidden" accept="image/*" type="file"
                formControlName="image" />
              <button mat-raised-button color="primary" (click)="openExplorer('image')">Upload Photo</button>
              &nbsp; &nbsp;
              <button mat-raised-button color="danger" (click)="cancel()">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal addCent" *ngIf="addVideoDiv">
        <div class="addCent mat-elevation-z6">
          <div class="description_div">
            <div class="videoChips">
              <mat-chip-option *ngFor="let data of videoList" class="pChip animated fadeIn delay-2s" [selectable]="selectable"
                [removable]="removable" (removed)="remove('vid', data)">
                <div id="selection">

                  <ngx-loading [show]="data.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                  </ngx-loading>
                  <img id="i-def" src="{{data.videoThumbURL}}" alt="Image">
                </div>
              </mat-chip-option>
            </div>
            <div class="savebtn">
              <input id="video_input" (change)="uploadVideos($event)" accept="video/*" class="hidden" type="file" />
              <button mat-raised-button color="primary" (click)="openExplorer('video')">Upload Video</button>
            </div>
            <div class="url">
              <div><input id="video_url" class="url-input" type="text" name="url" placeholder="video url"> </div>
              <button mat-raised-button color="primary" (click)="uploadVideoByUrl()" id="resize">
                <mat-icon class="icon-upload">publish</mat-icon>
              </button>
              <button mat-raised-button class="danger" (click)="cancel()">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="imageGallery.length > 0 && mode == 'image'">
        <div class="modal cent" *ngIf="showConfirm">
          <div class="cent mat-elevation-z6">
            <button mat-raised-button color="primary" (click)="confirm('image')">Confirm</button>
            <button mat-raised-button color="danger" (click)="cancel()">Cancel</button>
          </div>
        </div>
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        <div class="imgGallery">
          <div *ngFor="let data of imageGallery" class="position-relative">
            <img src="{{data.url}}" alt="" class="slieImg">
           <div class="text-right">
             <button (click)="showModal('addImage', null)" mat-mini-fab class="add-btn mat-elevation-z6">
          <i class="material-icons">add</i>
        </button>
            <button (click)="showModal('delele', data.url)" mat-mini-fab class="del-btn mat-elevation-z6">
              <mat-icon class="bin" svgIcon="delete-icon"></mat-icon>
            </button>
           </div>
          </div>
        </div>
      </div>

      <div *ngIf="videoGallery.length > 0 && mode == 'video'">
        <div class="modal cent" *ngIf="showConfirm">
            <div class="cent mat-elevation-z6">
              <button mat-raised-button class="orangebutton btn0" (click)="confirm('video')">Confirm</button>
              <button mat-raised-button class="can btn0 ml-1" (click)="cancel()">Cancel</button>
            </div>
          </div>

      <video #video controls class="player mat-elevation-z6" controlsList="nodownload">
        <source src="{{selectedSource}}" type="video/mp4">
          Your browser does not support HTML5 video.
        </video>
        <div class="flow-auto">
          <div class="w-100 cen-r">
            <div class="v-card" [ngClass]="{'lnk': video.url, 'bordered': video.url == selectedSource}"
              *ngFor="let video of videoGallery" (click)="playSelected(video.url)">
              <!-- <span class="descrip">{{video.url}}</span> -->
              <span class="play">
                <mat-icon>play_arrow</mat-icon>
              </span>
            </div>
          </div>
        </div>
        <button (click)="showModal('addVideo')" mat-mini-fab class="add-btn mat-elevation-z6">
          <i class="material-icons">add</i>
        </button>
        <button (click)="showModal('delele')" mat-mini-fab class="del-btn mat-elevation-z6">
          <mat-icon class="bin" svgIcon="delete-icon"></mat-icon>
        </button>
      </div>
    </div>

    <div class="rel">
      <div class="center" *ngIf="imageGallery.length == 0 && videoGallery.length == 0">
        <span *ngIf="imageGallery.length == 0 && mode == 'image'">No Images found</span>
        &nbsp;
        <button *ngIf="imageGallery.length == 0 && mode == 'image'" (click)="showModal('addImage')" mat-raised-button
          color="primary" class="mat-elevation-z6">
          <i class="material-icons">add</i> &nbsp; Add Images
        </button>
        <span *ngIf="videoGallery.length == 0 && mode == 'video'">No Videos found</span>
        <button *ngIf="videoGallery.length == 0 && mode == 'video'" (click)="showModal('addVideo')" mat-raised-button
          color="primary" class="mat-elevation-z6">
          <i class="material-icons">add</i> &nbsp; Add Video
        </button>
      </div>
    </div>
  </form>
</div>
