import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects'; //npm i -S @ngrx/effects
import { Action, Store } from '@ngrx/store';

import { Observable, map, switchMap, timer } from 'rxjs';
// import { Observable } from 'rxjs/Observable';

import * as ApplicationActions from './actions';

@Injectable()
export class ApplicationEffects {

    // APPLICATION_TIMEOUT_TIME = 1000 * 5;
    APPLICATION_TIMEOUT_TIME = 1000 * 60 * 30;

    
    extendApplicationTimeout$ = this.actions$.pipe(
        switchMap( ( action: Action ) => timer(this.APPLICATION_TIMEOUT_TIME) ))
        .pipe(map(() => new ApplicationActions.LogOut()));

    constructor( private actions$: Actions ) {}
}
