import { Injectable } from '@angular/core';
import { ApiserviceService } from '../apiservice.service';

@Injectable()
// @Injectable({
//   providedIn: 'root'  // or provide it in your specific module
// })
export class CommonfunctionService {

  constructor(private api: ApiserviceService) {
    //this.checkResourceAccess('attributegroups','GET',false);

  }

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }

  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = {};
    let flag = true;
    arrayObj.forEach(obj => {
      if (obj[key] == val && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach(obj => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) { // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value) {
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0) +" "+value.getHours().toString().padStart(2, 0)+":"+value.getMinutes().toString().padStart(2, 0);
  }

  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj, k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  getErrorMessage(err) {
    let msg = JSON.parse(JSON.stringify(err));
    return msg.message;
  }


  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false
    }
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    resources.forEach(element => {
      if (element.resource_name == name) {
        if (element.methods.indexOf('GET') != -1) {
          resource.GET = true;
        }
        if (element.methods.indexOf('POST') != -1) {
          resource.POST = true;
        }
        if (element.methods.indexOf('PATCH') != -1) {
          resource.PATCH = true;
        }
        if (element.methods.indexOf('DELETE') != -1) {
          resource.DELETE = true;
        }
      }
    });
    return resource;
  }
// date format UTC
formatDateUTC(dt: any, getTime: boolean = false) {
  let value;
  dt = new Date(dt);
  value = new Date(dt + ' UTC');
  if (getTime) {
    return  value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'}) + " " + value.getHours().toString().padStart(2, 0) + ":"
      + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
  } else {
    return (
      value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'})
    );
  }
}

escapeMetaCharacters(inputString) {
  var outputString = "";
  try {
      var metaCharacters = ["\\", "^", "$", "*", "+", "?", "|", "<", ">", "&", "'"];
      for (var i = 0; i < metaCharacters.length; i++) {
          if (inputString.includes(metaCharacters[i])) {
              if (metaCharacters[i] == "'") {
                  outputString = inputString.replaceAll(metaCharacters[i], "''");
              }
              else {
                  outputString = inputString.replace(metaCharacters[i], "\\" + metaCharacters[i]);
              }
              inputString = outputString;
          }
          else {
              outputString = inputString;
          }
      }
      return outputString;
  } catch (error) {
     console.log(`escape Meta Characters error  escapeMetaCharacters() | ${error}`);

  }
}

removeMetaCharacters(inputString) {
  var outputString = "";
  try {
      var metaCharacters = ["\\"];
      for (var i = 0; i < metaCharacters.length; i++) {
          if (inputString.includes(metaCharacters[i])) {
              if (metaCharacters[i] == "\\") {
                  outputString = inputString.replaceAll("\\", "");
              }
              inputString = outputString;
          }
          else {
              outputString = inputString;
          }
      }
      return outputString;
  } catch (error) {
     console.log(`escape Meta Characters error  escapeMetaCharacters() | ${error}`);

  }
}


    /**
   * GetSortOrder Function for shorting orders
   * @param prop
   * @returns uint
   */
    GetSortOrder(prop) {
      return function (a, b) {
        if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
          return 1;
        } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
          return -1;
        }
        return 0;
      }
    }

    camelizeAll(str) {
      const arr = str.split(' ');
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(' ');
      return str2;
    }

    // CONVER TO percent
    percentageConvertor(total:any, current:any): number{
      let val:number=0;
      if(total && current){
        let t = Number(total);
        let c = Number(current);
        val = Number(((c * 100)/t).toFixed(0));
        return val;
      }
      return val;
    }

    // get diffrence between start date and end date
    getDateDifference(startDate, endDate){
      let val='';
      if(startDate && endDate){
        const firstDate = new Date(this.formatDateUTC(startDate, true))
        const lastDate = new Date(this.formatDateUTC(endDate, true))
        const milliseconds = Math.floor((lastDate.getTime() - firstDate.getTime()));
        const seconds = Math.floor((milliseconds / 1000) % 60);
        const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
        const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);
        val = this.str_pad_left(hours, '0', 2) + ':'+ this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
        return val;
      }
      return val;
    }
    // date format in HH:MM:SS
    str_pad_left(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length);
    }

}
