<div class="parent-container">
    <mat-card appearance="outlined" class="example-card">
        <mat-card-header class="pl-4">
            <mat-card-title class="master-title title open-sans">Parcel Marks</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="bbar w-100" *ngIf="calculate">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <ngx-loading [show]="upperLoader"
                [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            <div class="gis_data_form">
                <form [formGroup]="fileUploadForm">
                    <div class="bottom-border"
                        style="display: flex; justify-content: space-between; align-items: center;">
                        <div>
                            <mat-radio-group (change)="selectedActionType($event.value)">
                                <mat-radio-button class="radio_text" *ngFor="let option of options"
                                    [value]="option.value">
                                    {{option.label}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div *ngIf="actionType=='upload'">
                                <div>
                                    <span class="fntcustom" *ngIf="fileName">{{fileName}}</span>
                                </div>
                                <div class="btnGrid">
                                    <div class="upload-btn-wrapper">
                                        <button mat-button class="browesbtn">
                                            <mat-icon class="upicon" svgIcon="cloud_upload"></mat-icon> Browse
                                        </button>
                                        <input type="file" name="myfile" #myFileInput (change)="handleFileInput($event)"
                                            formControlName="file" accept=".zip" />
                                    </div>
                                    <button (click)="uploadFile(fileUploadForm)" type="button" mat-raised-button
                                        color="primary" [disabled]="fileUploadForm.invalid">
                                        <span *ngIf="!loading">Upload</span>
                                        <span *ngIf="loading">Uploading...</span>
                                    </button>
                                </div>
                                <!-- <div class="button-container" fxLayout="column" fxLayoutAlign="space-evenly start">
                  <mat-form-field class="mr-2">
                    <mat-select placeholder="Select Mark Type" (selectionChange)="selectedMarksType($event.value)"
                      formControlName="markType">
                      <mat-option *ngFor="let action of actions" [value]="action.value">
                        {{action.label}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div>
                    <div class="upload-btn-wrapper mr-3">
                      <button [disabled]="!uploadMarksType" mat-button class="browesbtn">
                        <mat-icon svgIcon="cloud_upload" class="upicon"></mat-icon> Browse
                      </button>
                      <input type="file" name="myfile" (change)="handleFileInput($event)" formControlName="file"
                        accept=".zip" />
                    </div>
                    <button (click)="uploadFile(fileUploadForm)" [disabled]="!fileName" type="button" mat-raised-button
                      color="primary">
                      <span *ngIf="!uploadLoading">Upload Weightage</span>
                      <span *ngIf="uploadLoading">Uploading...</span>
                    </button>
                  </div>

                </div> -->
                            </div>
                            <div class="d-flex justify-content-between align-items-center"
                                *ngIf="actionType=='calculate'" style="padding-left: 25px;">
                                <div>
                                    <mat-form-field class="pr-3">
                                        <mat-select placeholder="Select Mark Type"
                                            (selectionChange)="selectedMarksType($event.value)"
                                            formControlName="markType">
                                            <mat-option *ngFor="let action of actions" [value]="action.value">
                                                {{action.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="mr-2">
                                        <mat-select (selectionChange)="selectNeigh($event.value)"
                                            placeholder="Select Neighbourhood" formControlName="neigh"
                                            (openedChange)="searchNeigh = ''">
                                            <input class="searchIn" mat-input [(ngModel)]="searchNeigh"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder="Type here to search..."
                                                (keydown)="$event.stopPropagation()" />
                                            <mat-option [value]=''>Select Neighbourhood</mat-option>
                                            <mat-option *ngFor="let list of neighbourhoods | neighFilter:searchNeigh"
                                                [value]="list.neigh_uid" (click)="searchNeigh=''">
                                                {{ list.neigh_name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field class="pl-3">
                                        <mat-select placeholder="Select Block"
                                            (selectionChange)="selectBlock($event.value)" formControlName="block"
                                            (openedChange)="searchBlock = ''">
                                            <input class="searchIn" mat-input [(ngModel)]="searchBlock"
                                                [ngModelOptions]="{standalone: true}"
                                                placeholder="Type here to search Block Name..."
                                                (keydown)="$event.stopPropagation()" />
                                            <mat-option [value]=''>Select Block</mat-option>
                                            <mat-option
                                                *ngFor="let block of blocks | searchfilter:'block_name':searchBlock"
                                                [value]="block.block_uid" (click)="searchBlock=''">
                                                {{ block.block_name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="sub-btn-container">
                                    <a mat-raised-button color="primary" (click)="openConfirmation()"
                                        matTooltip="Marks calculation" style="color: white;">
                                        Calculate Marks </a>
                                    <a class="ml-1" mat-raised-button color="primary" (click)="downloadLog()"
                                        matTooltip="Download log" style="color: white;">
                                        Download Marks</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card-content>
        <!-- <div class="bbar w-100" *ngIf="loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div> -->
    </mat-card>

    <mat-card appearance="outlined" class="example-card">
        <mat-card-header>
            <mat-card-title class="master-title title open-sans">Parcel Marks Report</mat-card-title>
            <div class="filter ml-3" fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                    <button mat-mini-fab (click)="getMarksUploadReport()" matTooltip="Refresh List" class="refresh-btn">
                        <mat-icon [ngClass]="{'rotation': reportLoader}">sync</mat-icon>
                        <!-- <i class="fa fa-spinner fa-spin"></i> -->
                    </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="open-sans mr-1">Filte By Status:</span>
                    <mat-select class="filter-by" placeholder="Select Status"
                        (selectionChange)="filterByStatus($event.value,'status')">
                        <!-- <mat-option value=''>Select Filter</mat-option> -->
                        <mat-option value='All'>All</mat-option>
                        <mat-option *ngFor="let value of statuses" [value]="value.status">
                            {{getStatus(value.status)}}
                        </mat-option>
                    </mat-select>
                </div>

            </div>
        </mat-card-header>
        <mat-card-content class="tabs">
            <ngx-loading [show]="reportLoader"
                [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            <!-- <div class="bottom-border">
        <button class="margr5 open-sans" *ngFor="let action of actions" [ngClass]="{'active': getActive_(action)}"
          (click)="getMarksUploadReport(action.value)">
          {{action.label}}</button>
      </div> -->
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" matSort class="w-100 table">
                    <ng-container *ngFor="let cols of displayedColumns">
                        <ng-container matColumnDef="{{ cols }}">
                            <ng-container *ngIf="cols=='id' || cols=='uid'">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                    <span class="header action cp" *ngIf="cols == 'id'"> Upload ID </span>
                                    <span class="header action cp" *ngIf="cols == 'uid'"> Name</span>
                                </th>
                            </ng-container>
                            <ng-container *ngIf="cols!='id' || cols!='uid'">
                                <th mat-header-cell *matHeaderCellDef>
                                    <span class="open-sans" *ngIf="cols == 'sno'">S.No</span>
                                    <span *ngIf="cols!='sno'" class="header action cp open-sans">{{ cols }}</span>
                                </th>
                            </ng-container>
                            <td mat-cell *matCellDef="let element; let i=index;">
                                <span class="open-sans" *ngIf="cols == 'sno'"> {{offset+i+1}}.</span>
                                <span class="open-sans" [ngClass]="{green: getStatus(element.status)=='Accepted'}"
                                    *ngIf="cols=='Status' && getStatus(element.status)=='Accepted'">{{getStatus(element.status)}}</span>
                                <span class="open-sans" [ngClass]="{red: getStatus(element.status)=='Rejected'}"
                                    *ngIf="cols=='Status' && getStatus(element.status)=='Rejected'">{{getStatus(element.status)}}</span>
                                <span class="open-sans" [ngClass]="{red: getStatus(element.status)=='Invalid'}"
                                    *ngIf="cols=='Status' && getStatus(element.status)=='Invalid'">{{getStatus(element.status)}}</span>
                                <span class="open-sans" *ngIf="cols=='Status' && getStatus(element.status)!='Invalid'
                   && getStatus(element.status)!='Rejected'
                    && getStatus(element.status)!='Accepted'">{{getStatus(element.status)}}</span>
                                <!-- <button class="view" *ngIf="cols == 'Parameter'" mat-button (click)="openView('parameter',element)"
                [disabled]="element.parameter==null">View</button>
              <button class="view" *ngIf="cols == 'Process Log'" mat-button (click)="openView('process',element)"
                [disabled]="element.process_log==null">View</button> -->

                                <!-- <a class="view" *ngIf="cols == 'Error File'" mat-button href="{{element.log_file}}" target="_blank"
                [disabled]="element.err_file==null">Error Log</a>
              <a class="view" *ngIf="cols == 'Log File'" mat-button href="{{element.err_file}}" target="_blank"
                [disabled]="element.log_file==null">Log</a> -->
                                <div *ngIf="cols == 'Logs'">
                                    <a target="_blank" href="{{element.errfile_link}}" class="bordered line-hith"
                                        mat-button>Validate
                                        Log
                                    </a>
                                    <a target="_blank" href="{{element.accept_errfile_link}}"
                                        class="bordered line-hith ml-1" mat-button>Accept
                                        Log
                                    </a>
                                </div>
                                <div style="padding-top:10px ;" *ngIf="cols=='Actions'"
                                    class="d-flex justify-content-start align-items-center">
                                    <button [disabled]="!isAccepted(getStatus(element.status))"
                                        [ngClass]="{btndisabled: !isAccepted(getStatus(element.status))}"
                                        class="bordered line-hith prim mr-1" mat-button
                                        (click)="action(element.upload_id,'accept')">Accept</button>
                                    <button [disabled]="!isRejected(getStatus(element.status))"
                                        [ngClass]="{btndisabled: !isRejected(getStatus(element.status))}"
                                        class="bordered line-hith warn mr-1" mat-button
                                        (click)="action(element.upload_id,'reject')">Reject</button>
                                    <button [ngClass]="{btndisabled: getStatus(element.status)!='Start'}"
                                        [disabled]="getStatus(element.status)!='Start'" mat-button
                                        class="bordered line-hith warn mr-1" (click)="stopProcess(element.id)">
                                        Stop Process</button>
                                </div>
                                <p matTooltip={{returnVal(element,cols)}}
                                    *ngIf="cols != 'sno' && cols != 'Parameter' && cols != 'Process Log' && cols != 'Status'"
                                    class="more mb-0 open-sans">
                                    {{returnVal(element, cols)}}</p>

                            </td>
                        </ng-container>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
                </table>
            </div>
            <!-- <ng-container *ngIf="dataSource.length">
        <div fxLayout="column" fxLayoutAlign="space-around center">
          <div>
            <i class="material-icons error">error</i>
          </div>
          <div class="notfound">Record not found</div>
        </div>
      </ng-container> -->
            <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>

        </mat-card-content>
    </mat-card>

</div>