import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../../apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-poi-data',
  templateUrl: './poi-data.component.html',
  styleUrls: ['./poi-data.component.css']
})
export class PoiDataComponent implements OnInit {
  poiData = [];
  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  loader: boolean;
  pages: any = [];
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageNo: number = 1;
  limit: number = 10;
  offset: number = 0;
  count: number = 0;
  sn: number = 0;
  // record: number = 0;
  pageIndex: number = 0;

  constructor(private api: ApiserviceService, private fnc: CommonfunctionService, private notify: NotificationService) {
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
    this.getPoiData();
    //this.getTotal();
  }

  ngOnInit() {

  }

  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        // this.getNeighbourhoods();

      })
  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     // .map((data:any) => data.json())
  //     .subscribe(
  //       (data:any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         //this.getNeighbourhoods();
  //       },
  //       err => {

  //       }
  //     )
  // }

  getAllowResource() {
    this.upload_access = this.fnc.checkResourceAccess('gisDataUpload', false);
  }

  // getTotal() {
  //   let url = '/poi/json';
  //   this.loader = true;
  //   this.api.getGisData(url)
  //     .subscribe((res: any) => {
  //       this.total = res.length;
  //     },
  //       err => {
  //         this.notify.notify('Something went wrong', 'error');
  //       });
  // }

  getPoiData() {
    let url = 'poi/json?limit=' + this.limit + '&offset=' + this.offset;
    if(this.offset == 0){
      url +="&is_count=true"
    }

    this.loader = true;
    this.api.getGpsData(url)
      .subscribe((res: any) => {
        this.loader = false;
        if(this.offset == 0){
          this.total = res.totalCount;
        }
        // this.record = res.length;
        this.count = this.offset;
        this.poiData = [];
        if (res.data.length) {
          res.data.forEach(e => {
            this.sn = ++this.count;
            let d = {
              "sn": this.sn,
              "poi_id": e.poi_id,
              "poi_uid": e.poi_uid,
              "poi_name": e.poi_name,
              "category": e.category,
              "sub_category": e.sub_category,
              "lat": e.lat,
              "lon": e.lon,
              "shape_leng": e.shape_leng,
              "shape_area": e.shape_area,
              "status": e.status,
              "added_by": e.added_by,
              "updated_by": e.updated_by,
              "added_date": e.added_date,
              "updated_date": e.updated_date
            }
            this.poiData.push(d);

          });
        } else {
          this.poiData = [];
          this.notify.notify('No results found', 'warn');
        }
      },
        err => {
          this.loader = false;
          this.notify.notify('Something went wrong', 'error');
        });
  }

  // pagination
  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getPoiData();
  }

}
