import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AdminMenusService } from './admin-menus.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ShareService } from '../services/share.service'
import { ApiserviceService } from '../../apiservice.service';


interface AdminMenus {
  key: string,
  title?: string,
  icon?: string,
  url: string,
  show: boolean
}

interface userAccess {
  GET: boolean,
  POST: boolean,
  PATCH: boolean,
  DELETE: boolean
}
/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.css']
})
export class AdminSidebarComponent implements OnInit {
  @Input() size: any;
  adminMenus: AdminMenus[] = [
    {
      key: 'dashboard',
      title: "Dashboard",
      icon: 'dashboard',
      url: '/admin/dashboard',
      show: true
    },
    {
      key: 'gisDataUpload',
      title: "GIS Data Upload",
      icon: 'cloud_upload',
      url: '/admin/uploadgis',
      show: false
    },
    {
      key: 'legacyDataUpload',
      title: "Legacy Data Upload",
      icon: 'assignments',
      url: '/admin/uploadlegacy',
      show: false
    },
    {
      key: 'poi',
      title: "Poi Upload Data",
      icon: 'property_listing',
      url: '/admin/poi',
      show: false
    },
    {
      key: 'imageVideo',
      title: "Image and Video Upload Data",
      icon: 'cloud_upload',
      url: '/admin/image_video',
      show: false
    },
    {
      key: 'gisatrributes',
      title: "Gis Attributes",
      icon: 'attribute_group',
      url: '/admin/gis-attributes',
      show: false
    },
    {
      key: 'gis-sync',
      title: "Gis Sync",
      icon: 'synchornize',
      url: '/admin/gis-sync',
      show: false
    },
    {
      key: 'calculate-sync',
      title: "Calculate/Sync",
      icon: 'property_listing',
      url: '/admin/calculate-sync',
      show: false
    },
    {
      key: 'spark-calculate-sync',
      title: "spark-calculate-sync",
      icon: 'spark-calculate-sync',
      url: '/admin/spark-calculate-sync',
      show: false
    },
    {
      key: 'upload_calculation',
      title: "Calculate Upload",
      icon: 'field_settings',
      url: '/admin/calculate-upload',
      show: false
    },
    {
      key: 'upload_momra_zone_code',
      title: "Upload Momra Zone Code",
      icon: 'momra-zone',
      url: '/admin/upload-momra-zone-code',
      show: false
    },
    {
      key: 'dbprocess',
      title: "Database Processes",
      icon: 'dbprocess',
      url: '/admin/dbprocess',
      show: false
    },
    {
      key: 'log-settings',
      title: "Log Settings",
      icon: 'logicon',
      url: '/admin/log-settings',
      show: false
    },
    {
      key: 'marksupload',
      title: "Amenity Marks Upload",
      icon: 'marks',
      url: '/admin/marksupload',
      show: false
    },
    {
      key: 'sic',
      title: "SIC Results",
      icon: 'Objectives',
      url: '/admin/sic-results',
      show: false
    },
    {
      key: 'settings',
      title: "Settings",
      icon: 'settings',
      url: '/admin/settings',
      show: false
    }
  ];

  attributes_access: userAccess;
  constructor(private menus: AdminMenusService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private fnc: CommonfunctionService, private share: ShareService,
    private api: ApiserviceService) {
    iconRegistry.addSvgIcon(
      'gear',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/gear.svg'))
      .addSvgIcon('activity_detail', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/activity_detail.svg'))
      .addSvgIcon('field_settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/field_settings.svg'))
      .addSvgIcon('masters', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/masters.svg'))
      .addSvgIcon('property_listing', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_listing.svg'))
      .addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/settings.svg'))
      .addSvgIcon('property_type', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_type.svg'))
      .addSvgIcon('attribute_group', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/attribute_group.svg'))
      .addSvgIcon('cloud_upload', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/cloud_upload.svg'))
      .addSvgIcon('assignments', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
      .addSvgIcon('gisDataUpload', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
      .addSvgIcon('attribute_group', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/attribute_group.svg'))
      .addSvgIcon('assignments', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/my_listing.svg'))
      .addSvgIcon('dbprocess', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/dbprocess.svg'))
      .addSvgIcon('marks', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Parcelupdate_light.svg'))
      .addSvgIcon('logicon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/log_icon.svg'))
      .addSvgIcon('marks_active', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Parcelupdate_light.svg'))
      .addSvgIcon('synchornize', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/synchornize.svg'))
      .addSvgIcon('spark-calculate-sync', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/icon/spark-calculate-sync.svg'))
      .addSvgIcon('Objectives', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/Objectives.svg'))
      .addSvgIcon('momra-zone', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/momra-zone-upload.svg'));


    this.getAllowResources();
  }

  ngOnInit() {

  }
  // getting allow resources
  getAllowResources() {

    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
      //   this.api.allowResources = res.data;
      //   this.getAllowResource();
      // }, err => {
      // })
          this.api.allowResources = res.data;
          this.getAllowResource();
        })
  }

  isSmall() {
    return this.size === 'small';
  }

  isNormal() {
    return this.size === 'normal';
  }

  getActive(menu) {
    return this.menus.getIsActive(menu.key);
  }

  getAllowResource() {
    //this.attributes_access = this.fnc.checkResourceAccess('attributes');
    this.adminMenus.forEach((v, k) => {
      let resource: userAccess;
      switch (v.key) {
        case 'gisDataUpload':
          resource = this.fnc.checkResourceAccess('gisDataUpload');
          if (resource.GET) v.show = true;
          break;
        case 'legacyDataUpload':
          resource = this.fnc.checkResourceAccess('legacyDataUpload');
          if (resource.GET) v.show = true;
          break;
        case 'poi':
          resource = this.fnc.checkResourceAccess('gisDataUpload');
          if (resource.GET) v.show = true;
          break;
        case 'imageVideo':
          resource = this.fnc.checkResourceAccess('upload_media');
          if (resource.GET) v.show = true;
          break;
        case 'gisatrributes':
          resource = this.fnc.checkResourceAccess('gis_attributes');
          if (resource.GET) v.show = true;
          break;
        case 'calculate-sync':
          resource = this.fnc.checkResourceAccess('calculateParcelHighway');
          if (resource.GET) v.show = true;
          break;
          case 'spark-calculate-sync':
            resource = this.fnc.checkResourceAccess('spark_calculate_sync');
            if (resource.GET) v.show = true;
            break;
        case 'upload_calculation':
          resource = this.fnc.checkResourceAccess('upload_calculation');
          if (resource.GET) v.show = true;
          break;
          case 'upload_momra_zone_code':
            resource = this.fnc.checkResourceAccess('csv_data_upload');
            if (resource.GET) v.show = true;
            break;
        case 'gis-sync':
          resource = this.fnc.checkResourceAccess('gis-sync');
          if (resource.GET) v.show = true;
          break;
        case 'dbprocess':
          resource = this.fnc.checkResourceAccess('manage_dbprocess');
          if (resource.GET) v.show = true;
          break;
        case 'marksupload':
          resource = this.fnc.checkResourceAccess('marks_upload');
          if (resource.GET) v.show = true;
          break;
        case 'log-settings':
          resource = this.fnc.checkResourceAccess('log_settings');
          if (resource.GET) v.show = true;
          break;
        case 'sic':
          resource = this.fnc.checkResourceAccess('sic');
          if (resource.GET) v.show = true;
          break;
        case 'settings':
          resource = this.fnc.checkResourceAccess('settings');
          if (resource.GET) v.show = true;
          break;
        case 'default':
          break;
      }
    });
  }


}
