<div class="parent-container">
    <div fxLayout="row" class="w-100 crumb-c p-2" fxLayoutAlign="space-between center">
        <div fxLayout="row" class="cleft" fxLayoutAlign="start center">
          <div class="title">Sync service</div>
            <mat-select id="year" class="form-control search-inputbox  ml-3"(selectionChange)="onSelectionChang()" (openedChange)="searchType=''" placeholder="Select type" [(ngModel)]="select_type">
                <input class="searchIn" mat-input [(ngModel)]="searchType" [ngModelOptions]="{standalone: true}" placeholder="Search" (keydown)="$event.stopPropagation()" />
                <ng-container *ngFor="let list of syncType | searchfilter:'name':searchType">
                    <mat-option [value]="list.val">
                        {{list.name}}
                    </mat-option>
                </ng-container>
            </mat-select>
            <ng-container>
                <mat-select id="year" (selectionChange)="onSelectionChang()" class="form-control search-inputbox mx-1" (openedChange)="searchyear=''" placeholder="Select year" [(ngModel)]="selectedYear">
                    <input class="searchIn" mat-input [(ngModel)]="searchyear" [ngModelOptions]="{standalone: true}" placeholder="Search" (keydown)="$event.stopPropagation()" />
                    <mat-option *ngFor="let year of years | searchfilter:'val':searchyear" [value]="year.val">
                        {{year.val}}
                    </mat-option>
                </mat-select>
                <mat-select id="building" class="form-control search-inputbox mx-1 w-250"
                 placeholder="Building id" [(ngModel)]="selectedBuildingUid" (openedChange)="resetBuildFilter()">
                    <input class="searchIn" mat-input [formControl]="searchField"  placeholder="Search building uid" (keydown)="$event.stopPropagation()" />
                    <div class="tbar w-100"><mat-progress-bar *ngIf="buildingLoader" mode="indeterminate"></mat-progress-bar></div>
                    <mat-option *ngFor="let building of building_uids" [value]="building.gid">
                        {{building.building_uid}} | {{building.building_name}}
                    </mat-option>
                </mat-select>
                <ng-container>
                  <span class="d-flex align-items-center mx-2 position-relative">
                      <span class="">
                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                      </span>
                      <input [min]="mindate" [max]="today" class="form-control calender" [matDatepicker]="picker1" [(ngModel)]="custom_date" disabled>

                  </span>
              </ng-container>
              <button mat-button (click)="startSync()" class="mx-1 sync-btn"> Start Sync
              </button>
            </ng-container>

        </div>
        <button mat-mini-fab (click)="refresh_Data()" matTooltip="Refresh Data" class="refresh-btn" [ngClass]="{'rotation': reportLoader}">
          <mat-icon [ngClass]="{'rotation': reportLoader}">sync</mat-icon>
      </button>
    </div>
    <div *ngIf="loader">
      <mat-progress-bar
      class="w-100"
      [color]="'warn'"
      [mode]="'indeterminate'"
      [value]="'determinate'">
  </mat-progress-bar>
    </div>
</div>
<div style="position: relative;">
    <div style="overflow: auto; height: calc(100vh - 195px);">
        <table mat-table [dataSource]="dataSource" class="sync-table w-100">
            <ng-container *ngFor=" let cols of displayedColumns">
                <ng-container matColumnDef="{{ cols }}">
                    <ng-container>
                        <th mat-header-cell *matHeaderCellDef [class.w-110]="cols == 'sno' || cols == 'action'">
                            <span *ngIf="cols == 'sno'" class="open-sans font-weight-bold">S.N.</span>
                            <span *ngIf="cols == 'table_name'" class="open-sans font-weight-bold">Type</span>
                            <span *ngIf="cols == 'table_progress'" class="open-sans font-weight-bold">Record Processed</span>
                            <span *ngIf="cols == 'year'" class="open-sans font-weight-bold">Year</span>
                            <span *ngIf="cols == 'from_sync_date'" class="open-sans font-weight-bold">From sync date</span>
                            <span *ngIf="cols == 'to_sync_date'" class="open-sans font-weight-bold">To sync date</span>
                            <span *ngIf="cols == 'execution_time'" class="open-sans font-weight-bold">Execution Time</span>
                            <span *ngIf="cols == 'last_sync'" class="open-sans font-weight-bold">Execution date</span>
                            <span *ngIf="cols == 'status'" class="open-sans font-weight-bold">Status</span>
                            <span *ngIf="cols == 'remarks'" class="open-sans font-weight-bold">Remarks</span>
                            <span *ngIf="cols == 'action'" class="open-sans font-weight-bold">Action</span>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="dataSource">
                        <td mat-cell *matCellDef="let element; let i=index;">
                            <span class="f-14" *ngIf="cols == 'sno'">
                                {{i+1}}.
                            </span>
                            <div *ngIf="cols == 'action'" class="d-flex">
                                <!-- <svg *ngIf="download_sync.GET == true" xmlns="http://www.w3.org/2000/svg" width="22" height="27" viewBox="0 0 22 27" (click)="download(element)" class="">
                                    <g id="Group_1619" data-name="Group 1619" transform="translate(-1785 -257)">
                                      <g id="Path_358" data-name="Path 358" transform="translate(1785 257)" fill="#fff">
                                        <path d="M 20 26.25 L 2 26.25 C 1.310750007629395 26.25 0.75 25.68925094604492 0.75 25 L 0.75 2 C 0.75 1.310750007629395 1.310750007629395 0.75 2 0.75 L 14.24676513671875 0.75 C 14.65116214752197 0.9711630940437317 16.06217002868652 2.063750267028809 17.91703033447266 3.739619970321655 C 20.03350639343262 5.651843547821045 21.03698921203613 6.788915157318115 21.24516296386719 7.149438858032227 C 21.24433898925781 7.181902408599854 21.24387550354004 7.215140819549561 21.24340057373047 7.249229907989502 C 21.24151992797852 7.384039878845215 21.23991012573242 7.580599784851074 21.23855972290039 7.830080032348633 C 21.23603057861328 8.298919677734375 21.23426055908203 8.985859870910645 21.23331069946289 9.871820449829102 C 21.2317008972168 11.36268997192383 21.23234939575195 13.43842029571533 21.23521995544434 16.04136085510254 C 21.24011039733887 20.47269058227539 21.24990081787109 24.95686912536621 21.25 25.00164985656738 C 21.25 25.68925094604492 20.68925094604492 26.25 20 26.25 Z" stroke="none"/>
                                        <path d="M 2 1.5 C 1.724300384521484 1.5 1.5 1.724300384521484 1.5 2 L 1.5 25 C 1.5 25.27569961547852 1.724300384521484 25.5 2 25.5 L 20 25.5 C 20.27569961547852 25.5 20.5 25.27569961547852 20.5 25.00329971313477 C 20.49991035461426 24.95850944519043 20.49011039733887 20.47393035888672 20.48521995544434 16.04219055175781 C 20.48234939575195 13.43871021270752 20.4817008972168 11.36242961883545 20.48331069946289 9.87101936340332 C 20.48426055908203 8.983980178833008 20.48603057861328 8.295940399169922 20.48856925964355 7.826030731201172 C 20.48952484130859 7.650289535522461 20.49060440063477 7.500534057617188 20.4918155670166 7.3797607421875 C 20.15018844604492 6.934028625488281 19.15254592895508 5.865062713623047 17.38973999023438 4.27400016784668 C 15.75477981567383 2.798328399658203 14.52657890319824 1.828596115112305 14.03300857543945 1.5 L 2 1.5 M 2 0 L 14.36977005004883 0 C 15.01644992828369 0 22 6.155979156494141 22 7.051639556884766 C 21.96051025390625 7.020092010498047 22 25 22 25 C 22 26.10457038879395 21.10457038879395 27 20 27 L 2 27 C 0.8954296112060547 27 0 26.10457038879395 0 25 L 0 2 C 0 0.8954296112060547 0.8954296112060547 0 2 0 Z" stroke="none" fill="#ddd"/>
                                      </g>
                                      <path id="Path_357" data-name="Path 357" d="M777.45,4759.557v4.531a1.867,1.867,0,0,0,1.888,2.045c.015.005,5.232,0,5.232,0" transform="translate(1022 -4502)" fill="none" stroke="#ddd" stroke-width="1.5"/>
                                      <line id="Line_123" data-name="Line 123" y2="13" transform="translate(1796 264.5)" fill="none" stroke="#ddd" stroke-linecap="round" stroke-width="1.5"/>
                                      <path id="Polygon_1" data-name="Polygon 1" d="M3.788.212a.3.3,0,0,1,.424,0L7.488,3.488A.3.3,0,0,1,7.276,4H.724a.3.3,0,0,1-.212-.512Z" transform="translate(1800 279) rotate(180)" fill="#ddd"/>
                                    </g>
                                  </svg> -->
                                  <button class="btn-stop" (click)="stopProcess(element)" [disabled]="element.text_status != 1">Stop Process</button>
                            </div>
                            <div *ngIf="cols == 'table_progress'">
                                <app-progress-bar [percent]="element?.table_progress" [min]="element?.record_processed" [max]="element?.record_to_be_processed"></app-progress-bar>
                             </div>
                            <div *ngIf="cols == 'execution_time'" [matTooltip]="getTooltip(element)">
                                {{element[cols]}}
                            </div>
                            <div *ngIf="cols == 'from_sync_date' || cols == 'to_sync_date'">
                              {{element[cols]}}
                            </div>
                            <div *ngIf="cols == 'status'">
                                <span [class.text-danger]="element.text_status == -1 || element.text_status == -2"
                                [class.text-success]="element.text_status == 2">{{element[cols]}}</span>
                            </div>
                            <p matTooltip={{element[cols]}} *ngIf="cols !== 'action' && cols != 'table_progress' && cols != 'execution_time' &&  cols != 'status'  && cols != 'from_sync_date' && cols != 'to_sync_date'"  class="more f-14 open-sans">
                              {{element[cols]}}</p>
                        </td>
                    </ng-container>

                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        </table>
    </div>
    <div>
      <mat-paginator #paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event;
       handlePagination($event)">
      </mat-paginator>
    </div>
    <div  class="alert-div" *ngIf="dataSource?.length && download_sync.GET == true">Note -  {{alertmessage}}</div>
</div>
