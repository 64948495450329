<div [ngClass]="{'sidebarnormal' : isNormal(), 'sidebarsmall' : isSmall()}">
  <mat-toolbar class="headtoolbar" color="primary">
    <div fxLayout="row" class="w-100 lnk" *ngIf="isSmall()" fxLayoutAlign="center center">
      <img routerLink="/admin" src="../../../assets/images/logo-mini-new.svg" width="32" />
    </div>
    <div fxLayout="row" class="w-100 lnk" *ngIf="isNormal()" fxLayoutAlign="center center">
      <img routerLink="/admin" src="../../../assets/images/logo.svg" width="170" />
    </div>
  </mat-toolbar>
  <div color="primary">
    <ng-container *ngFor="let menus of adminMenus">
      <div *ngIf="menus.show" class="itemlist" [routerLink]="menus.url"  title="{{menus.title}}"
        [ngClass]="{'active':getActive(menus)}">
        <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}">
        </mat-icon>
        <!-- <mat-icon *ngIf="getActive(menus) && menus.key=='marksupload'" svgIcon="{{menus.icon}}_active"
          title="{{menus.title}}">
        </mat-icon> -->
        <div class="menu-text" *ngIf="isNormal()">{{menus.title}}</div>
      </div>
    </ng-container>
  </div>
</div>
