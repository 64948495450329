<div class="table-estater table">
  <table class="attr-table table_data">
    <thead>
      <tr>
        <th> S. No. </th>
        <th> Poi Uid </th>
        <th> Name </th>
        <th> Category </th>
        <th> Sub Categoryt</th>
        <th> Added Date </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pd of poiData;">
        <td width="5%"> {{pd.sn}}</td>
        <td> {{pd.poi_uid}}</td>
        <td> {{pd.poi_name}} </td>
        <td> {{pd.category}} </td>
        <td>{{pd.sub_category}}</td>
        <td>{{pd.added_date}}</td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="loader" class='loader-div'>
    <ngx-loading [show]="loader"></ngx-loading>
  </div>
</div>
<mat-paginator [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
</mat-paginator>