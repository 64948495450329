<div fxLayout="row" class="w-100 over_auto crumb-c px-3" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button class="back-btn" (click)="backtoquery()"><img style="height:10px;" src="assets/icon/back_arrow.svg"
                alt=""> Back</button>
        <h4 class="open-sans mb-0 text-nowrap" style="color: #616474;">{{query_name}}
        </h4>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <mat-select class="sort-inputbox" placeholder="Sort By" [(ngModel)]="orderBy"
            (selectionChange)="onchangeFilter()">
            <mat-option *ngFor="let type of sort_by" [value]="type.value">
                {{ type.label[api.language] }}
            </mat-option>
        </mat-select>

        <mat-select class="sort-inputbox" [(ngModel)]="sortAsc" placeholder="Sort Order"
            (selectionChange)="onchangeFilter()">
            <mat-option value="true"><span>ASC</span></mat-option>
            <mat-option value="false"><span>DES</span></mat-option>
        </mat-select> -->
        <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
            <div class="d-flex align-items-center">
                <mat-select class="sort-inputbox" [(ngModel)]="selectedYear" placeholder="Select Year">
                    <!-- <mat-option [value]="'all'">All</mat-option> -->
                    <mat-option *ngFor="let year of yearList" [value]="year.name">
                        {{ year.name }}
                    </mat-option>
                </mat-select>
                <button class="execute" (click)="execute()" [disabled]="selectedYear=='all'">Execute</button>
            </div>
        </div>
    </div>
</div>
<div class="">
    <div class="table-div w-100 px-3 table-responsive">
        <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>
        <mat-progress-bar *ngIf="loaderExcute" mode="indeterminate"></mat-progress-bar>

        <table mat-table [dataSource]="dataSource" [class.text-right]="selectedLangCode== 'ar'" class="sic-table w-100"
        matSort (matSortChange)="onchangeFilter($event)">
            <ng-container *ngFor=" let cols of displayedColumns">
                <ng-container matColumnDef="{{ cols }}">
                    <ng-container *ngIf="cols == 'SIC date' || cols == 'Year'">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header>
                            <span class="open-sans font-weight-bold">{{cols}}</span>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="cols != 'SIC date' || cols != 'Year'">
                        <th mat-header-cell *matHeaderCellDef>
                            <span class="open-sans font-weight-bold">{{cols}}</span>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="dataSource">
                        <td mat-cell *matCellDef="let element; let i=index;">
                            <span class="f-14" *ngIf="cols == 'SN'">
                                {{offset+i+1}}.
                            </span>
                            <div *ngIf="cols == 'Action'">
                                <button class="download-btn" (click)="download(element)">Download</button>
                            </div>
                            <p matTooltip={{returnVal(element,cols)}} *ngIf="cols != 'SN' && cols != 'Action'"
                                class="f-14 open-sans p-0 m-0">
                                {{returnVal(element, cols)}}</p>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
        </table>
        <ng-container *ngIf="dataSource?.length == 0 && !loader">
            <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 8rem;">
                <span class="material-icons mr-2 availability">
                    error_outline
                </span>
                <span class="availability">Data not available</span>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="dataSource">
        <div class="d-flex mt-2 justify-content-end">
            <mat-paginator [length]="total" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent = $event; handlePagination($event)">
            </mat-paginator>
        </div>
    </ng-container>
</div>