import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-db-processes',
  templateUrl: './db-processes.component.html',
  styleUrls: ['./db-processes.component.css']
})
export class DbProcessesComponent implements OnInit {
  displayedColumns = ['SN',
    'datname',
    'Age',
    'Process ID',
    'Query',
    'State',
    'Actions']
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<unknown>;
  pname: FormControl;
  loader: boolean = false;
  totalCount: any;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  limit: number = 10;
  offset: number = 0;
  processId: any;

  returnVal(element, dispCol) {
    switch (dispCol) {
      case 'datname': return element.datname;
      case 'Age': return element.age;
      case 'Process ID': return element.pid;
      case 'Query': return element.query;
      case 'State': return element.state;
    }
  }
  public process_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false,
  };
  constructor(private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    public dialog: MatDialog,
    private notify: NotificationService) {
    this.menus.setActive('dbprocess');
    this.getAllowResourcesApi();
  }

  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.process_access = this.fnc.checkResourceAccess('manage_dbprocess', false);
        this.getDbProcess();


      })
  }
  getDbProcess(searchterm = null) {
    if (!this.process_access.GET) {
      this.notify.notify('You are not Authorized to GET DB Processes', 'warn', 4000);
      return;
    }
    let url = `get-db-process?active_process=true&limit=${this.limit}&offset=${this.offset}`;
    if (searchterm) {
      url += `&process_name=${searchterm}`;
    }
    this.loader = true;
    this.api.getGmsData(url).subscribe((res: any) => {
      this.loader = false;
      if (res) {
        this.dataSource = res;
        this.totalCount = res.length;
      }
    },err=>{
      this.loader = false;
    })
  }


  openConfirmation(element) {
    if (!this.process_access.POST) {
      this.notify.notify('You are not Authorized to STOP DB Processes', 'warn', 4000);
      return;
    }
    this.processId = element.pid;
    let _data = {
      parent_data: this,
      message: 'Do you really want to Stop Running Process?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      this.stopProcess()
    }
  }

  stopProcess() {
    this.notify.notify("Request Submitted", "success", 3000)

    this.loader = true;
    this.api.getGmsData(`stop-process?process_id=${this.processId}`).subscribe((res: any) => {

      this.loader = false;
      if (res == 'true') {
        this.notify.notify("Process Stopped", 'warn', 4000);
      }
      if (res == 'false') {
        this.notify.notify("Process Cannot be stopped", 'warn', 4000);
      }
    }, err => {
      this.loader = false;
      this.notify.notify("Some Error", "error")
    })
    setTimeout(() => {
      this.getDbProcess();
    }, 1000);

  }

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getDbProcess();
  }

  ngOnInit() {
    this.pname = new FormControl();
    this.pname.valueChanges.pipe(debounceTime(500)).subscribe(term => {
      term = term.trim().toLowerCase();
      if (term) {
        this.getDbProcess(term)
      } else if (!term) {
        this.getDbProcess(null)
      }
    })
  }
}
