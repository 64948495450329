import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { NotificationService } from "../services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { GisDataService } from "../gis-data-upload/gis-data-upload.service";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

export interface options {
  label: string;
  value: string;
}
export interface status {
  status_code: number;
  value: string;
}

@Component({
  selector: 'app-calculate-upload',
  templateUrl: './calculate-upload.component.html',
  styleUrls: ['./calculate-upload.component.css']
})
export class CalculateUploadComponent implements OnInit {
  status: status[] = [
    { status_code: -1, value: 'Invalid' },
    { status_code: 1, value: 'Valid' },
    { status_code: 2, value: 'Accepted' },
    { status_code: -2, value: 'Rejected' },
    { status_code: 0, value: 'Uploaded' },
    { status_code: -3, value: 'Accept Invalid' },
    { status_code: 4, value: 'Accept Progress' }
  ];
  public calculate_access = {
    GET: false,
    POST: true,
    PATCH: false,
    DELETE: false,
  };
  public calculate_highway_access = {
    GET: false,
    POST: true,
    PATCH: false,
    DELETE: false,
  };
  public calculate_amenity_distance = {
    GET: false,
    POST: true,
    PATCH: false,
    DELETE: false,
  };
  displayedColumns = [
    "sno",
    "upload_id",
    "process_name",
    "Added Date",
    "Accept Time",
    "Validate time",
    // "Existing Count",
    // "New Count",
    // "Updated Date",
    "View Log",
    // "Error File",
    "Status",
    "Actions",
  ];
  loader1: boolean;
  process_name: any;
  totalCount: any;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  selectedBlock: any;
  parcel = "";
  cluster = "";
  degree = "";
  searchAttr: string = "";
  searchNeigh: string = "";
  searchBlock: string = "";
  gisAttributes: any = [];
  layer: any;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  filter: boolean = false;
  statusAll: string;
  attributeName = "";
  filterNeigh: any;
  reset = "";
  previousProcess: any;
  reportStatus: any = [];
  filteredAttributes: any = [];
  filterAttribute: string;
  filteredNeighs: any = [];

  search_year: string;
  yearList: any = [];
  selectedYear: string = new Date().getFullYear().toString();
  selectedlayer: any;
  sub_categories: any = [];

  fileToUpload: File = null;
  fileName: string = '';
  file_check: boolean = false;
  loading: boolean = false;
  is_admin: boolean = false;

  returnVal(element, cols) {
    switch (cols) {
      case "process_name":
        return element.file_name;
      case "Added Date":
        return this.getdateForm(element.added_date);
      case "Accept Time":
          return element.accept_time?element.accept_time: "--";
        case "Validate time":
          return  element.validate_time?element.validate_time: "--";
      case "Existing Count":
        return element.existing_count;
      case "upload_id":
        return element.upload_id;
      case "New Count":
        return element.new_count;
      case "Target Count":
        return element.target_count;
      case "uid":
        return element.uid;
      case "Updated Date":
        return this.getdateForm(element.updated_date);
      case "Status":
        return element.status;
    }

  }
  options: options[] = [
    { label: "Parcel Clusters", value: "parcel_cluster" },
    { label: "Direction", value: "parcel_direction" },
    { label: "Setback", value: "parcel_setback" },
    // { label: "Highway/Street facing", value: "highway_facing" },
    { label: "Amenity Distance", value: "parcel_amenity_distance" },
    { label: "Sikka Visibility", value: "parcel_sikka_visibility" },
    { label: "Parcel Visibility", value: "parcel_visibility" },
    { label: "Street Facing Rays", value: "highway_street_facing" },
  ];

  calculateSyncForm: FormGroup;
  loader: boolean = false;
  dataSource: any = [];
  refresh: any = [
    {
      name: "Calculate only new records",
      value: "false",
    },
    {
      name: "Delete Existing records and calculate ",
      value: "true",
    },
  ];
  selectedneightUid: any;
  distance: number;
  isRefresh: boolean = true;
  selected: string = 'parcel_cluster';
  formValue: any;
  attrName: string;
  subCat: any = [];
  buildingUid: string;
  radius: number;
  limit: number = 10;
  offset: number = 0;
  zoning: string = "";
  ZoningList = [];
  propertySubcatList = [];

  select_zoning = [];
  select_subcat = [];

  search_value: string = "";
  search_subcat: string = "";

  @ViewChild('myFileInput') myFileInput;

  constructor(
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private notify: NotificationService,
    private active: GisDataService
  ) {
    this.menus.setActive("upload_calculation");
    this.active.setActive("");
    this.getAllowResourcesApi();
    this.selected = "parcel_cluster";
    this.getReports(this.selected)
    this.calculateSyncForm = this.fb.group({
      neigh: [""],
      block: [""],
      distance: [""],
      refresh: [""],
      attrName: [""],
      parcel: [""],
      clusterGids: [""],
      subCat: [""],
      radius: [""],
      buildingUid: [""],
      degree: [""],
      zoning: [""],
      propertysubcat: [""],
    });
    this.selectedForm(this.selected);
    this.getdropdowndata();
  }

  /**
   * this is a function to get dropdown data
   */
  getdropdowndata() {
    let url = "get-dropdown-data?year=" + this.selectedYear;
    this.ZoningList = [];
    this.propertySubcatList = [];
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.propertySubcatList = res.propertySubCat;
          this.ZoningList = res.zoning;
        }
      },
      error: (err) => {
        console.log(err.error.message);
      },
    });
  }
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.calculate_access = this.fnc.checkResourceAccess(
        "upload_calculation",
        false
      );
      this.calculate_highway_access = this.fnc.checkResourceAccess(
        "highway_facing_rays",
        false
      );
      this.calculate_amenity_distance = this.fnc.checkResourceAccess(
        "distance_amenity_parcel",
        false
      );
    });
  }

  /**
   * function for Status filter on sync/calculate table
   * @param param
   * @param type
   */
  filterByStatus(param, type) {
    if (type == "status") {
      if (param == "All") {
        this.statusAll = "-2,-1,0,1,2";
        this.getReports(this.process_name, "filter");
      } else {
        this.statusAll = param.toString();
        this.getReports(this.process_name, "filter");
      }
    }
    if (type == "neigh") {
      if (param == "All") {
        this.filterNeigh = "";
        this.getReports(this.process_name, "filter");
      } else {
        this.filterNeigh = param.toString();
        this.getReports(this.process_name, "filter");
      }
    }
    if (type == "attribute") {
      if (param == "All") {
        this.filterAttribute = "";
        this.getReports(this.process_name, "filter");
      } else {
        this.filterAttribute = param.toString();
        this.getReports(this.process_name, "filter");
      }
    }
  }

  getStatus(val) {
    return this.fnc.getArrayValue("status_code", val, this.status).value;
  }
  getLabel(val: string) {
    if (val) {
      let v = val.split("**");
      return v.toString();
    }
    return "";
  }

  // SELECTED SECTION
  //to get value of what checkbox is selected
  selectedForm(event) {
    this.resetfile();
    this.searchNeigh = undefined;
    this.selectedBlock = undefined;
    this.selectedneightUid = null;
    this.distance = null;
    this.isRefresh = true;
    this.formValue = event;
    if (this.formValue) {
      this.calculateSyncForm.patchValue({
        neigh: "",
        refresh: "",
        attrName: "",
        block: "",
        parcel: "",
        clusterGids: "",
        radius: "",
        zoning: "",
        degree: "",
        distance: "",
        propertysubcat: "",
      });
      setTimeout(() => {
        this.calculateSyncForm.controls.refresh.patchValue("true");
        if (this.formValue == "sikka_parcel") {
          this.calculateSyncForm.controls.radius.patchValue(11);
          this.calculateSyncForm.controls.zoning.patchValue("PRI,INV");
        } else if (this.formValue == "highway rays") {
          this.calculateSyncForm.controls.radius.patchValue(100);
          this.calculateSyncForm.controls.degree.patchValue(180);
        } else if (this.formValue == "highway_facing") {
          this.calculateSyncForm.controls.distance.patchValue(150);
          this.getdropdowndata();
        }
      }, 100);
    }
  }

  selectedNeigh(neigh) {
    this.selectedneightUid = neigh;
    this.degree = "180";
    if (this.formValue == "highway_facing") {
    } else {

    }
  }

  selectedRefresh(value) {
    //concole.log(value);
    this.isRefresh = value;
  }


  sync(val) {
    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to Sync ", "warn", 5000);
      return;
    }
    if (
      this.attributeName == null ||
      this.attributeName == undefined ||
      this.attributeName == ""
    ) {
      this.notify.notify("Enter Attribute to Sync ", "warn", 5000);
      return;
    }

    let attrName = val.value.attrName;
    if (attrName) {
      this.loader = true;
      let url = `attributes-sync?attribute_name=${attrName}&year=${this.selectedYear}`;
      //concole.log(url);
      this.api.getGmsData(url).subscribe(
        {
          next: (res: any) => {
            this.loader = false;
            if (res) {
              this.notify.notify(
                "Request has been Submitted to server",
                "success",
                5000
              );
              if (this.selectedlayer)
                this.getReports(this.selectedlayer, "filter");
            } else {
              // this.notify.notify("Internal server error", 'warn', 5000);
            }
          },
          error: (err) => {
            this.loader = false;
            // this.notify.notify(err.message, "error");
          }
        }
      );
    }
  }

  // confirmDialogYes(confirm, action) {
  //   if (confirm == "YES") {
  //     this.calculate();
  //   }
  // }

  //create year dropdown
  getYears() {
     const currentYear = new Date().getFullYear()
    const startYear  = 2009;
    for (var i = currentYear; i >= startYear; i--) {
      this.yearList.push({ val: (i).toString() });
    }
  }
  setGlobleYear() {
    this.selectedBlock = null;
    this.selectedneightUid = null;
    this.calculateSyncForm.patchValue({ neigh: "" });
    if (this.selectedlayer) {
      this.getReports(this.selectedlayer, "button");
    }
    if (this.formValue == "highway_facing") {
      this.getdropdowndata();
    }
  }

  checkErrorLen(val: string) {
    if (val && val.length > 260) {
      let v1 = val.substring(0, 260);
      let v2 = val.substring(260, val.length);
      return v1 + " " + v2;
    } else {
      return val;
    }
  }

  getReports(process, from?) {
    // if (!this.calculate_access.GET) {
    //   this.notify.notify("You are not authorized ", "warn", 5000);
    //   return;
    // }
    if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
      this.is_admin = true;
    }
    this.selectedlayer = process;
    this.dataSource = [];
    this.process_name = process;
    let url;
    this.active.setActive(process);
    this.layer = 'calculations';
    url = `${process}/upload-calculation/status?added_by=${this.api.user_id}`;
    if(this.is_admin){
      url = `${process}/upload-calculation/status?`;
    }
    if (this.offset == 0) {
      url += "&is_count=true";
    }
    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }


    this.loader1 = true;
    this.api.getGmusData(url).subscribe(
      {
        next: (res: any) => {
          this.searchNeigh = "";
          this.loader1 = false;
          this.filter = true;
          if (this.offset == 0) {
            this.totalCount = res.totalCount;
          }
          if (res && res?.data?.length) {
            this.dataSource = new MatTableDataSource(res?.data);
            // this.dataSource = res.data;
            this.dataSource.sort = this.sort;
            // this.reportsTotalCount();
          } else {
            this.notify.notify("No Results Found!", "warn", 5000);
          }
        },
        error: (err) => {
          this.loader1 = false;
          this.notify.notify(err.error.message, "error", 3000);
        }
      }
    );
  }

  download_process_log(layer: any, process_name: any, id: any) {
    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to Sync ", "warn", 5000);
      return;
    }
    let url = `sync-calculate-process?id=${id}&layer=${layer}&process_name=${this.layer}&action=get_log`;
    this.api.getGesData(url).subscribe(
      {
        next: (res: any) => {
          if (res && res?.data?.length) {
            let keys: any[], text = "";
            keys = Object.keys(res.data[0].process_log)
            keys.map(d => {
              text += res.data[0].process_log[d] + "\n"
            })
            var a = document.createElement('a');
            var file = new Blob([text], { type: 'text/plain' });
            a.href = URL.createObjectURL(file);
            a.download = 'Process_log_' + id + '.txt';
            a.click();

          } else {
            this.notify.notify("No Results Found!", "warn", 5000);
          }
        },
        error: (err) => {
          this.loader1 = false;
          this.notify.notify(err.error.message, "error", 3000);
        }
      }
    );
  }

  stopProcess(id) {
    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to Sync ", "warn", 5000);
      return;
    }
    if (this.process_name.includes("facing")) {
      this.process_name = "highway_street_facing";
    } else if (this.process_name.includes("cluster")) {
      this.process_name = "parcel_cluster";
    } else if (this.process_name.includes("amenity")) {
      this.process_name = "parcel_amenity_distance";
    } else if (this.process_name.includes("attribute")) {
      this.process_name = "gis_properties_attribute_sync";
    } else if (this.process_name.includes("direction")) {
      this.process_name = "parcel_direction";
    } else if (this.process_name.includes("setback")) {
      this.process_name = "parcel_setback";
    } else if (this.process_name.includes("access")) {
      this.process_name = "gis_highway_facing_rays_for_sync";
    } else if (this.process_name.includes("rays")) {
      this.process_name = "gis_calculate_highway_access";
    } else if (this.process_name.includes("visibility")) {
      this.process_name = "parcel_visibility";
    } else if (this.process_name.includes("sikka")) {
      this.process_name = "parcel_sikka_visibility";
    } else {
      return;
    }
    let upload_id = id.upload_id;
    this.loader1 = true;
    let url = 'stop-sync-process-calculation?process_name='+ this.process_name + '&user_id=' + this.api.user_id + '&upload_id='+ upload_id;
    this.api.getGmusData(url).subscribe(
        {
          next: (res: any) => {
            this.notify.notify("Request Submitted", "success", 4000);
            this.loader1 = false;
            this.getReports(this.selectedlayer, "button");
          },
          error: (err) => {
            this.loader1 = false;
            this.getReports(this.selectedlayer, "button");
            this.notify.notify(err.error.message, "error", 4000);
          }
        }
      );
  }

  getActive_(e) {
    return this.active.getIsActive(e.value);
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date, true);
    } else {
      return "";
    }
  }
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = e.pageSize * e.pageIndex;
      console.log(e.pageSize, e.pageIndex, this.offset);

    }
    this.getReports(this.process_name, "page");
  }

  resetfile(){
    if(this.myFileInput){
      this.myFileInput.nativeElement.value = "";
      this.fileName='';
      this.fileToUpload=null;
    }

  }
  ngOnInit() {
    setTimeout(() => {
      this.getYears();
    }, 500);
  }

  handleFileInput(event) {
    // this.openConfirmation();
    let fileList: FileList = event.target.files;
    this.file_check = true;
    // let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.file_check = false;
        this.notify.notify('Unsupported file type', 'warn');
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  fileUpload() {
    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to Sync ", "warn", 5000);
      return;
    }
    if (!this.fileToUpload) {
      this.notify.notify('No File Uploaded', 'warn');
      return;
    }
    let url = this.selected + '/upload-calculation?added_by=' + this.api.user_id;
    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    this.loading = true;

    this.api.postFile(url, formData).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.file_check = false;
        this.notify.notify("File successfully uploaded!", "success");
        this.resetfile();
        this.getReports(this.selected);
        console.log("calling", this.selected);
      },
      error: (err) => {
        this.file_check = false;
        this.resetfile();
        this.loading = false;
        this.getReports(this.selected);
        this.notify.notify(this.fnc.getErrorMessage(err.error), "error");
      },
    });
  }

  isAccepted(v) {
    if (v == 'Valid') {
      return true;
    }
  }
  isRejected(v) {
    if (v == 'Invalid' || v == 'Valid' || v == 'Uploaded') {
      return true;
    }
  }
  showStopbutton(status) {
    if (status == 'Accept Progress') {
      return true;
    }
  }

  action(data, flag) {
    if (flag == 'accept') {
      this.loader = true;
      let url = this.selectedlayer + '/upload-calculation/' + data.upload_id + '/accept?accepted_by=' + this.api.user_id;
      this.api.postUserAction(url, null).subscribe({
        next: (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify('File Accepted', 'success');
          } else {
            this.notify.notify('File Not Accepted', 'error');
          }
          this.getReports(this.selectedlayer);
        },
        error: (err) => {
          this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
          this.loader = false;
          this.getReports(this.selectedlayer);
        }
      })
    }
    else if (flag == 'reject') {
      this.loader = true;
      let url = this.selectedlayer + '/upload-calculation/' + data.upload_id + '/reject?rejected_by=' + this.api.user_id;
      this.api.postUserAction(url, null).subscribe({
        next: (res: any) => {
          this.loader = false;
          if (res) {
            this.notify.notify('File Rejected', 'success');
          } else {
            this.notify.notify('File Not Rejected', 'error');
          }
          this.getReports(this.selectedlayer);

        }, error: (err) => {
          this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
          this.loader = false;
          this.getReports(this.selectedlayer);
        }
      })
    }
  }

  isacceptView(v) {
    if (v == 'Accepted' || v == 'Accept Invalid' || v == 'Accept Progress') {
      return true;
    }
    return false;
  }

}
