
<a (click)="openProfile()" mat-menu-item>
  <mat-icon>settings</mat-icon>
  Profile
</a>
<div class="divider"></div>
<a (click)="logout(false)" mat-menu-item> 
  <mat-icon>power_settings_new</mat-icon>
  Log out
</a>
<div class="divider"></div>
<a (click)="logout(true)" mat-menu-item> 
  <mat-icon>power_settings_new</mat-icon>
  Log out from all devices
</a>