<div class="mtc-header position-relation" fxLayout="row" fxLayoutAlign="center center">
  <div class="pt-3 d-flex">
    <div>
      <h3 *ngIf="mode=='process'">Process Log</h3>
      <h3 *ngIf="mode=='parameter'">Parameter Log</h3>
    </div>
    <div class="copy" (cbOnSuccess)="copied($event)" ngxClipboard [cbContent]="copiedLog">
      <ng-container *ngIf="!isCopied">
        <button class="copy-btn" mat-button matTooltip="Copy Log" (click)="copyText()">
          <span class="material-icons">
            content_copy
          </span>
        </button>
      </ng-container>
      <ng-container *ngIf="isCopied">
        <span style="color: #fff;font-size: 15px;
        position: relative;
        right: 35px;">
          Log Copied!
        </span>
      </ng-container>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div>
    <div class="each" *ngFor="let item of logList">
      {{item}}
    </div>
  </div>
</mat-dialog-content>