import { BrowserModule, } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiserviceService } from './apiservice.service';
//import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppComponent } from './app.component';
import { CommonfunctionService } from './services/commonfunction.service';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing/app-routing.module';

//inactivity handling==================================================
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './state/reducers';
import { ApplicationEffects } from './state/application/effects';
import { MatSortModule } from "@angular/material/sort";
//inactivity handling==================================================

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([ApplicationEffects]),
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    AdminModule,
    MatSortModule
  ],
  providers: [ApiserviceService, CommonfunctionService],
  bootstrap: [AppComponent]
})
export class AppModule { }
