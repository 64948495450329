import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'neighFilter'
})
export class NeighbourhoodPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            let name = it.neigh_name;
            return name.toLowerCase().includes(searchText);
        });
    }
}