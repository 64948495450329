<div class="login-wrapper">
  <div class="login-middle" [ngClass]="{'forgotpassword': showForgot}">
    <div class="logo-contain">
      <img src="/assets/images/logo.svg" class="center" width="250" />
    </div>
    <div class="login-container" *ngIf="!showForgot">
      <mat-card appearance="outlined" class="cen">
        <mat-card-content>
          <mat-tab-group #tabGroup [@.disabled]="true" dynamicHeight="true" disableRipple class="demo-tab-group">
            <mat-tab label="Login" class="top-label global">
              <form [formGroup]="loginForm" (ngSubmit)="doLogin(loginForm.value)">
                <div class="inside-content">
                  <mat-form-field class="full-width">
                    <mat-icon matPrefix class="mat-icons">email</mat-icon>
                    <input matInput type="email" placeholder="Enter your email address" formControlName="email" autocomplete="address-level1">
                    <mat-error>Email is required</mat-error>
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-icon matPrefix class="mat-icons">lock_outline</mat-icon>
                    <input type="password" matInput placeholder="Enter your password" formControlName="password"
                      [type]="hide ? 'password' : 'text'" autocomplete="new-password">
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                  </mat-form-field>

                  <!-- <div class="catp-d">
                    <div class="cap-img">
                      <re-captcha required (resolved)="resolved($event)" siteKey="6LfKCVIUAAAAADyREfHHktMNkvpOCv-E7JD9ZhFF"></re-captcha>
                    </div>
                  </div> -->
                  <div *ngIf="error" class="login-err">{{error}}</div>
                  <div class="button-row">
                    <button [disabled]="loginForm.invalid" mat-button color="primary" type="submit">
                      <span class="arrow-icon" *ngIf="!login_action">Login</span>
                      <span class="arrow-icon" *ngIf="login_action">Logging in...</span>
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" x="0" y="0" viewBox="0 0 20 20" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m12 2-1.4 1.4 5.6 5.6h-16.2v2h16.2l-5.6 5.6 1.4 1.4 8-8z" fill="#ffffff" data-original="#000000" class=""></path></g></svg>

                    </button>
                  </div>
                </div>
              </form>
              <a class="bottom" (click)="jumpForgot()" style="color: white;">
                <div class="c-v">
                  <div class="tx">
                    Forgot Password?
                  </div>
                </div>
              </a>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="forgot-container" *ngIf="showForgot">
      <div class="inside-content">
        <div class="tab_header">Forgot Password</div>
        <p class="text">Enter your email. We'll email instructions on how to reset your password</p>
        <mat-form-field class="full-width">
          <mat-icon matPrefix class="mat-icons">email</mat-icon>
          <input [(ngModel)]="forget_email" matInput placeholder="Enter your email address" value="" type="email">
        </mat-form-field>
        <span *ngIf="f_error" class="forget-err">{{forget_message}}</span>
        <span *ngIf="!f_error" class="forget-success">{{forget_message}}</span>
        <div class="button-row">
          <button mat-button color="primary" (click)="jumpForgot()">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" x="0" y="0" viewBox="0 0 20 20" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,20,20)"><path xmlns="http://www.w3.org/2000/svg" d="m12 2-1.4 1.4 5.6 5.6h-16.2v2h16.2l-5.6 5.6 1.4 1.4 8-8z" fill="#ffffff" data-original="#000000" class=""></path></g></svg>
            <span class="arrow-icon"> Go back</span>
          </button>
          <button [disabled]="!forget_email ||!enable_btn" (click)="forgetPassword()" mat-button color="primary">
            <span class="arrow-icon">Submit</span>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" x="0" y="0" viewBox="0 0 20 20" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m12 2-1.4 1.4 5.6 5.6h-16.2v2h16.2l-5.6 5.6 1.4 1.4 8-8z" fill="#ffffff" data-original="#000000" class=""></path></g></svg>
          </button>
        </div>
      </div>
    </div>

  </div>
</div>
