import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
// import { HttpClientModule } from '@angular/common/http';
import { AdminRoutingModule } from './admin-routing/admin-routing.module';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectCityComponent } from '../admin/city-select/city-select-component';
import { NotificationService } from './services/notification.service';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from "@angular/material/sort";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AdminMenusService } from './admin-sidebar/admin-menus.service';
import { OverlayModule } from '@angular/cdk/overlay';
// import { QuillModule } from 'ngx-quill'
//import {} from '@types/googlemaps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DndModule } from 'ngx-drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminSidebarComponent } from './admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { AdminBreadcrumbComponent, AdminSearchbarComponent, AdminActionbuttonsComponent } from './admin-breadcrumb/admin-breadcrumb.component';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UsermenuComponent } from './admin-header/usermenu/usermenu.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AdminBreadcrumbService } from './admin-breadcrumb/admin-breadcrumb.service';
import { AuthGuard } from './services/auth.guard';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { ShareService } from './services/share.service';
import { NotificationComponent, NotifyDetailComponent } from './notification/notification.component';

import { GisDataUploadComponent, ConflictDialog } from '../admin/gis-data-upload/gis-data-upload.component';
import { GisDataService } from '../admin/gis-data-upload/gis-data-upload.service';

import { MatChipsModule } from '@angular/material/chips';
import { LegacyDataUploadComponent } from './legacy-data-upload/legacy-data-upload.component';
// import { AngularCesiumModule } from 'angular-cesium';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PoiComponent } from './poi/poi.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { MediaDialogComponent } from './image-upload/media-dialog/media-dialog.component';

import { MatTreeModule } from '@angular/material/tree';
import { PoiDataComponent } from './poi/poi-data/poi-data.component';
import { JsonToCsv } from './services/jsonToCsv';
import { JsonToCsvMarks } from '../admin/marks-upload/jsonToCsv';
import { ConfirmDialogBox } from './confirm-dialog/confirm-dialogbox';
import { GisAttributesComponent } from './gis-attributes/gis-attributes.component';
import { AddAttributesComponent } from './gis-attributes/add-attributes/add-attributes.component';
import { CalculateSyncComponent } from './calculate-sync/calculate-sync.component';
import { ViewReportComponent } from './calculate-sync/view-report/view-report.component';
import { DbProcessesComponent } from './db-processes/db-processes.component';
import { ClipboardModule } from 'ngx-clipboard';
import { GisAttributePipe } from './Filter/gis-attribute.pipe';
import { NeighbourhoodPipe } from './Filter/neighbourhood.pipe';
import { MarksUploadComponent } from './marks-upload/marks-upload.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DescriptionComponent } from './image-upload/description/description.component';
import { GisSyncComponent } from './gis-sync/gis-sync.component';
import { SearchFilterPipe } from './pipe/Search-filter-pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { LogSettingComponent } from './log-setting/log-setting.component';
import { UserProfileComponent } from './admin-header/user-profile/user-profile.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-2';
import { SettingsComponent } from './settings/settings.component';
import { AddEditComponent } from './settings/add-edit/add-edit.component';
import { CategoryDialogComponent } from './settings/category-dialog/category-dialog.component';
import { SicResultsComponent } from './sic-results/sic-results.component';
import { SicResultsDetailsComponent } from './sic-results/sic-results-details/sic-results-details.component';
import { ProgressBarComponent } from './gis-sync/progress-bar/progress-bar.component';
import { SocketService } from './socket.service';
import { CalculateUploadComponent } from './calculate-upload/calculate-upload.component';
import { InterceptorService } from './interceptor.service';
import { SparkCalculateSyncComponent } from './spark-calculate-sync/spark-calculate-sync.component';
import { DataTableComponent } from './spark-calculate-sync/components/data-table/data-table.component';
import { UploadMomraZoneCodeComponent } from './upload-momra-zone-code/upload-momra-zone-code.component';
import { GisDataTableComponent } from './shared/components/gis-table/gis-data-table/gis-data-table.component';
import { RemoveUnderscorePipe } from './upload-momra-zone-code/pipes/removeUnderscore.pipe';
import { GisFileUploadComponent } from './shared/components/gis-file-upload/gis-file-upload.component';
import { CommonfunctionService } from '../services/commonfunction.service';
import { ApiserviceService } from '../apiservice.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  swipeEasing: false
};

@NgModule({
  imports: [
    CdkTableModule,
    CommonModule,
    HttpClientModule,
    HttpClientModule,
    ClipboardModule,
    FormsModule, ReactiveFormsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    RecaptchaModule,
    RecaptchaV3Module,
    // AngularCesiumModule.forRoot(),
    RecaptchaFormsModule,
    AdminRoutingModule,
    MatInputModule,
    MatTableModule,
    FormsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    PerfectScrollbarModule,
    MatTreeModule,
    OverlayModule,
    // QuillModule.forRoot(),
    MatButtonToggleModule,
    MatSortModule,
    MatIconModule, MatCheckboxModule, MatButtonModule,
    MatCardModule, MatGridListModule, MatSidenavModule, MatToolbarModule,
    MatTabsModule, MatListModule, MatMenuModule, MatTooltipModule, MatDialogModule,
    FlexLayoutModule, MatSelectModule, MatRadioModule, MatSnackBarModule,
    BrowserAnimationsModule, NgxIntlTelInputModule,
    DndModule, MatPaginatorModule, MatChipsModule, MatProgressBarModule, MatProgressSpinnerModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      backdropBorderRadius: '4px',
      primaryColour: '#f69230',
      secondaryColour: '#16ceed',
      tertiaryColour: '#23cb6b',
      fullScreenBackdrop: false
    }),
    AngularEditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ImageCropperModule
  ],
  declarations: [
    AdminComponent,
    AdminLoginComponent,
    AdminSidebarComponent,
    AdminHeaderComponent,
    AdminBreadcrumbComponent,
    AdminSearchbarComponent,
    AdminActionbuttonsComponent,
    UsermenuComponent,
    SelectCityComponent,
    NotificationComponent,
    NotifyDetailComponent,
    GisDataUploadComponent,
    ConflictDialog,
    ConfirmDialogBox,
    DashboardComponent,
    LegacyDataUploadComponent,
    PoiComponent,
    ImageUploadComponent,
    MediaDialogComponent,
    PoiDataComponent,
    GisAttributesComponent,
    AddAttributesComponent,
    CalculateSyncComponent,
    ViewReportComponent,
    DbProcessesComponent,
    GisAttributePipe,
    NeighbourhoodPipe,
    MarksUploadComponent,
    DescriptionComponent,
    GisSyncComponent,
    SearchFilterPipe,
    LogSettingComponent,
    UserProfileComponent,
    ImageEditorComponent,
    SettingsComponent,
    AddEditComponent,
    CategoryDialogComponent,
    SicResultsComponent,
    SicResultsDetailsComponent,
    ProgressBarComponent,
    CalculateUploadComponent,
    SparkCalculateSyncComponent,
    DataTableComponent,
    UploadMomraZoneCodeComponent,
    GisDataTableComponent,
    RemoveUnderscorePipe,
    GisFileUploadComponent
  ],
  entryComponents: [MediaDialogComponent, ConflictDialog, ConfirmDialogBox, AddAttributesComponent, ViewReportComponent, DescriptionComponent, UserProfileComponent, ImageEditorComponent,
    AddEditComponent,
    CategoryDialogComponent
  ],
  providers: [
    CommonfunctionService,
    ApiserviceService,
    NotificationService,
    AdminMenusService,
    AdminBreadcrumbService,
    AuthGuard,
    JsonToCsv,
    JsonToCsvMarks,
    ShareService,
    GisDataService,
    SocketService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky' },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }
  ],
  exports: [AdminLoginComponent, SearchFilterPipe, ProgressBarComponent]
})
export class AdminModule { }
