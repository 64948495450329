<div class="parent-container" [ngStyle]="{'height':height+'px'}">
    <mat-card appearance="outlined" class="example-card">
    <mat-card-header>
      <div class="w-100 mlr-2" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title">Image and Video File Data Report</span>
      <input class="search-input" [formControl]="searchField" type="text" name="search" placeholder="Search...">
      </div>
    </mat-card-header>
    <mat-card-content class="tabs">
        <ng-container>
          <div class="w-100 mlr-2" fxLayout="row" fxLayoutAlign="start center">
            <span><a *ngFor="let option of options" class="active" (click)="getUploadedData(option.value, null, true, true)">
                {{option.label}}</a></span>
            <span *ngIf="selected_neighbourhoods">
              <mat-icon class="ml-1">trending_flat</mat-icon>
            </span>
            <span *ngIf="selected_neighbourhoods">{{selected_neighbourhoods}}</span>
          </div>
        </ng-container>


      <div class="table-container">
        <mat-table [dataSource]="dataSource" class="w-100">
          <mat-progress-bar class="w-100 abs" mode="indeterminate"></mat-progress-bar>

          <!-- Id Column -->
          <ng-container matColumnDef="sn">
            <mat-header-cell *matHeaderCellDef>SN </mat-header-cell>
            <mat-cell *matCellDef="let element;">
              <span>{{element.sn}}</span>
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="uid">
            <mat-header-cell *matHeaderCellDef> UID </mat-header-cell>
            <mat-cell *matCellDef="let element"><span>{{element.uid}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell width="10%" mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"><span>{{element.name}}</span></mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell class="text-center" *matCellDef="let element">
              <button (click)="addDescription(element)" class="border" style="    border-radius: 3px;  background: #fff;">
                <img src="assets/images/svgicons/eye.svg" alt="" width="20px">
              </button>
              <!-- <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
                <span (click)="edit(element)" *ngIf="selectedCellId != element.id"  matTooltipPosition="right" matTooltip="{{getDiscript(element)}}"
                  class="descr inline-fix-desc">{{getDiscript(element)}}</span>
                <input *ngIf="selectedCellId == element.id" class="editable" 
                  [(ngModel)]="tempCellData.description" >
                <div class="mlr-2" fxLayout="row" fxLayoutAlign="center start">
                  <mat-button-toggle class="marg-2" *ngIf="selectedCellId == element.id" value="left"
                    (click)="updateReportData(element)" title="Save">
                    <mat-icon *ngIf="!updateLoader" class="size-sm accent">save</mat-icon>
                    <mat-spinner *ngIf="updateLoader"></mat-spinner>
                  </mat-button-toggle>
                  <mat-button-toggle class="marg-2" *ngIf="selectedCellId == element.id" value="center"
                    (click)="cancelEdit(element.id)" title="Cancel">
                    <mat-icon class="size-sm"
                      [ngClass]="{'default': selectedCellId == element.id, 'inactive': selectedCellId != element.id}">
                      close</mat-icon>
                  </mat-button-toggle>
                </div>
              </div> -->
            </mat-cell>
          </ng-container>

          <!-- Image Column -->
          <ng-container matColumnDef="image">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-raised-button color="primary" (click)="openMediaDialog('image', element, element.id)">Image</button>
            </mat-cell>
          </ng-container>

           <!-- video Column -->
           <ng-container matColumnDef="video">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Video </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-raised-button color="primary" (click)="openMediaDialog('video', element, element.id)">Video</button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="view">
            <mat-header-cell mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a (click)="getBlocks(element.uid,null, true)" *ngIf="!selected_neighbourhoods">View Block</a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
        </mat-table>
      </div>
      <div class="w-100" fxLayout="row">
        <mat-paginator class="w-100" [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
        </mat-paginator>
      </div>
      <div *ngIf="loader1" class='loader-div'>
        <ngx-loading [show]="loader1"></ngx-loading>
      </div>
    </mat-card-content>
  </mat-card>

</div>
