import { Component, OnInit, HostListener, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { neighbourhoods, options } from '../gis-data-upload/gis-data-upload.component';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { GisDataService } from '../gis-data-upload/gis-data-upload.service';
import { Router } from '@angular/router';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { MediaDialogComponent } from './media-dialog/media-dialog.component';
import { DescriptionComponent } from './description/description.component';
import { debounceTime } from 'rxjs/operators';
// import { MediumEditor } from 'medium-editor';

export interface option {
  label: string;
  value: string;
  show: boolean;
}
/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_neighbourhoods_json_get</li>
 * <li>gisapi_blocks_json_get</li>
 * <li>gisapi_count_layer_get</li>
 * <li>gisapi_description_patch</li>
 * </ol>
 *
 */
// declare const MediumEditor: any;
@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements AfterViewInit{

  displayedColumns = ['sn', 'uid', 'name', 'description', 'image', 'video','view'];
  dataSource: any = [];
  selectedCellId: any;

  // tempCellData: any = {
  //   description: {}
  // }
//pagination
  total: any;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  limit: number = 10;
  offset: number = 0;
  pageNo: number = 1;
  dataLength: any = 0;
  sn: number = 1;
  count: number = 0;
  selectedYear: string = new Date().getFullYear().toString();

  //medium editor
  textVar:any;
  opt = {
          'toolbar': {
              allowMultiParagraphSelection: true,
              buttons: ['bold', 'italic', 'anchor', 'underline', 'h1', 'h2', 'h3', 'h4']
            }
        }

  placeholder = "description";

// search
 searchField: FormControl;
 search_input: any;

 neigh_uids:any = '';
 search: any = '';
 block_uids: any = '';

  height: number;
  inHeight: number;
  updateLoader: boolean = false;
  selectedTypeId: any;
  selectedlayer:any;
  options: option[] =
    [
      { label: 'Neighbourhoods', value: 'neighbourhoods', 'show': true},
      // { label: 'Blocks', value: 'blocks',  'show': false }
    ];

  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  is_admin = false;
  loader1: Boolean = false;
  blocks : any = [];
  neighbourhoods : any = [];

  selected_neighbourhoods: any = '';
  // editor: any;
  // @ViewChild('editable', { static: true }) editable: ElementRef;
  // @ViewChild('media') media: ElementRef;

  constructor(private api: ApiserviceService, private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private fb: FormBuilder,
    private notify: NotificationService,
    private active: GisDataService,
    private route: Router,
    public dialog: MatDialog) {
    this.menus.setActive('imageVideo');
    this.active.setActive('');
    this.onResize();
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
    this.getUploadedData('neighbourhoods',null, true, true);
  }
  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }
  // ngAfterViewInit() {
    // let editor = new MediumEditor(".editable").subscribe("editableClick", (e:any) => {
    //   let s= e.target;
    //     console.log(s)
    
    // });
  //   const edit = this.media.nativeElement;
  //   const editor = new MediumEditor(edit);
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    //  this.height = (window.innerHeight - 220);
    //  this.inHeight = (window.innerHeight - 288);
  }

  public getUploadedData(val, searchterm=null, flag: boolean = false, reset : boolean = false) {
    this.selectedlayer = val
    this.active.setActive(this.selectedlayer);
    this.dataSource = [];
    if (flag) {
      this.offset = 0;
      this.pageNo = 1;
    }
    if(reset){
      this.offset = 0;
      this.pageNo = 1;
      this.dataLength = 0;
      this.total = 0;
      this.defaultPageSize = 10;
      this.count = 0;
      this.pageIndex = 0;
    }
    if(searchterm == null && flag){
      this.getcount(this.selectedlayer)
    }
    if(this.selectedlayer == 'blocks'){
      this.getBlocks(this.selected_neighbourhoods,searchterm, false);
    }else if(this.selectedlayer == 'neighbourhoods'){
      this.getNeighbourhoods(searchterm)
    }
  }

  public getNeighbourhoods(searchterm = null) {

    this.selected_neighbourhoods = '';
    this.loader1 = true;
    let url = 'neighbourhoods/json?limit='+this.limit+'&offset='+this.offset;
    if(searchterm != null){
      this.neigh_uids = searchterm;
      this.search = searchterm;
      url =  url+'&search='+this.search;
    }
    this.api.getGpsData(url)
      .subscribe((data: any) => {
        this.loader1 = false;
        if (data.length) {
          this.count = this.offset;
          this.neighbourhoods = [];
          let lan = this.api.language;
          let temp = {}
          temp[lan] = '';
          data.forEach(element => {
            this.sn = ++this.count;
            let d = {
              "sn": this.sn,
              "id" :  element.neigh_id,
              "uid" : element.neigh_uid,
              "name":element.neigh_name,
              "description": element.description == null ? temp : element.description,
              "image":element.image,
              "video":element.video
            }
            this.neighbourhoods.push(d);
          });
          this.dataSource = [];
          this.dataSource = this.neighbourhoods;
        }
      }, err => {
        this.loader1 = false;
        this.notify.notify('No neighbourhoods found', 'error');
      });
  }

  public getBlocks(uid, searchterm=null, flag: boolean = false) {

    if (flag) {
      this.offset = 0;
      this.pageNo = 1;
      this.dataLength = 0;
      this.total = 0;
      this.defaultPageSize = 10;
      this.count = 0;
      this.pageIndex = 0;
    }

    this.selected_neighbourhoods = uid;
    this.selectedlayer = 'blocks';
    if(searchterm == null && flag){
      this.getcount(this.selectedlayer)
    }
    let url = this.selectedlayer+'/json?neigh_uid='+uid+'&limit='+this.limit+'&offset='+this.offset;  if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    this.loader1 = true;
    if(searchterm != null){
      this.block_uids = searchterm;
      this.search = searchterm;
      url =  url+'&search='+this.search;
    }
    this.dataSource = [];
    this.api.getGpsData(url).subscribe((res: any) => {
      this.loader1 = false;
      if (res.length) {
        this.count = this.offset;
        this.blocks = [];
        let lan = this.api.language;
        let temp = {}
        temp[lan] = '';
        res.forEach(element => {
          this.sn = ++this.count;
          let data = {
            "sn": this.sn,
            "id": element.block_id,
            "uid": element.block_uid,
            "name": element.block_name,
            "description": element.description == null ?  temp : element.description,
            "image":element.image,
            "video":element.video
          }
          this.blocks.push(data);
        });

        this.dataSource = this.blocks;
      }
    }, err => {
      this.blocks = [];
      this.loader1 = false;
      this.notify.notify('No Blocks found', 'error');
    })
  }

  public getcount(selectedlayer){
    this.dataLength = 0;
    this.total = 0
    let url = 'count/'+selectedlayer;
    if(this.selectedlayer == 'blocks'){
      url += '?neigh_uid='+this.selected_neighbourhoods;
    }
    this.api.getGesData(url).subscribe( res => {
      this.dataLength = res;
      this.total = res;
    },
    err=>{

    })

  }

  public getActive_(e) {
    return this.active.getIsActive(e.value);
  }

  //table functions
  public cancelEdit(id) {
    if (id == this.selectedCellId) {
      this.selectedCellId = '';
    }
  }
  // public edit(element) {
  //   this.selectedCellId = element.id;
  //   this.tempCellData.description = (element.description && element.description[this.api.language]) ? element.description[this.api.language] : element.name;
  // }
  
  addDescription(element){
    let dialogRef = this.dialog.open(DescriptionComponent, {
      width: '600px',
      panelClass: 'create-master-panel',
      data: {element : element , selectedlayer: this.selectedlayer}
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == true){
        this.getUploadedData(this.selectedlayer);
      }else{
      }
    });
  }

  // public updateReportData(value){

  //     let body = [], description = {};
  //     description[this.api.language] =  this.fnc.escapeMetaCharacters(this.tempCellData.description.trim());
  //     let record = {
  //       "added_by": this.api.user_id,
  //       "description": description,
  //       "entity_id": value.id,
  //       "entity_name": this.selectedlayer,
  //       "status": 1,
  //     }
  //     body.push(record);
  //     let url = 'description';
  //     this.loader1 = true;
  //     this.api.patchData(url, body).subscribe(
  //       (res: any) => {
  //         this.loader1 = false;
  //         if (res == "Description updated") {
  //           this.notify.notify("Description successfully updated", "success", 5000);
  //           this.selectedCellId = "";
  //           this.getUploadedData(this.selectedlayer, null, false);
  //         }else{
  //           this.notify.notify("Description not updated", "error", 5000);
  //         }
  //       },
  //       (err) => {
  //         this.loader1 = false;
  //         if (err.error.text == "Description updated") {
  //           this.notify.notify("Description successfully updated", "success", 5000);
  //           this.selectedCellId = "";
  //           this.getUploadedData(this.selectedlayer, null, false);
  //         } else {
  //           this.notify.notify("Description not updated", "error", 5000);
  //         }
  //       }
  //     );
  // }

  public getDiscript(item){
    if (item) {
      let descrip =  item.description[this.api.language].replace(/<\/?[^>]+(>|$)/g, "");
      let label = (item.description && item.description[this.api.language]) ? descrip : item.name;
      return label
    }
  }
  public openMediaDialog(action, data, id) {
    let cellData = { }
    if(action == 'image'){
      cellData = {
        data: data.image,
        id: id,
      }
    }else if(action == 'video'){
      cellData = {
        data: data.video,
        id: id,
      }
    }

    console.log('Open');
    
    let dialogRef = this.dialog.open(MediaDialogComponent, {
      width: '600px',
      panelClass: 'create-master-panel',
      data: {
        parent: this,
        cellData: cellData,
        mode: action
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

 
  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(800))
      .subscribe(term => {
        if(this.selectedlayer == null){
          this.notify.notify('please select layer', 'warn');
        }else{
          term = term.trim();
          this.search_input = term;
          if (term) {
            this.getUploadedData(this.selectedlayer, term ,true)
          } else {
            this.getUploadedData(this.selectedlayer, null ,true)
          }
        }
      })
  }

  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getUploadedData(this.selectedlayer,null, false);
  }

}
