<div class="table-wrapper">
  <!-- <ngx-loading  [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading> -->

  <div class="custom-card">
    <table
      mat-table
      [dataSource]="dataSource"
    >
      <!-- S. No Column -->
      <ng-container matColumnDef="S. No">
        <th mat-header-cell *matHeaderCellDef>S. No</th>
        <td mat-cell *matCellDef="let element; let i = index"> {{i + 1 + offset}} </td>
      </ng-container>

      <!-- Upload id Column -->
      <ng-container matColumnDef="Upload id">
        <th mat-header-cell *matHeaderCellDef>Calculate id</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef >Name</th>
        <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.uid}} </td>
      </ng-container>

      <!-- Added date Column -->
      <ng-container matColumnDef="added_date">
        <th mat-header-cell *matHeaderCellDef style="min-width: 170px;">Added date</th>
        <td mat-cell *matCellDef="let element"> {{getFormatedDate(element.added_date) ? getFormatedDate(element.added_date): '--'}}</td>
      </ng-container>
      <!-- Updated date Column -->
      <ng-container matColumnDef="Updated_date">
        <th mat-header-cell *matHeaderCellDef>Updated date</th>
        <td mat-cell *matCellDef="let element"> <span *ngIf="element.updated_date">{{getFormatedDate(element.updated_date)}}</span> <span *ngIf="!element.updated_date">--</span></td>
      </ng-container>
        <!-- Count Column -->
        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef> Count </th>
          <td mat-cell *matCellDef="let element"> {{element.existing_count ? element.existing_count : '--'}} </td>
        </ng-container>
          <!-- New count Column -->
          <ng-container matColumnDef="new_count">
            <th mat-header-cell *matHeaderCellDef> New count </th>
            <td mat-cell *matCellDef="let element"> {{element.new_count ? element.new_count : '--'}} </td>
          </ng-container>
            <!-- Target Column -->
        <!-- <ng-container matColumnDef="Target_count">
          <th mat-header-cell *matHeaderCellDef> Target count </th>
          <td mat-cell *matCellDef="let element"> 10 </td>
        </ng-container> -->
          <!-- Log Column -->
          <ng-container matColumnDef="Log_files">
            <th mat-header-cell *matHeaderCellDef> Logs </th>
            <td mat-cell *matCellDef="let element"> <button type="button" class="log-btn" (click)="downloadLog(element.logfile)">Log</button> </td>
          </ng-container>
            <!-- Error Column -->
        <!-- <ng-container matColumnDef="Error_files">
          <th mat-header-cell *matHeaderCellDef> Error logs </th>
          <td mat-cell *matCellDef="let element"> <button type="button" class="log-btn">Error Logs</button>  </td>
        </ng-container> -->
          <!-- Status Column -->
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
            <button class="running status-btn" *ngIf="element.status == 1"> Running </button>
            <button class="success status-btn" *ngIf="element.status == 2"> Success </button>
            <button class="success status-btn" *ngIf="element.status == 4"> Synced </button>
            <button class="failed status-btn" *ngIf="element.status == -1"> Failed </button>
            <button class="failed status-btn" *ngIf="element.status == -2"> Stopped </button>
            <button class="running status-btn" *ngIf="element.status == 0"> Started </button>
            </td>
          </ng-container>
            <!-- Action Column -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
          <button class="stop-process action-btn" [title]="element.status == -2 ? 'Stopped':'Stop Process'" [disabled]="element.status == -2" *ngIf="element.status == 1 || element.status == -2"  (click)="openConfirmation(element)">
            <img src="assets/icon/stop-process.svg" alt="" >
          </button>
          <button type="button" class="sync action-btn" [disabled]="element.status == 4" [title]="element.status == 4 ? 'Synced':'Sync Process'" *ngIf="element.status == 2 || element.status == 4" (click)="syncProcess(element)">
            <img src="assets/icon/sync-process.svg" alt="">
          </button>
          <span *ngIf="element.status != 1 && element.status != 2 && element.status != 4 && element.status != -2"> -- </span>
          </td>
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
   <div class="pagination-wrapper">
    <mat-paginator [length]="totalCount" showFirstLastButtons  [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
    </mat-paginator>
   </div>

    <!-- <div class="">
      No Records Available
    </div> -->
  </div>
</div>

<ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
