import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css']
})
export class ViewReportComponent implements OnInit {
  public parent_data: any;
  logList;
  mode;
  isCopied: Boolean = false;
  copiedLog: any = [];
  constructor(public dialogRef: MatDialogRef<ViewReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clipboard: ClipboardService) {
    this.mode = this.data.type;
    this.parent_data = this.data.data;
    if (this.mode == 'process') {
      let processLog = this.parent_data.process_log;
      let keys = Object.keys(processLog);
      let list = [];
      if (keys && keys.length) {
        keys.map(element => {
          list.push(processLog[element]);
        })
        this.logList = list;
      }
    }
    if (this.mode == 'parameter') {
      let parameterLog = this.parent_data.parameter;
      let keys = Object.keys(parameterLog);
      let list = [];
      if (keys && keys.length) {
        keys.map(element => {
          list.push(parameterLog[element]);
        })
        this.logList = list;
      }
    }

  }

  copyText() {
    this.logList.map(item => {
      this.copiedLog.push(item + '\n');
    })
    this.clipboard.copyFromContent(this.copiedLog)
  }

  copied(event) {
    this.isCopied = event.isSuccess
  }
  ngOnInit() {
  }

}
