<div class="notify-outer">
  <mat-toolbar class="notifybar">
    <h2>Notifications</h2>
  </mat-toolbar>
  <div class="outerbox">
    <div class="notify-inner">
      <ng-container *ngIf="notification.length>0">
        <div class="notifybox" (click)="markAsRead(data)" *ngFor="let data of notification" [ngStyle]="{'background-color':data.email_status === 'sent' ? 'rgb(208, 235, 219)' : '#f3f4f9', 'cursor':data.email_status === 'read' ? 'normal' : 'pointer' }">
          <div class="upper">
            <div>
              <p class="fnt13 clamp" [ngStyle]="{'color':data.email_status === 'sent' ? '#27325f' : '#b3b3b3' }">{{data.from_user_name}}
                ({{data.from_user_id}})
              </p>
            </div>
            <div>
              <span class="time fnt11">{{getDate(data.added_date)}} | {{getTime(data.added_date)}}</span>
            </div>
          </div>
          <div>
            <p class="msg fnt13" [ngStyle]="{'color':data.email_status === 'sent' ? '#27325f' : '#b3b3b3' }">{{data.message}}</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="notification.length==0">
        <div class="center">
          <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
          <span *ngIf="!loader" class="fnt15">
            No new notifications...
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="center">
    <button class="grey-button mat-button ng-star-inserted" routerLink="/admin/notifications" (click)="overlay_off()">
      <span class="mat-button-wrapper">View all</span>
    </button>
  </div>
</div>