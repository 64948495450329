import { Component, OnInit } from '@angular/core';
import { ShareService } from '../services/share.service'
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  notification: any = [];
  limit: number = 20;
  offset: number = 0;
  loader: boolean = false;
  constructor(private share: ShareService, private api: ApiserviceService) {
    this.share.notify_obj = this;
    this.share.adminComp.getNotifications(this.offset, this.limit, false);
  }
  overlay_off() {
    this.share.adminComp.overlay_off();
  }
  markAsRead(n) {
    this.share.adminComp.markAsRead(n);
  }
  getDate(v){
    let date = new Date(v);
    let month = date.getMonth() + 1;
    let format = date.getDate()+'-'+month+'-'+date.getFullYear();
    return format;
  }
  getTime(v){
    let date = new Date(v);
    let format = date.getHours()+':'+date.getMinutes();
    return format;
  }
  ngOnInit() {
  }

}

@Component({
  selector: 'app-notify-details',
  templateUrl: './notify-detail.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotifyDetailComponent implements OnInit {
  notification: any = [];
  limit: number = 20;
  offset: number = 0;
  height: number;
  loader: boolean = false;
  scroll: boolean = false;
    constructor(private share: ShareService, private api: ApiserviceService, private notify: NotificationService) {
      this.onResize();
      this.share.notify_detail_obj = this;
      this.share.adminComp.getNotifications(this.offset, this.limit, false);
    }
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight-121);
  }
  getDate(v){
    let date = new Date(v);
    let month = date.getMonth() + 1;
    let format = date.getDate()+'-'+month+'-'+date.getFullYear();
    return format;
  }
  getTime(v){
    let date = new Date(v);
    let format = date.getHours()+':'+date.getMinutes();
    return format;
  }
  markAsRead(n) {
    this.share.adminComp.markAsRead(n);
  }
  onScroll(e) {
    if (e.isReachingBottom) {
      this.scroll = true;
      this.offset += this.limit;
      this.share.adminComp.getNotifications(this.offset, this.limit, true);
    }
  }
  ngOnInit() {
  }

}
