import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, FormGroup, FormControl, FormBuilder, FormArray, NgModel } from '@angular/forms';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { AddEditComponent } from './add-edit/add-edit.component';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  displayedColumns = ['sn', 'key', 'value', 'type', 'status', 'added_by', 'added_date', 'action'];
  public indexListCols=[
    "name",
    "options",
  ];
  dataSource: any = [];
  indexList: any = [];
  loader: boolean;
  selectedCellId: any;
  changed_value: string;
  status: string = "0,1";
  searchField: FormControl;
  search: any = '';

  setting_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };


  total: number;
  pageSizeOptions: any=[5, 10, 15, 25];
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  limit: number=10;
  offset: number=0;

  constructor(
    private menus: AdminMenusService,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fnc: CommonfunctionService,
    public dialog: MatDialog,
  ) {
    this.menus.setActive('settings');
    this.getAllowResources();
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.apiService.getUserRole();
    let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
    this.apiService.getUmsData(body)
      .subscribe((res: any) => {
        this.apiService.allowResources = res.data;
        this.setting_access = this.fnc.checkResourceAccess('settings', false);
        this.getAllowResource();
        this.getSettings();
      }, err => {
      })

  }


  getAllowResource() {
    this.setting_access = this.fnc.checkResourceAccess('settings', false);
  }

  editValue(ele, type) {
    this.selectedCellId = ele.id + '_' + type;
    this.changed_value = ele.value;
  }

  cancelEdit(uid) {
    this.selectedCellId = '';
  }
  edit_setting(data) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to EDIT setting", "warn");
      return;
    }
    var body = {
      "id": data.id,
      "value": this.changed_value,
      "status": data.status,
      "updated_by": this.apiService.user_id
    }
    this.loader = true;

    this.apiService.patchGmsData('settings', body).subscribe((res: any) => {
      if (res && res.status == 201) {
        this.selectedCellId = '';
        this.notify.notify("Setting has been updated successfully","success")
        this.getSettings();
      }
      this.loader = false;
    }, err => {
      this.notify.notify("settings not updated", "error")
      this.loader = false;
    })
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDate(date);
    } else {
      return '';
    }
  }

  openDailog(type: any, data: any = null) {
    if (!this.setting_access.POST) {
      this.notify.notify("You are not authorized to Add setting", "warn");
      return;
    }
    if (type == 'add') {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddEditComponent, {
        width: '350px',
        panelClass:'customPadding',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result == true){
            this.getSettings();
        }
      });
    }
  }


  openConfirmation(value) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to Delete setting", "warn");
      return;
    }
    let _data = {
      parent_data: this,
      message: 'Do you want to delete setting?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'YES'){
        this.confirmDialogYes(value);
      }
    });
  }

  confirmDialogYes(value) {
      let body = {
        "id": value.id,
        "status": -1,
        "updated_by": this.apiService.user_id
      }
      this.loader=true;
      this.apiService.patchGmsData('settings', body).subscribe((res: any) => {
        this.loader=false;
        if(res && res.status == 201)
        {
          this.notify.notify("Setting has been deleted successfully", "success");
          this.getSettings();
        }
      }, err => {
        this.loader=false;
        this.notify.notify("Setting has been deleted failed", "error")
      })

  }

  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        this.reset();
        term = term.trim().toLowerCase();
        if (term) {
          this.getSettings(term)
        } else {
          this.getSettings(null)
        }
      })
  }

  /**
   * This is a function that used to getting setting from api
   * @param searchterm searchterm is a param
   * @returns
   */
  getSettings(searchterm = null) {
    if (!this.setting_access.GET) {
      this.notify.notify("You are not authorized to Access setting", "warn");
      return;
    }
    this.loader = true;
    //this.dataSource = [];
    let url = `settings?status=${this.status}&limit=${this.limit}&offset=${this.offset}`;
    if (searchterm != null) {
      url += `&search_text=${searchterm}`;
    }
    if (this.offset == 0) {
      url += `&is_count=true`;
    }
    this.apiService.getGmsData(url).subscribe((res: any) => {
      this.indexList = [];
      this.loader = false;
      if (this.offset == 0) {
        this.total = res.totalRecord;
      }
      if (res && res.data) {
        res.data.map(e => {
          e['added_date'] = this.fnc.formatDateUTC(new Date(e.added_date));
        })
        this.dataSource = res.data;
        if (res.server) {
          this.indexList = res.server;
        };
        // this.total = res.datalength
      } else {
        this.dataSource = [];
        this.indexList = [];
      }
    }, err => {
      this.dataSource = [];
      this.indexList = [];
      this.loader = false;
    })
  }

  /**
   * This is a function to use update status
   * @param row_id
   * @param event
   * @returns
   */
  updateStatus(row_id, event) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to update setting", "warn");
      return;
    }
    let body: any = {
      "id": row_id,
      "status": (event.checked == true) ? 1 : 0,
      "updated_by": this.apiService.user_id
    };
    this.loader = true;
    this.apiService.patchGmsData('settings', body).subscribe((res: any) => {
      this.loader = false;
      if(res && res.status == 201){
        this.notify.notify(`Setting has been updated successfully`, "success");
        this.getSettings();
      }
    }, err => {
      this.loader = false;
    })
  }


  /**
   * API call to get Attributes with status=-1 and Data length for pagination
   */


  getDeleted(event) {
    if (!this.setting_access.GET) {
      this.notify.notify("You are not authorized to Access Settings", "warn");
      return;
    }

    if (event.checked == false) {
      this.status = "0,1";
    }
    else {
      this.status = "-1";
    }
    this.reset();
    this.getSettings();
  }

  reset(){
    this.offset=0;
    this.pageIndex=0;
  }

  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getSettings();
  }

}
