import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { initialSharedModel, SharedModel } from '../../../sharedmodel/shared.model';


@Component({
  selector: 'app-gis-data-table',
  templateUrl: './gis-data-table.component.html',
  styleUrls: ['./gis-data-table.component.scss'],
  providers: [DatePipe]  // Add DatePipe as a provider
})
export class GisDataTableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: string[] = [];
  @Input() buttons: any = {}; // Input for buttons configuration
  @Input()  getStatusClass: (status: any) => string;
  @Input() totalCount: number;
  @Output() pageChange = new EventEmitter<any>();
  @Input() reportLoader: boolean = false; 
  sharedModel: SharedModel = { ...initialSharedModel };
  pageSizeOptions: number[] = [10, 25, 50, 100];
  defaultPageSize: number = 10;
  pageIndex: number = 0;
  constructor(private datePipe: DatePipe) {}
  ngOnInit(): void {}
  // Function to handle disabled state
  isButtonDisabled(element: any, buttonLabel: string): boolean {
    const status = element?.status;
  
    if (buttonLabel === 'Accept Log') {
      // Enable 'Accept Log' button only for Accepted status
      return status !== 2;
    }
  
    if (buttonLabel === 'Stop Process') {
      // Show 'Stop Process' button only for Validating status
      return status !== 3;
    }
  
    // Check based on status for other buttons
    if (status === 1) {
      // Status is valid, buttons should not be disabled
      return false;
    } else if (status === 2 || status === -2 || status === -1 || status === 0 || status === 3 || status === -3) {
      // Status is accepted, accept-invalid, invalid, or validating -> disable all buttons
      return true;
    }
  
    return false;
  }
  

  /**
   * Checks if a value is in a valid date format using regex.
   */
  isDate(value: any): boolean {
    // Regex to match date formats like "YYYY-MM-DD HH:MM:SS" or similar
    const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)?$/;
    return typeof value === 'string' && dateRegex.test(value);
  }

  /**
   * Formats the value for display.
   * If the value is a date, it formats it to 'd MMM yyyy HH:mm:ss'.
   * Limits to 25 characters and adds ellipsis if needed.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatDisplayValue(value: any): string {
    
    if (value === null || value === undefined || value === '') {
      return '--';
    }
    if (this.isDate(value)) {
      // Format the date using Angular's DatePipe
      return this.datePipe.transform(value, 'd MMM yyyy HH:mm:ss') || '--';
    }
    return value.toString().length > 20 ? value.toString().slice(0, 20) + '...' : value.toString();
  }
  

  /**
   * Formats the value for the tooltip.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatValue(value: any): string {
    return value === null || value === undefined || value === '' ? '--' : value.toString();
  }

  /**
   * Checks if a tooltip is needed based on the length of the value.
   */
  isTooltipNeeded(value: any): boolean {
    return value && value.toString().length > 25;
  }
  pageAction(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    if (this.sharedModel.limit !== e.pageSize) {
      this.sharedModel.limit = e.pageSize;
      this.sharedModel.offset = 0;
    } else {
      this.sharedModel.offset = this.pageIndex * e.pageSize;
    }
    const pagedetails = {
      limit: this.sharedModel.limit,
      offset: this.sharedModel.offset
    };
    this.pageChange.emit(pagedetails);
  }
}
