 
<div fxLayout="row" fxShow.gt-sm="true" fxHide="true" fxLayoutAlign="center center"> 
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <ng-container *ngFor="let crumbs of getCrumbs();let j = index"> 
        <div class="master-title" *ngIf="j===0">{{crumbs.title}}</div> 
        <div  *ngIf="j>0" class="subk">
            <p *ngIf="!crumbs.url && !crumbs.function">{{crumbs.title}}</p>
            <a *ngIf="crumbs.url" href="{{crumbs.url}}">{{crumbs.title}}</a>
            <a class="clickable" *ngIf="crumbs.function && !crumbs.url" (click)="callFunction(crumbs.function)">{{crumbs.title}}</a>
        </div>
        <mat-icon *ngIf="(getCrumbs().length -1) != j" class="ar">arrow_forward</mat-icon> 
      </ng-container>  
    </div>
</div>  

<div fxLayout="row" class="page-content w-100"  fxLayoutAlign="space-between center" fxShow="true" fxShow.gt-sm="false"> 
    <div class="ellipsisoverflow" *ngIf="getMobileLastCrumb()">{{getMobileLastCrumb().title}}</div>
    <div class="badge bg-white" *ngIf="getCount()">{{getCount()}}</div>
</div>