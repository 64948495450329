import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { ApiserviceService } from '../../../apiservice.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-attributes',
  templateUrl: './add-attributes.component.html',
  styleUrls: ['./add-attributes.component.css']
})
export class AddAttributesComponent implements OnInit {
  layers: any = [{
    value: "building"
  },
  {
    value: "parcel"
  },
  {
    value: "street"
  },
  {
    value: "project"
  },
  ];

  data_types: any = [
    {
      value: "decimal-input"
    },
    {
      value: "boolean"
    },
    {
      value: "string-input"
    },
    {
      value: "integer"
    },
    {
      value: "json"
    },

  ];

  public parent_data;
  public mode;
  public AttributeForm: any;
  public attributeList: any = [];
  loader: boolean;

  constructor(public dialogRef: MatDialogRef<AddAttributesComponent>,
    @Inject(MAT_DIALOG_DATA) public info: any,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fb_: FormBuilder) {
    this.parent_data = this.info;
    this.mode = this.parent_data.type;
    if (this.mode == 'add') {
      this.AttributeForm = this.fb_.group({
        name: ['', Validators.required],
        layer: ['', Validators.required],
        dataType: ['', Validators.required],
      });
    }
    if (this.mode == 'edit') {
      this.AttributeForm = this.fb_.group({
        name: ['', Validators.required],
        layer: ['', Validators.required],
        dataType: ['', Validators.required],
      });

      setTimeout(() => {
        this.AttributeForm.patchValue({
          "addedBy": this.parent_data.edit.added_by,
          "attributeId": this.parent_data.edit.attribute_id,
          "name": this.parent_data.edit.attribute_name,
          "dataType": this.parent_data.edit.data_type,
          "isLangDependent": this.parent_data.edit.is_lang_dependent,
          "layer": this.parent_data.edit.layer,
          "status": this.parent_data.edit.status,
        });
      });
    }
  }
  addAttribute(val) {
    let data = val.value;
    let re = /\ /gi;
    let attribute_name = data.name.trim().replace(re, '_');

    let url = `attributes`;
    let body = {
      "addedBy": this.apiService.user_id,
      "attributeName": attribute_name,
      "dataType": data.dataType,
      "isLangDependent": false,
      "layer": data.layer,
      "status": 1
    }
    this.loader = true;
    this.apiService.postGmsData(url, body).subscribe((res: any) => {
      this.loader = false;
      if (res && res.status == 201) {
        this.notify.notify(res.message, 'success', 3000);
        this.parent_data.pdata.getAttributes();
        // this.parent_data.pdata.getAttributeCount();
        this.cancel();
      }
      else {
        this.notify.notify(res.message, 'warn', 5000)
      }

    }, err => {
      this.loader = false;
      this.notify.notify("Failure", 'error');
    })
  }

  updateAttibutes(val) {
    let updateData = val.value;
    let re = /\ /gi;
    let attribute_name = updateData.name.trim().replace(re, '_');
    let url = `attributes`
    let body = {
      "attributeId": this.parent_data.edit.attribute_id,
      "attributeName": attribute_name,
      "dataType": updateData.dataType,
      "layer": updateData.layer,
      "addedBy": this.apiService.user_id
    }
    this.loader = true;
    this.apiService.patchGmsData(url, body).subscribe((res: any) => {
      this.loader = false;
      if (res.status == 201) {
        this.notify.notify(res.message, 'success');
        this.parent_data.pdata.getAttributes();
        // this.parent_data.pdata.getAttributeCount();
        this.dialogRef.close();
      }else{
        this.notify.notify(res.message, 'warn');
      }     
      
    }, err => {
      this.loader = false;
      this.notify.notify('Attribute Updation Error', 'error');
    })
  }

  cancel() {
    this.dialogRef.close();
  }


  ngOnInit() {
  }

}
