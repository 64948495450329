import { Component, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sic-results',
  templateUrl: './sic-results.component.html',
  styleUrls: ['./sic-results.component.scss']
})
export class SicResultsComponent implements OnInit {
  selectedLangCode;
  entity: string = '';
  entity_list: any[] = [];
  searchfilter: FormControl;
  loader: boolean = false;

  dataSource: any = [];
  // displayedColumns = ['SN', 'ID', 'Name', 'Added by', 'Date', 'Action'];
  displayedColumns = ['SN', 'ID', 'Name', 'Date', 'Action'];
  search_item: string;

  limit: number = 10;
  offset: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  total: number = 0;
  pageIndex: any = 0;
  SIC_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false
  };

  sortAsc: string = 'true';
  orderBy: string = 'name'

  constructor(
    private menu: AdminMenusService,
    private alert: NotificationService,
    private fnc: CommonfunctionService,
    public api: ApiserviceService,
    private router: Router
  ) {
    this.menu.setActive('sic');
    this.searchfilter = new FormControl();
    this.getAllowResources()
  }


  /**
   * This is a function that is used to get list of all resources allocated
   * to the logged in user and checks resource access for this component &
   * access APIs and to perform actions.
   * @api GET user/resource
   * @return {} Returns void.
   */
  getAllowResources() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({
        next: (res: any) => {
          this.api.allowResources = res.data;
          this.SIC_access = this.fnc.checkResourceAccess('sic', false);
          this.getOptions();
        },
        error: (err) => {
        }
      })
  }

  ngOnInit(): void {
    this.searchfilter.valueChanges.pipe(debounceTime(500)).subscribe(term => {
      term = term.toLowerCase();
      this.reset();
      if (term && term.length) {
        this.search_item = term;
        this.getSICQuerys();
      }
      else if (term.length == 0) {
        this.search_item = '';
        this.getSICQuerys();
      }
    })
  }

  /**
* This is a function to change pagination page and get the list of sic.

* @param e - A Page Event to get page details
* @return {} Returns void.
*/

  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = e.pageSize * e.pageIndex;
    }
    this.getSICQuerys();
  }

  /**
   * This is a reset pagination function
   */
  reset() {
    this.offset = 0;
    this.pageIndex = 0;

  }

  returnVal(element, cols) {
    cols = cols.toLowerCase().split(' ').join('_');
    return element[cols]
  }

  /**
   * this is a on entity change fuction
   */
  entityChange(event?) {
    if (event) {
      this.orderBy = event?.active.toLowerCase();
      if (event?.direction == 'asc') { this.sortAsc = 'true'; }
      else if (event?.direction == 'desc') { this.sortAsc = 'false'; }
    }
    this.reset();
    this.getSICQuerys();
  }

  /**
   * this is a function to see the details of selected row
   * @param element
   */
  selectRowdata(element) {
    this.router.navigateByUrl(`sic-results/${this.entity}/${element.id}`)
  }

  getdate(val) {
    if (val) {
      return this.fnc.formatDateUTC(new Date(val), true);
    }
    return '';
  }

  getOptions() {
    this.loader = true;
    this.api.getGmsData(`sic-option?order_by=name&asc=false`).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.data) {
          res.data.map(e => {
            e['name'] = e.name ? this.fnc.camelizeAll(e.name) : '';
          })
          this.entity_list = res.data;
          this.entity = this.entity_list[0].id;
          this.getSICQuerys();
        } else {
          this.entity_list = [];
        }
      },
      error: () => {
        this.loader = false;
      }
    })
  }

  /**
* This is a function to GET list of SIC results from API.
* Resources are checked.
* List of SIC results are stored in {@link dataSource} variable.
* Limit and offset used for pagination.
* sort order and sort by used for sorting conditionally.
* @api GET /SIC results
* @return {} Returns void.
*/

  getSICQuerys() {
    if (!this.SIC_access.GET) {
      this.alert.notify('You are not authorize to get SIC queries', "warn");
      return;
    }
    if (!this.entity) {
      this.alert.notify('Please select option', "warn");
      return;
    }
    let url = `sic-queries?limit=${this.limit}&offset=${this.offset}&option_id=${this.entity}`;
    if (this.search_item) url += `&name=${this.search_item}`;
    if (this.offset == 0) url += `&is_count=true`;
    if (this.sortAsc) url += `&asc=${this.sortAsc}`
    if (this.orderBy) url += `&order_by=${this.orderBy}`
    this.loader = true;
    this.api.getGmsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.dataSource = [];
        if (this.offset == 0) {
          this.total = res?.totalRecord
        }
        if (res && res.data) {
          this.dataSource = res.data;
        }
      },
      error: () => {
        this.loader = false;
        this.dataSource = [];
      }
    })
  }


}
