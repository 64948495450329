<div class="parent-container" [ngStyle]="{'height':height+'px'}">
  <mat-card appearance="outlined" class="example-card" *ngIf="upload_access.POST">
    <mat-card-header>
      <mat-card-title class="master-title title">Poi Data Upload</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ngx-loading [show]="loader1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
      <div class="gis_data_form">
        <form [formGroup]="fileUploadForm">
          <!-- <div class="bottom-border">
            <mat-radio-group formControlName="layer" (change)="initValidators($event)">
              <mat-radio-button class="radio_text"  [checked]="check(option)" *ngFor="let option of options" [value]="option.value">{{option.label}}</mat-radio-button>
            </mat-radio-group>
          </div> -->
          <div>
            <div class="gridV">
              <div>
                <span class="fntcustom" *ngIf="fileName">{{fileName}}</span>
              </div>
              <div class="btnGrid">
                <div class="upload-btn-wrapper">
                  <button mat-button class="browesbtn" ><mat-icon svgIcon="cloud_upload" class="upicon"></mat-icon> Browse
                  <input type="file" name="myfile"  #myFileInput (change)="handleFileInput($event)" formControlName="file" accept=".zip" />
                </button>
                </div>
                <button type="submit" mat-raised-button color="primary" [disabled]="!fileUploaded" (click)="uploadFile(fileUploadForm)">
                  <span *ngIf="!loading">Upload</span>
                  <span *ngIf="loading">Uploading...</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-card-content>
    <div class="bbar w-100" *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </mat-card>

  <mat-card appearance="outlined" class="example-card">
    <mat-card-header>
      <mat-card-title class="master-title title">Poi Data Report</mat-card-title>
    </mat-card-header>
    <mat-card-content class="tabs">
      <div class="bottom-border" style="margin-bottom: 10px;">
        <button class="margr5" *ngFor="let option of options" [ngClass]="{'active': getActive_(option)}" (click)="getUploadedFilesList(option.value, true)">
          {{option.label}}</button>
      </div>
    <ng-container *ngIf="selectedlayer == 'poi'">
      <div class="table-estater">
          <table class="attr-table table_data">
            <thead>
              <tr>
                <th> S. No. </th>
                <th> Upload Id </th>
                <th> Name </th>
                <th> Added Date </th>
                <th> Update Count</th>
                <th> User Name </th>
                <th> Status </th>
                <th> View Log </th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let poiData of poiDataReport;">
                <td width="5%"> {{poiData.sn}}</td>
                <td> {{poiData.upload_id}}</td>
                <td> {{poiData.name}} </td>
                <td> {{poiData.added_date}} </td>
                <td>{{poiData.update_count}}</td>
                <td>{{poiData.user_name}}</td>
                <td>{{poiData.status}}</td>
                <td>
                  <a target="_blank" href="{{poiData.errfile_link}}" class="bordered" mat-button>
                    Validate Log
                  </a>
                  <a [disabled]="!acceptedlog(poiData.status)" target="_blank" href="{{poiData.accept_errfile_link}}" class="bordered ml-1" mat-button>
                    Accept Log
                  </a>
                </td>
                <td>
                  <button [disabled]="!isAccepted(poiData.status)" class="bordered prim" mat-button (click)="action(poiData, 'accept')">Accept</button>
                  <button [disabled]="!isRejected(poiData.status)" class="bordered" mat-button color="warn" (click)="action(poiData, 'reject')">Reject</button></td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="loader" class='loader-div'>
            <ngx-loading [show]="loader"></ngx-loading>
          </div>
      </div>
      <mat-paginator [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
      </mat-paginator>
    </ng-container>
    <ng-container *ngIf="selectedlayer == 'data'">
      <app-poi-data></app-poi-data>
    </ng-container>
     

    </mat-card-content>
  </mat-card>

</div>
