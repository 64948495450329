import { Component, OnInit } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import * as $ from 'jquery';
import { ApiserviceService } from '../../apiservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loader: boolean = true;
  constructor(private menu: AdminMenusService, private api:ApiserviceService, private router: Router) {
    if(!this.api.user_id && !this.api.city_id){
      this.router.navigateByUrl('/admin/login');
    }
    this.menu.setActive('dashboard');
  }
  success(){
    this.loader = false;
  }
  ngOnInit() {

  }

  ngAfterViewInit(){
    $(document).ready(function () {
      $('#frame').on('load', function () {

      });
  });
  }
}
