import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { neighbourhoods, options } from '../gis-data-upload/gis-data-upload.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { Router } from '@angular/router';
import { GisDataService } from '../gis-data-upload/gis-data-upload.service';
import { NotificationService } from '../services/notification.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_blocks_json_get</li>
 * <li>gisapi_places_bulk_search_post</li>
 * <li>umsapi_user_resource_post</li>
 * <li>gisapi_neighbourhoods_json_get</li>
 * <li>gisapi_layer_uploads_post</li>
 * <li>gisapi_layer_uploads_status_get</li>
 * <li>gisapi_layer_uploads_upload_id_accept_post</li>
 * <li>gisapi_layer_uploads_upload_id_reject_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.css']
})
export class PoiComponent implements OnInit {

  options: options[] =
    [
      { label: 'Uploads', value: 'poi' },
      { label: 'Poi Data', value: 'data' }
    ];
  status: any[] = [
    { status_code: -1, value: 'Invalid' },
    { status_code: 1, value: 'Valid' },
    { status_code: 2, value: 'Accepted' },
    { status_code: -2, value: 'Rejected' },
    { status_code: 0, value: 'Uploaded' },
    { status_code: -3, value: 'accept_invalid' },
    { status_code: 4, value: 'Accept Progress' }
  ];

  fileToUpload: File = null;
  fileUploaded: boolean = false;
  fileUploadForm: FormGroup;
  layer: FormControl;
  selectedlayer: any = 'poi';
  loading: boolean = false;
  loader: boolean = false;
  height: any;
  flag: boolean = false;
  limit: number = 10;
  offset: number = 0;
  // record: number;
  disableNext: boolean = false;
  disablePriv: boolean = true;
  pageNo: number = 1;
  sn: number = 1;
  count: number = 0;
  fileName: string = '';
  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  is_admin = false;

  dataLength: number;

  pages: any = [];
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  selectedLayer: any;
  neighbourhoods: neighbourhoods[] = [];
  poiDataReport: any = [];
  blocks: any = [];
  buildings: any = [];
  loader1: boolean = false;

  @ViewChild('myFileInput') myFileInput;

  @ViewChild(MatPaginator) paginator: MatPaginator

  constructor(private api: ApiserviceService, private fnc: CommonfunctionService, private menus: AdminMenusService, private _crumbs: AdminBreadcrumbService, private fb: FormBuilder,
    private notify: NotificationService, private active: GisDataService, private route: Router) {
    this.menus.setActive('poi');
    this.active.setActive('');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Upload Data' };
    this._crumbs.mobiletitle = 'Upload Data';
    this._crumbs.count = 1;
    this.fileUploadForm = new FormGroup({
      layer: new FormControl('', [Validators.required]),
      file: new FormControl(null, [Validators.required])
    });
    this.onResize();
    this.getAllowResourcesApi();
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 80);
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event) {
    if (this.loading) {
      if (confirm("File upload is still under progress! If you leave, your changes will be lost.")) {
        return true;
      } else {
        return false;
      }
    }
  }



  initValues() {
    this.fileUploadForm.valueChanges.subscribe((data) => {
      if (data.layer) this.layer = data.layer;
    })
  }
  ngOnInit() {
    this.initValues();
  }
  check(v) {
    if (this.options.length == 1) {
      this.fileUploadForm.patchValue({ 'layer': this.options[0].value });
      return true;
    } else {
      return false;
    }
  }
  initValidators(e) {
    let data = e.value;
    this.fileName = '';
    this.fileUploadForm = new FormGroup({
      layer: new FormControl('', [Validators.required]),
      file: new FormControl(null, [Validators.required])
    })
    this.fileUploadForm.patchValue({ 'layer': data });
    this.initValues();
  }
  // getting allow resources
  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        // this.getNeighbourhoods();

      })
  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     // .map((data:any) => data.json())
  //     .subscribe(
  //       (data:any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         //this.getNeighbourhoods();
  //       },
  //       err => {
  //       }
  //     )
  // }

  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type', 'warn');
        this.fileUploadForm = new FormGroup({
          file: new FormControl(null, [Validators.required])
        })
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
      if (this.fileName)
        this.fileUploaded = true;
    }
  }

  getAllowResource() {
    this.upload_access = this.fnc.checkResourceAccess('gisDataUpload', false);
  }



  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  uploadFile(val) {

    if (!this.upload_access.POST) {
      this.notify.notify("You are not authorized to upload GIS data", "warn");
      return;
    }
    if (!this.fileUploaded) {
      return
    }
    let ln = this.fileToUpload.size;
    this.loading = true;
    let layer, uid = null, url;
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    layer = val.value.layer;

    url = layer + '/uploads?uid=' + uid + '&added_by=' + this.api.user_id;
    this.api.postFile(url, formData).subscribe({next: (res: any) => {
      this.loading = false;
      this.notify.notify('File successfully uploaded!', 'success');
      this.fileToUpload = null;
      this.fileName = '';
      this.myFileInput.nativeElement.value = '';
      this.fileUploadForm = new FormGroup({
        file: new FormControl(null, [Validators.required]),
      })
      let e = { value: layer }
      this.initValidators(e);
      setTimeout(() => {
        if (this.selectedlayer != null) {
          this.getUploadedFilesList(this.selectedlayer, false);
        }
      }, 1000);
    }, error: (err) => {
      this.fileToUpload = null;
      this.fileName = '';
      this.myFileInput.nativeElement.value = '';
      this.fileUploadForm = new FormGroup({
        file: new FormControl(null, [Validators.required]),
      })
      let e = { value: layer }
      this.initValidators(e);
      this.loading = false;
    }})
  }
  // uploadData(e) {
  //   this.selectedlayer = e;
  //    if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
  //    this.is_admin = true;
  //   }
  //   let url = '';
  //   url = this.selectedlayer + '/uploads/status?added_by=' + this.api.user_id + '&limit=1000';
  //   if (this.is_admin) {
  //     url = this.selectedlayer + '/uploads/status';
  //   }
  //   this.api.getGisData(url).subscribe((res: any) => {
  //     this.dataLength = res.length;
  //     this.total = res.length;
  //   })
  // }

  getDate(date) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date, true);
    } else {
      return '';
    }
  }
  getStatus(val) {
    return this.getArrayValue('status_code', val, this.status).value;
  }
  getActive_(e) {
    return this.active.getIsActive(e.value);
  }
  getUploadedFilesList(val, flag: boolean) {
    this.selectedlayer = val;
    this.active.setActive(this.selectedlayer);
    if (val != 'poi') {
      return false;
    }
    // this.uploadData(val);
    if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
      this.is_admin = true;
    }
    if (!this.upload_access.GET) {
      this.notify.notify("You not authorized to view uploaded file of GIS", "warn");
      this.poiDataReport = [];
      return;
    }
    let url = '';
    // this.record = 0;
    this.loader = true;

    if (flag) {
      this.offset = 0;
      this.pageNo = 1;
    }
    let limit = this.limit;
    let offset = this.offset;
    url = this.selectedlayer + '/uploads/status?added_by=' + this.api.user_id + '&limit=' + limit + '&offset=' + offset;
    if (this.is_admin) {
      url = this.selectedlayer + '/uploads/status?limit=' + limit + '&offset=' + offset;
    }
    if (offset == 0) {
      url += "&is_count=true"
    }
    this.api.getGmusData(url).subscribe((res: any) => {
      if (offset == 0) {
        this.total = res.totalCount;
      }
      // this.record = res.data.length;
      this.count = this.offset;
      this.poiDataReport = [];
      this.loader = false;
      if (res.data?.length) {
        res.data?.forEach(e => {
          this.sn = ++this.count;
          let d = {
            sn: this.sn,
            name: e.name,
            added_date: this.getDate(e.added_date),
            status: this.getStatus(e.status),
            errfile_link: e.errfile_link,
            logfile_link: e.logfile_link,
            accept_errfile_link: e.accept_errfile_link,
            accept_logfile_link: e.accept_logfile_link,
            upload_id: e.upload_id,
            user_name: e.added_by,
            existing_count: e.existing_count,
            add_count: e.add_count,
            delete_count: e.delete_count,
            update_count: e.update_count,
            street_delete_count: e.street_delete_count,
            check: (Number(e.existing_count)) - (Number(e.update_count) + Number(e.delete_count)),
          }
          this.poiDataReport.push(d);

        });
      } else {
        this.poiDataReport = [];
        this.loader = false;
        this.notify.notify('No results found', 'warn');
      }
    }, err => {
      this.loader = false;
      this.poiDataReport = [];
      this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
    })
  }

  isLogView(v) {
    if (v == 'Valid' || v == 'Uploaded' || v == 'Invalid' || v == 'Rejected') {
      return true;
    }

  }
  acceptedlog(v) {
    if (v == 'Accepted' || v == 'accept_invalid' || v == 'Accept Progress') {
      return true;
    }
  }

  isAccepted(v) {
    if (v == 'Valid' || v == 'Accept Progress') {
      return true;
    }
  }
  isRejected(v) {
    if (v == 'Invalid' || v == 'Valid') {
      return true;
    }
  }
  action(data, flag) {
    if (!this.upload_access.PATCH) {
      this.notify.notify("You are not authorized to update GIS information", "warn");
      return;
    }
    this.loader = true;
    if (flag == 'accept') {
      let url = this.selectedlayer + '/uploads/' + data.upload_id + '/accept?accepted_by=' + this.api.user_id;
      this.api.postUserAction(url, null).subscribe((res: any) => {
        this.notify.notify('File Accepted', 'success');
        this.loader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
      }, err => {
        this.loader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
        this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
      })
    } else {
      let url = this.selectedlayer + '/uploads/' + data.upload_id + '/reject?rejected_by=' + this.api.user_id;
      this.api.postUserAction(url, null).subscribe((res: any) => {
        this.notify.notify('File Rejected', 'warn');
        this.loader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
      }, err => {
        this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
        this.getUploadedFilesList(this.selectedlayer, false);
        this.loader = false;
      })
    }
  }

  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getUploadedFilesList(this.selectedlayer, false);
  }


}
