// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  umsUrl: "https://umsapinode.dev10.in/",
  gisUrl: "https://gisapi.dev10.in/gis/api/",
  gmsUrl:"https://gms.dev10.in/",
  gpsUrl:"https://gps.dev10.in/",
  gesUrl:"https://ges.dev10.in/",
  gmusUrl:"https://gmus.dev10.in/",
  user_token: 'TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=',
  
  
  
  baseURL : 'https://g2.dev10.in',
  adminHost: "https://umsnew.dev10.in",
  
  // baseURL : 'http://localhost:5001',
  // adminHost: "http://localhost:4200",
  
  isLocalServe: false
};
