import { Component, OnInit, HostListener, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { GisDataService } from './gis-data-upload.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { Router } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';

export interface neighbourhoods {
  label: string;
  value: string;
}

export interface options {
  label: string;
  value: string;
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_blocks_json_get</li>
 * <li>gisapi_places_bulk_search_post</li>
 * <li>umsapi_user_resource_post</li>
 * <li>gisapi_neighbourhoods_json_get</li>
 * <li>gisapi_layer_uploads_post</li>
 * <li>gisapi_layer_uploads_status_get</li>
 * <li>gisapi_layer_uploads_upload_id_accept_post</li>
 * <li>gisapi_layer_uploads_upload_id_reject_post</li>
 * <li>gisapi_layer_uploads_upload_id_put</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-gis-data-upload',
  templateUrl: './gis-data-upload.component.html',
  styleUrls: ['./gis-data-upload.component.css']
})
export class GisDataUploadComponent implements OnInit {
  options: options[] =
    [
      { label: 'Governorates', value: 'governorates' },
      { label: 'Neighbourhoods', value: 'neighbourhoods' },
      { label: 'Blocks', value: 'blocks' },
      { label: 'Master Plans', value: 'master_plans' },
      { label: 'Projects/Compounds', value: 'projects' },
      { label: 'Parcels', value: 'parcels' },
      { label: 'Streets', value: 'streets' },
      { label: 'Unit Layout', value: 'units' },
      { label: 'Road Layout', value: 'road' },
      { label: 'Parcel setback', value: 'parcel_setback' },
      { label: 'Metro Line', value: 'metro_line' },
      { label: 'Metro station', value: 'metro_station' },
      { label: 'Project Road', value: 'project_road' },
      { label: 'Pcc Parcels', value: 'pcc_parcels' },
      // { label: 'Compound road', value: 'compound_road' },
      // { label: 'Project parcel', value: 'project_parcel' },
      // { label: 'Project street', value: 'project_street' },
      {label: 'Project Details', value:'project_details'}
    ];
  status: any[] = [
    { status_code: -1, value: 'Invalid' },
    { status_code: 1, value: 'Valid' },
    { status_code: 2, value: 'Accepted' },
    { status_code: -2, value: 'Rejected' },
    { status_code: 0, value: 'Uploaded' },
    { status_code: -3, value: 'Accept Invalid' },
    { status_code: 4, value: 'Accept Progress' }
  ];
  statusSubProcess: any[] = [
    { status_code: -1, value: 'Error' },
    { status_code: 1, value: 'Completed' },
    { status_code: 0, value: 'Uploaded' },
    { status_code: 4, value: 'Running...' },

  ];

  road_filter = [
    { id: 1, "name": "calculate_setback", "label": "Calculate Setback", view: false },

    // { id: 5, "name": "duplicate_id", "label": "Suppress duplicate id overlap check", view: false },
  ];
  parcel_filter = [
    { id: 1, "name": "parcel_overlap", "label": "Suppress parcel overlap check", view: false },
    { id: 2, "name": "neigh_overlap", "label": "Suppress Neighbourhood  overlap check", view: false },
    { id: 3, "name": "block_overlap", "label": "Suppress block overlap check", view: false },
    { id: 4, "name": "street_overlap", "label": "Suppress street overlap check", view: false },
    // { id: 5, "name": "duplicate_id", "label": "Suppress duplicate id overlap check", view: false },
  ];
  unit_layout_filter = [
    { id: 1, "name": "block_check", "label": "Suppress block check", view: false },
    // { id: 5, "name": "duplicate_id", "label": "Suppress duplicate id overlap check", view: false },
  ];
  searchBuilding:string=''
  searchNeigh:string =''
  searchBlock:string =''
  fileToUpload: File = null;
  fileUploadForm: FormGroup;
  selectedneightUid: FormControl;
  selectedBlock: FormControl;
  selectedBlockRoad: FormControl;
  parcelEntered = '';
  parcesl: FormControl;
  selectedBuilding: FormControl;
  layer: string;
  selectedlayer: string = null;
  loading: boolean = false;
  loader: boolean = false;
  height: any;
  flag: boolean = false;
  limit: number = 10;
  offset: number = 0;
  statusAll = '';
  record: number;
  disableNext: boolean = false;
  disablePriv: boolean = true;
  pageNo: number = 1;
  sn: number = 1;
  count: number = 0;
  fileName: string = '';
  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  parcelsetback_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  governorates_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  neighbourhoods_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  blocks_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  plans_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  projects_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  parcels_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  streets_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  units_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  roadlayout_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  metro_station_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  metro_line_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  project_road_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  pcc_parcels_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  project_street_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  compound_road_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  project_parcel_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
   phase_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  // is_admin = false;
  unitLayout: boolean = false;
  dataLength: number;

  pages: any = [];
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  // selectedLayer: any;
  neighbourhoods: neighbourhoods[] = [];
  gisDataReport: any = [];
  blocks: any = [];
  buildings: any = [];
  loader1: boolean = false;
  selectedUploadId: any;

  selectedValidator: any = [];
  selectedBuildUid: String = null;
  used_uploadedfiles = [];
  group_uploadedfiles = [];
  child_length: boolean = false;
  childList = [];
  @ViewChild(MatPaginator) paginator: MatPaginator
  Blocks: any;
  filter: boolean = false;
  select_row: any=[];

  @ViewChild('myFileInput') myFileInput;

  search_year:string;
  yearList:any=[];
  selectedYear:string=new Date().getFullYear().toString();
  acceptLoader: boolean = false;
  is_admin: boolean = false;
  constructor(private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private fb: FormBuilder,
    private notify: NotificationService,
    private active: GisDataService,
    private route: Router,
    public dialog: MatDialog) {
    this.menus.setActive('gisDataUpload');
    this.active.setActive('');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Upload Data' };
    this._crumbs.mobiletitle = 'Upload Data';
    this._crumbs.count = 1;
    this.fileUploadForm = new FormGroup({
      selectedneightUid: new FormControl(''),
      selectedBlockRoad: new FormControl(''),
      parcels: new FormControl(''),
      layer: new FormControl('', [Validators.required]),
      file: new FormControl(null, [Validators.required])
    });
    this.onResize();

    this.getAllowResourcesApi();

    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 80);
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event) {
    if (this.loading) {
      if (confirm("File upload is still under progress! If you leave, your changes will be lost.")) {
        return true;
      } else {
        return false;
      }
    }
  }

   //create year dropdown
   getYears() {
    const currentYear = new Date().getFullYear()
    const startYear  = 2009;
    for (var i = currentYear; i >= startYear; i--) {
      this.yearList.push({ val: (i).toString() });
    }
  }
  setGlobleYear(){
    this.getNeighbourhoods(this.fileUploadForm.get('layer').value);
    if(this.selectedlayer) this.getUploadedFilesList(this.selectedlayer, true)

  }

  // date formate
  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date, true);
    } else {
      return '';
    }
  }
  //conflict dialog-------------------------------------------------------------------------------------
  openConflictDialog(data) {
    let dialogRef = this.dialog.open(ConflictDialog, {
      width: '600px',
      panelClass: 'create-master-panel',
      data: {
        parent: this,
        rowData: data
      }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  //conflict dialog-------------------------------------------------------------------------------------


  getBlocks(neighUid) {
    this.loader1 = true;
     let url = `blocks/json?neigh_uid=${neighUid}&return_geometry=true`;

    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    this.api.getGpsData(url).subscribe((res: any) => {
      this.blocks = res;
      this.loader1 = false;
      this.buildings = [];
      this.selectedBuilding = null;
    }, err => {
      this.blocks = [];
      this.buildings = [];
      this.selectedBuilding = null;
      this.selectedBlock = null;
      this.loader1 = false;
      this.notify.notify('No Blocks found in this Neighbourhood', 'error');
    })
  }

  selectblock(e) {
    this.selectedBlock = e.value;
    //concole.log(this.selectedBlock);
  }

  getBuildings(blockData) {
    this.loader1 = true;
    let body = {
      "columns": [
        "key", "geom"
      ],
      "data": [
        [
          "area", blockData.geom
        ]
      ]
    };
    this.api.postGmsData(`places/bulk/search`, body).subscribe({next: (res: any) => {
      let format = res;
      // format = JSON.parse(format);
      if (format.buildings.area?.length) {
        format.buildings.area.forEach(element =>{
          const ele = {...element,searchparam:`${element?.building_name} ${element?.building_uid}`}
          this.buildings.push(ele);
        })
        console.log(this.buildings);

      } else {
        this.buildings = [];
        this.notify.notify('No Buildings found in this Block', 'warn');
        this.selectedBuilding = null;
      }
      this.loader1 = false;
    }, error: (err) => {
      this.buildings = [];
      this.selectedBuilding = null;
      this.loader1 = false;
      this.notify.notify('No Buildings found in this Block', 'error');
    }})
  }
  execute_blocks() {
    if (!this.blocks_access.POST && this.layer == 'blocks') {
      this.notify.notify("You are not authorized to Calculate Distance", "warn");
      return;
    }
    this.api.getGpsData('blocks/execute').subscribe({next: (res: any) => {
      //concole.log(res);
    }, error : (err) => {
      //concole.log(err);
    }})
  }
  initValues() {
    this.fileUploadForm.valueChanges.subscribe((data) => {
      if (data.layer) this.layer = data.layer;
      if (data.selectedneightUid) this.selectedneightUid = data.selectedneightUid;
      if (data.selectedBlock) this.selectedBlock = data.selectedBlock;
      if (data.selectedBuilding) this.selectedBuilding = data.selectedBuilding;
      if (data.layer == 'units' && this.selectedneightUid) {
        this.getBlocks(this.selectedneightUid);
      }
      if (data.layer == 'units' && this.selectedneightUid && this.selectedBlock) {
        let blockData = this.fnc.getArrayValue('block_uid', this.selectedBlock, this.blocks);
        this.getBuildings(blockData);
      }
    })
  }
  ngOnInit() {
    setTimeout(() => {
      this.getYears();
    }, 500);
    this.initValues();
  }
  check(v) {
    if (this.options.length == 1) {
      this.fileUploadForm.patchValue({ 'layer': this.options[0].value });
      return true;
    } else {
      return false;
    }
  }
  initValidators(e) {
    let data = e.value;
    this.getNeighbourhoods(data);
    this.fileName = '';
    this.selectedValidator = [];
    this.selectedneightUid = null;
    if (data == 'units') {
      this.fileUploadForm = new FormGroup({
        selectedBlock: new FormControl(),
        selectedBuilding: new FormControl(),
        selectedneightUid: new FormControl(),
        layer: new FormControl('', [Validators.required]),
        file: new FormControl(null, [Validators.required])
      })
      this.fileUploadForm.patchValue({ 'layer': data });
      this.initValues();
    } else {
      // this.fileUploadForm.patchValue({'selectedneightUid': null});
      this.fileUploadForm = new FormGroup({
        selectedneightUid: new FormControl(''),
        selectedBlock: new FormControl(''),
        parcels: new FormControl(''),
        layer: new FormControl('', [Validators.required]),
        file: new FormControl(null, [Validators.required])
      })
      this.fileUploadForm.patchValue({ 'layer': data });
      this.initValues();
    }

  }
  // getting allow resources
  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
       // this.getNeighbourhoods();

      })
  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     // .map((data:any) => data.json())
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         this.getNeighbourhoods();
  //       },
  //       err => {
  //       }
  //     )
  // }

  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type', 'warn');
        this.fileUploadForm = new FormGroup({
          file: new FormControl(null, [Validators.required])
        })
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  getAllowResource() {
    this.upload_access = this.fnc.checkResourceAccess('gisDataUpload', false);
    this.parcelsetback_access = this.fnc.checkResourceAccess('parcel_setback', false);
    this.governorates_access = this.fnc.checkResourceAccess('governorates', false);
    this.neighbourhoods_access = this.fnc.checkResourceAccess('neighbourhoods', false);
    this.blocks_access = this.fnc.checkResourceAccess('blocks', false);
    this.plans_access = this.fnc.checkResourceAccess('master_plans', false);
    this.projects_access = this.fnc.checkResourceAccess('projects', false);
    this.parcels_access = this.fnc.checkResourceAccess('parcels', false);
    this.streets_access = this.fnc.checkResourceAccess('streets', false);
    this.units_access = this.fnc.checkResourceAccess('units', false);
    this.roadlayout_access = this.fnc.checkResourceAccess('road', false);
    this.metro_line_access = this.fnc.checkResourceAccess('metro_line', false);
    this.metro_station_access = this.fnc.checkResourceAccess('metro_station', false);
    this.project_road_access = this.fnc.checkResourceAccess('project_road', false);
    this.pcc_parcels_access = this.fnc.checkResourceAccess('pcc_parcels', false);
    this.compound_road_access = this.fnc.checkResourceAccess('compound_road', false);
    this.project_parcel_access = this.fnc.checkResourceAccess('project_parcel', false);
    this.phase_access = this.fnc.checkResourceAccess('project_details', false);
    this.project_street_access = this.fnc.checkResourceAccess('project_street', false);
  }

  getNeighbourhoods(data) {

    if(data == 'units' || data == 'parcels' || data == 'road'){
      this.loader1 = true;
      let url = `neighbourhoods/json`;
      if(this.selectedYear){
        url += `?year=${this.selectedYear}`
      }
      this.api.getGpsData(url)
      .subscribe({next: (data: any) => {
        this.loader1 = false;
        if (data.length) {
          this.neighbourhoods = data;
        }
      }, error: (err) => {
        this.loader1 = false;
      }}
      );
    }

  }

  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  uploadFile(val) {

    // if (!this.upload_access.POST) {
    //   this.notify.notify("You are not authorized to upload GIS data (gisDataUpload)", "Dismiss");
    //   return;
    // }
    if (!this.governorates_access.POST && this.layer == 'governorates') {
      this.notify.notify("You are not authorized to upload Governorate", "warn");
      return;
    }
    if (!this.neighbourhoods_access.POST && this.layer == 'neighbourhoods') {
      this.notify.notify("You are not authorized to upload neighbourhoods", "warn");
      return;
    }
    if (!this.blocks_access.POST && this.layer == 'blocks') {
      this.notify.notify("You are not authorized to upload blocks", "warn");
      return;
    }
    if (!this.plans_access.POST && this.layer == 'master_plans') {
      this.notify.notify("You are not authorized to upload Master Plans", "warn");
      return;
    }
    if (!this.projects_access.POST && this.layer == 'projects') {
      this.notify.notify("You are not authorized to upload projects", "warn");
      return;
    }
    if (!this.parcels_access.POST && this.layer == 'parcels') {
      this.notify.notify("You are not authorized to upload parcels", "warn");
      return;
    }
    if (!this.streets_access.POST && this.layer == 'streets') {
      this.notify.notify("You are not authorized to upload streets", "warn");
      return;
    }
    if (!this.units_access.POST && this.layer == 'units') {
      this.notify.notify("You are not authorized to upload units", "warn");
      return;
    }
    if (!this.roadlayout_access.POST && this.layer == 'road') {
      this.notify.notify("You are not authorized to upload Road Layout", "warn");
      return;
    }
    if (!this.parcelsetback_access.POST && this.layer == 'parcel_setback') {
      this.notify.notify("You are not authorized to upload Parcel Setback", "warn");
      return;
    }
    if (!this.metro_station_access.POST && this.layer == 'metro_station') {
      this.notify.notify("You are not authorized to upload metro station", "warn");
      return;
    }
    if (!this.metro_line_access.POST && this.layer == 'metro_line') {
      this.notify.notify("You are not authorized to upload metro line", "warn");
      return;
    }
    if (!this.project_road_access.POST && this.layer == 'project_road') {
      this.notify.notify("You are not authorized to upload project road", "warn");
      return;
    }
    if (!this.pcc_parcels_access.POST && this.layer == 'pcc_parcels') {
      this.notify.notify("You are not authorized to upload pcc parcels", "warn");
      return;
    }
    if (!this.project_street_access.POST && this.layer == 'project_street') {
      this.notify.notify("You are not authorized to upload Compound street", "warn");
      return;
    }
    if (!this.project_parcel_access.POST && this.layer == 'project_parcel') {
      this.notify.notify("You are not authorized to upload compound parcels", "warn");
      return;
    }
    if (!this.phase_access.POST && this.layer == 'project_details') {
      this.notify.notify("You are not authorized to upload phase", "warn");
      return;
    }
    if (!this.compound_road_access.POST && this.layer == 'compound_road') {
      this.notify.notify("You are not authorized to upload compound road", "warn");
      return;
    }

    // let ln = this.fileToUpload.size;
    let layer, uid = null, url;
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    layer = val.value.layer;

    if (layer == 'units') {
      uid = val.value.selectedBuilding;
    } else if (layer == 'parcel_setback') {

    } else if (layer != 'governorates' && layer != 'neighbourhoods' &&
       layer != 'blocks' && layer != 'master_plans' && layer != 'projects' &&
       layer != 'streets' && layer !='metro_line' && layer != 'metro_station' && layer != 'project_road' && layer != 'pcc_parcels' && layer !== 'compound_road' && layer !== 'project_parcel' && layer !== 'project_street' && layer !== 'project_details') {
      if (this.selectedValidator.includes('neigh_overlap') == false) {
        if (val.value.selectedneightUid == null || val.value.selectedneightUid == '') {
          this.notify.notify('Please Select neighbourhoods', 'warn');
          return;
        } else {
          uid = val.value.selectedneightUid;
        }
      } else {
      }
    }
    if (layer == 'road') {
      if (val.value.selectedneightUid == null || val.value.selectedneightUid == '') {
        this.notify.notify('Please Select neighbourhoods', 'warn');
        return;
      } else {
        uid = val.value.selectedneightUid;
      }
    }

    url = layer + '/uploads?added_by=' + this.api.user_id;
    if (uid) {
      url = url + `&uid=${uid}`;
    }
    if (this.selectedValidator.length) {
      url = url + '&suppress_validations=' + this.selectedValidator.toString();
    }
    if(this.selectedYear){
        url += `&year=${this.selectedYear}`;
    }
    this.loading = true;
    this.api.postFile(url, formData).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.notify.notify("File successfully uploaded!", "success");
        this.fileToUpload = null;
        this.fileName = "";
        this.myFileInput.nativeElement.value = "";
        if (layer == "units") {
          this.fileUploadForm = new FormGroup({
            selectedBlock: new FormControl(""),
            selectedBuilding: new FormControl(""),
            selectedneightUid: new FormControl(""),
            //layer: new FormControl('', [Validators.required]),
            file: new FormControl(null, [Validators.required]),
          });
          let e = { value: layer };
          this.initValidators(e);
        } else {
          this.fileUploadForm = new FormGroup({
            file: new FormControl(null, [Validators.required]),
            selectedneightUid: new FormControl(null),
            //layer: new FormControl(null, [Validators.required]),
          });
          let e = { value: layer };
          this.initValidators(e);
        }
        this.selectedValidator = [];
        setTimeout(() => {
          if (this.selectedlayer != null) {
            this.getUploadedFilesList(this.selectedlayer, false);
          }
        }, 1000);
      },
      error: (err) => {
        this.fileToUpload = null;
        this.fileName = "";
        this.myFileInput.nativeElement.value = "";
        if (layer == "units") {
          this.fileUploadForm = new FormGroup({
            selectedBlock: new FormControl(""),
            selectedBuilding: new FormControl(""),
            selectedneightUid: new FormControl(""),
            // layer: new FormControl('', [Validators.required]),
            file: new FormControl(null, [Validators.required]),
          });
        } else {
          this.fileUploadForm = new FormGroup({
            file: new FormControl(null, [Validators.required]),
            selectedneightUid: new FormControl(null),
            //  layer: new FormControl(null, [Validators.required]),
          });
        }
        this.loading = false;
        this.notify.notify(this.fnc.getErrorMessage(err.error), "error");
      },
    });
  }
  uploadData(e) {
    this.selectedlayer = e;
    // if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
    //   this.is_admin = true;
    // }
    let url = '';
    let statusCount = this.statusAll;
    url = this.selectedlayer + '/uploads/status';
    if (statusCount) {
      url += "&status=" + statusCount;
    }
    // if (this.is_admin) {
    //   url = this.selectedlayer + '/uploads/status?status=' + this.statusAll + '&limit=1000';
    //   console.log(url, "fromcount");
    // }
    this.api.getGmusData(url).subscribe((res: any) => {
      this.dataLength = res.length;
      this.total = res.length;
    })
  }

  // getDate(val) {
  //   if (val) {
  //     let d: any[] = val.split("T");
  //     let e = d[1].split('.');
  //     return d[0] + ' ' + e[0];
  //   } else { return 'NA' }
  // }
  getStatus(val) {
    return this.getArrayValue('status_code', val, this.status).value;
  }
  getSubProcessStatus(val) {
    return this.getArrayValue('status_code', val, this.statusSubProcess).value;
  }
  getActive_(e) {
    return this.active.getIsActive(e.value);
  }



  getUploadedFilesList(val, flag: boolean) {
    if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
      this.is_admin = true;
    }

    if (!this.governorates_access.GET && val == 'governorates') {
      this.notify.notify("You are not authorized to VIEW uploaded Governorate", "warn");
      return;
    }
    if (!this.neighbourhoods_access.GET && val == 'neighbourhoods') {
      this.notify.notify("You are not authorized to VIEW uploaded neighbourhoods", "warn");
      return;
    }
    if (!this.blocks_access.GET && val == 'blocks') {
      this.notify.notify("You are not authorized to VIEW uploaded blocks", "warn");
      return;
    }
    if (!this.plans_access.GET && val == 'master_plans') {
      this.notify.notify("You are not authorized to VIEW uploaded Master Plans", "warn");
      return;
    }
    if (!this.projects_access.GET && val == 'projects') {
      this.notify.notify("You are not authorized to VIEW uploaded projects", "warn");
      return;
    }
    if (!this.parcels_access.GET && val == 'parcels') {
      this.notify.notify("You are not authorized to VIEW uploaded parcels", "warn");
      return;
    }
    if (!this.streets_access.GET && val == 'streets') {
      this.notify.notify("You are not authorized to VIEW uploaded streets", "warn");
      return;
    }
    if (!this.units_access.GET && val == 'units') {
      this.notify.notify("You are not authorized to VIEW uploaded units", "warn");
      return;
    }
    if (!this.roadlayout_access.GET && val == 'road') {
      this.notify.notify("You are not authorized to VIEW uploaded Road Layout", "warn");
      return;
    }
    if (!this.parcelsetback_access.GET && val == 'parcel_setback') {
      this.notify.notify("You are not authorized to VIEW uploaded Parcel Setback", "warn");
      return;
    }
    if (!this.metro_station_access.GET && this.layer == 'metro_station') {
      this.notify.notify("You are not authorized to VIEW uploaded metro station", "warn");
      return;
    }
    if (!this.metro_line_access.GET && this.layer == 'metro_line') {
      this.notify.notify("You are not authorized to VIEW uploaded metro line", "warn");
      return;
    }
    if (!this.project_road_access.GET && this.layer == 'project_road') {
      this.notify.notify("You are not authorized to VIEW uploaded project road", "warn");
      return;
    }
    if (!this.pcc_parcels_access.GET && this.layer == 'pcc_parcels') {
      this.notify.notify("You are not authorized to VIEW uploaded pcc parcels", "warn");
      return;
    }
    if (!this.project_street_access.GET && this.layer == 'project_street') {
      this.notify.notify("You are not authorized to VIEW uploaded compound street", "warn");
      return;
    }
    if (!this.project_parcel_access.GET && this.layer == 'project_parcel') {
      this.notify.notify("You are not authorized to VIEW uploaded compound parcel", "warn");
      return;
    }
    if (!this.phase_access.GET && this.layer == 'project_details') {
      this.notify.notify("You are not authorized to VIEW phase", "warn");
      return;
    }
    if (!this.compound_road_access.GET && this.layer == 'compound_road') {
      this.notify.notify("You are not authorized to VIEW uploaded compound road", "warn");
      return;
    }
    if (this.selectedlayer != val) {
      this.statusAll = '';

    }
    this.filter = true;
    this.selectedBuildUid = null;

    let status = this.statusAll;
    let url = '';
    this.record = 0;
    this.loader = true;
    this.selectedlayer = val;
    if (this.selectedlayer == 'units') {
      this.unitLayout = true;
    } else {
      this.unitLayout = false;
    }
    this.active.setActive(this.selectedlayer);
    if (flag) {
      this.offset = 0;
      this.pageNo = 1;
      this.pageIndex = 1;
    }
    let limit = this.limit;
    let offset = this.offset;
    url = this.selectedlayer + '/uploads/status?limit=' + limit + '&offset=' + offset;
    if(!this.is_admin){
      url += "&added_by=" + this.api.user_id;
    }
    if (status) {
      url += "&status=" + status;
    }
    if(offset == 0){
      url +="&is_count=true"
    }
    if(this.selectedYear){
      url += `&year=${this.selectedYear}`;
    }
    this.api.getGmusData(url).subscribe((res: any) => {
      this.loader = false;
      if(offset == 0)this.total = res.totalCount;
      this.record = res.length;
      this.count = this.offset;
      this.gisDataReport = [];
      var otherStatus = [];
      if (res.data.length) {
        res.data.forEach(e => {
          otherStatus = [];
          let otherst=null;
          if (e.other_status != null) {
            otherst = e.other_status?.is_delete == "true" ? e.other_status?.data : null;
            for (const [key, value] of Object.entries(e.other_status)) {
              otherStatus.push(value);
            }
          }
          this.sn = ++this.count;
          let conflictdata:any=[];
          if(e.conflict && e.conflict.building_uids){
            for(let i =0; i < e.conflict.building_uids.length; i ++){
              for (let [key, value] of Object.entries(e.conflict.building_uids[i])) {
                conflictdata.push({key : key, val: value})
              }
            }
            e.conflict = conflictdata;
          }
          let d = {
            sn: this.sn,
            uid: e.uid,
            name: e.name,
            added_date: this.getdateForm(e.added_date),
            accept_date: this.getdateForm(e.accept_date),
            validate_time: e.validate_time,
            accept_time: e.accept_time,
            status: this.getStatus(e.status),
            errfile_link: e.errfile_link,
            logfile_link: e.logfile_link,
            conflict: e.conflict,
            accept_errfile_link: e.accept_errfile_link,
            other_status: otherStatus,
            upload_id: e.upload_id,
            user_name: e.added_by,
            existing_count: e.existing_count,
            add_count: e.add_count,
            delete_count: e.delete_count,
            update_count: e.update_count,
            street_delete_count: e.street_delete_count,
            check: (Number(e.existing_count)) - (Number(e.update_count) + Number(e.delete_count)),
            suppress_validations:e?.suppress_validations,
            otherst: otherst
          }
          this.gisDataReport.push(d);

        });
        console.log(this.gisDataReport);

      } else {
        this.gisDataReport = [];
        this.loader = false;
        this.notify.notify('No results found', 'warn');
      }
    }, err => {
      this.loader = false;
      this.gisDataReport = [];
      this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
    })
  }

  filterByStatus(status) {
    if (status == "All") {
      this.statusAll = "1,2,-3,-1,-2,0,4";
      this.getUploadedFilesList(this.selectedlayer, true);
    }
    else {
      this.statusAll = status.toString();
      this.getUploadedFilesList(this.selectedlayer, true);
    }
  }


  isLayerAllowed(layer) {
    let allowedLayers = ['governorates', 'neighbourhoods', 'blocks', 'master_plans', 'projects'];
    if (allowedLayers.includes(layer)) {
      return true;
    } else {
      return false;
    }
  }
  isLogView(v) {
    if (v == 'Valid' || v == 'Uploaded' || v == 'Invalid' || v == 'Rejected') {
      return true;
    }
  }
  isacceptView(v) {
    if (v == 'Accepted' || v == 'Accept Invalid' || v == 'Accept Progress') {
      return true;
    }
  }
  isAccepted(v) {
    if (v == 'Valid') {
      return true;
    }
  }
  isRejected(v) {
    if (v == 'Invalid' || v == 'Valid') {
      return true;
    }
  }

  checkDis(id){
    if(this.select_row.indexOf(id)>-1 && this.acceptLoader){
      return true;
    }
    return false;
  }
  action(data, flag) {
    if (!this.governorates_access.PATCH && this.selectedlayer == 'governorates') {
      this.notify.notify("You are not authorized to Modify uploaded Governorate", "warn");
      return;
    }
    if (!this.neighbourhoods_access.PATCH && this.selectedlayer == 'neighbourhoods') {
      this.notify.notify("You are not authorized to Modify uploaded neighbourhoods", "warn");
      return;
    }
    if (!this.blocks_access.PATCH && this.selectedlayer == 'blocks') {
      this.notify.notify("You are not authorized to Modify uploaded blocks", "warn");
      return;
    }
    if (!this.plans_access.PATCH && this.selectedlayer == 'master_plans') {
      this.notify.notify("You are not authorized to Modify uploaded Master Plans", "warn");
      return;
    }
    if (!this.projects_access.PATCH && this.selectedlayer == 'projects') {
      this.notify.notify("You are not authorized to Modify uploaded projects", "warn");
      return;
    }
    if (!this.parcels_access.PATCH && this.selectedlayer == 'parcels') {
      this.notify.notify("You are not authorized to Modify uploaded parcels", "warn");
      return;
    }
    if (!this.streets_access.PATCH && this.selectedlayer == 'streets') {
      this.notify.notify("You are not authorized to Modify uploaded streets", "warn");
      return;
    }
    if (!this.units_access.PATCH && this.selectedlayer == 'units') {
      this.notify.notify("You are not authorized to Modify uploaded units", "warn");
      return;
    }
    if (!this.roadlayout_access.PATCH && this.selectedlayer == 'road') {
      this.notify.notify("You are not authorized to Modify uploaded Road Layout", "warn");
      return;
    }
    if (!this.parcelsetback_access.PATCH && this.selectedlayer == 'parcel_setback') {
      this.notify.notify("You are not authorized to Modify uploaded Parcel Setback", "warn");
      return;
    }
    if (!this.metro_station_access.PATCH && this.layer == 'metro_station') {
      this.notify.notify("You are not authorized to Modify uploaded metro station", "warn");
      return;
    }
    if (!this.metro_line_access.PATCH && this.layer == 'metro_line') {
      this.notify.notify("You are not authorized to Modify uploaded metro line", "warn");
      return;
    }
    if (!this.project_road_access.PATCH && this.layer == 'project_road') {
      this.notify.notify("You are not authorized to Modify uploaded project road", "warn");
      return;
    }
    if (!this.pcc_parcels_access.PATCH && this.layer == 'pcc_parcels') {
      this.notify.notify("You are not authorized to Modify uploaded pcc parcels", "warn");
      return;
    }
    if (!this.project_street_access.PATCH && this.layer == 'project_street') {
      this.notify.notify("You are not authorized to Modify compound street", "warn");
      return;
    }
    if (!this.project_parcel_access.PATCH && this.layer == 'project_parcel') {
      this.notify.notify("You are not authorized to Modify compound parcel", "warn");
      return;
    }
    if (!this.phase_access.PATCH && this.layer == 'project_details') {
      this.notify.notify("You are not authorized to Modify  phase", "warn");
      return;
    }
    if (!this.compound_road_access.PATCH && this.layer == 'compound_road') {
      this.notify.notify("You are not authorized to Modify compound road", "warn");
      return;
    }
    // if (!this.upload_access.PATCH) {
    //   this.notify.notify("You are not authorized to update GIS information (gisDataUpload)", "Dismiss");
    //   return;
    // }
    // if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
    //   this.is_admin = true;
    // }
    //   if (!this.is_admin) {
    //     this.notify.notify("You are not authorized to accept and reject , admin can only accept", "Dismiss");
    //     return
    //   }

    if (flag == 'accept') {

      if(data.otherst){
        let dialogDef = this.dialog.open(ConfirmDialogBox, {
          width: '450px',
          data: {'parent_data':null, 'message':data.otherst},
          disableClose: true
        })
        dialogDef.afterClosed().subscribe(res=>{
          if(res == 'YES'){
            this.select_row.push(data.upload_id);
            this.acceptLoader = true;
            setTimeout(() => {
              this.getUploadedFilesList(this.selectedlayer, false);
            }, 2000);
            let url = this.selectedlayer + '/uploads/' + data.upload_id + '/accept?accepted_by=' + this.api.user_id;
            this.api.postUserAction(url, null).subscribe((res: any) => {
              this.acceptLoader = false;
              this.select_row=[];
              this.getUploadedFilesList(this.selectedlayer, false);
              if(res){
                this.notify.notify('File Accepted', 'success');
              }else{
                this.notify.notify('File Not Accepted', 'error');
              }
            }, err => {
              this.acceptLoader = false;
              this.select_row=[];
              this.getUploadedFilesList(this.selectedlayer, false);
              this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
            })
          }
        })
      }else{
        this.select_row.push(data.upload_id);
        this.acceptLoader = true;
        setTimeout(() => {
          this.getUploadedFilesList(this.selectedlayer, false);
        }, 2000);
        let url = this.selectedlayer + '/uploads/' + data.upload_id + '/accept?accepted_by=' + this.api.user_id;
        this.api.postUserAction(url, null).subscribe((res: any) => {
          this.acceptLoader = false;
          this.select_row=[];
          this.getUploadedFilesList(this.selectedlayer, false);
          if(res){
            this.notify.notify('File Accepted', 'success');
          }else{
            this.notify.notify('File Not Accepted', 'error');
          }
        }, err => {
          this.acceptLoader = false;
          this.select_row=[];
          this.getUploadedFilesList(this.selectedlayer, false);
          this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
        })
      }

    }
    else if (flag == 'reject') {
      this.loader = true;
      let url = this.selectedlayer + '/uploads/' + data.upload_id + '/reject?rejected_by=' + this.api.user_id;
      this.api.postUserAction(url, null).subscribe((res: any) => {
        this.loader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
        if(res){
          this.notify.notify('File Rejected', 'success');
        }else{
          this.notify.notify('File Not Rejected', 'error');
        }

      }, err => {
        this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
        this.getUploadedFilesList(this.selectedlayer, false);
        this.loader = false;
      })
    }
  }

  calculateDirection(data) {
    this.loader = true;
    let url = `manage-process`
    var body = {
      "process_name": "gis_update_direction_corner_by_uploadid",
      "upload_id": data.upload_id,
      "user_id": this.api.user_id,
      "neigh_uid": data.uid
    }
    this.api.postGmsData(url, body).subscribe((res: any) => {
      this.notify.notify('Calculation Started', 'warn');
      this.loader = false;
      this.getUploadedFilesList(this.selectedlayer, false);
    }, err => {
      this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
      this.getUploadedFilesList(this.selectedlayer, false);
      this.loader = false;
    })
  }



  showStopbutton(status) {
    if (status == 'Accept Progress' || status == 'Uploaded') {
      return true;
    }
  }
  // stopprocess api call
  stopprocess(data, process) {

    // if (!this.upload_access.GET) {
      //this.notify.notify("You are not authorized to stop process", "Dismiss", 5000);
      // return;
    // }
    let processName = 'gis_accept_upload';

    if (data.status == 'Uploaded') {
      processName = 'gis_validate_upload';
    }
    if (process == 'direction') {
      processName = 'gis_update_direction_corner_by_uploadid';
    }

    let url = `stop-process?uid=${data.upload_id}&user_id=${this.api.user_id}&process_name=${processName}`;
    // if (process == '')
      this.loader = true
    this.api.getGmsData(url).subscribe({next: (res) => {
      this.loader = false
      if (res) {
        this.getUploadedFilesList(this.selectedlayer, false);
        this.notify.notify("Stop process successful", "warn", 3000);
      } else {
        this.notify.notify("Stop process unsuccessful", "error", 5000);
      }
    }, error: (err) => {
      this.loader = false;
      this.notify.notify(err.error.message, "error", 5000);
    }})
  }

  openConfirmation() {
    // if (!this.upload_access.PATCH) {
    //   this.notify.notify("You are not authorized to Calculate Setback", "Dismiss", 5000);
    //   return;
    // }
    if (!this.roadlayout_access.POST && this.layer == 'road') {
      this.notify.notify("You are not authorized to Calculate Setback", "warn");
      return;
    }
    if (this.selectedneightUid == null || this.selectedneightUid == undefined) {
      this.notify.notify("Please select Neighbourhood", "warn");
      return;
    }
    let _data = {
      parent_data: this,
      message: 'Do you really want to run Setback Calculation?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      this.setbackCalculate()
    }
  }
  ////////////////////////////////

  setbackCalculate() {
    // if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
    //   this.is_admin = true;
    // }
    // if (!this.upload_access.PATCH) {
    //   this.notify.notify("You are not authorized to calculate setback (gisDataUpload)", "Dismiss");
    //   return;
    // }
    if (!this.roadlayout_access.POST && this.layer == 'road') {
      this.notify.notify("You are not authorized to Calculate Setback", "warn");
      return;
    }
    if (this.selectedneightUid == null || this.selectedneightUid == undefined) {
      this.notify.notify("Please select Neighbourhood", "warn");
      return;
    }
    let url = `setback-calculate/${this.layer}?neigh_uid=${this.selectedneightUid}&accepted_by=${this.api.user_id}`;

    if (this.selectedBlock != null) {
      url = url + `&block_uid=${this.selectedBlock}`
    }
    if (this.parcelEntered != null && this.parcelEntered != '') {
      url = url + `&parcel_ids=${this.parcelEntered}`;
    }

    this.loading = true;

    this.api.getGesData(url).subscribe({next: (res: any) => {
      this.loading = false;
    }, error: (err) => {
      this.loading = false;
      this.notify.notify("Something Error", "error")
    }})
  }

  downloadLog() {
    if (!this.roadlayout_access.POST && this.layer == 'road') {
      this.notify.notify("You are not authorized to Download Log", "warn");
      return;
    }
    let uid = this.selectedneightUid;
    if (this.selectedBlock) {
      uid = this.selectedBlock
    }
    let url = `${this.api.gesUrl}gesapi/log/${this.api.city_code}_${uid}_run_setback_err.log`;
    window.open(url, "_blank");
  }

  downloadLogSub(uploadId) {
    let LogString = this.selectedlayer;
    if (uploadId != null) {
      LogString += "_" + uploadId;
    }
    // if(id != null) {
    // 	LogString += "_"+id;
    // }
    // if(uid != null) {
    // 	LogString += "_"+uid;
    // }
    // if(neighUid != null){
    // 	LogString += "_"+neighUid;
    // }
    // if(blockUid != null) {
    // 	LogString += "_"+blockUid;
    // }
    let url = `${this.api.gmsUrl}gis/api/logs/${this.api.city_code}_gis_update_direction_corner_by_uploadid_${uploadId}_manage_process_err.log`;
    window.open(url, "_blank");
  }


  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getUploadedFilesList(this.selectedlayer, false);
  }

  // getvalue(id) {
  //   let val = this.fnc.getArrayValue('id', id, this.filter);
  //   return val.label;

  // }
  checkAllSelected(val) {
    var index = this.selectedValidator.indexOf(val.name + '_overlap');
    if (index !== -1) {
      return true
    } else {
      return false
    }

  }
  updatefilter(event, val) {
    if (event.checked) {
      this.selectedValidator.push(val.name);
      //concole.log(this.selectedValidator);

    } else {
      var index = this.selectedValidator.indexOf(val.name);
      if (index !== -1) this.selectedValidator.splice(index, 1);
    }
  }
  setBuilding(e, data) {
    let id, val = e.value;
    if (data) {
      this.selectedUploadId = data;
    }
    this.selectedBuildUid = val;
  }
  getCurrentBuild(data) {
    let id = data.upload_id;
    if (id == this.selectedBuildUid) {
      return id;
    } else {
      return null;
    }
  }
  update(val) {
    this.loader = true;
    let body = {
      "uid": this.selectedBuildUid,
      "updated_by": this.api.user_id,
      "year": this.selectedYear
    }
    let url = this.selectedlayer + '/uploads/' + val.upload_id;

    this.api.putGmusData(url, body).subscribe({next: (data) => {
      this.loader = false;
      setTimeout(() => {
        this.getUploadedFilesList(this.selectedlayer, false);
      }, 5000);
    }, error: (err) => {
      this.loader = false;
      this.notify.notify(err.error.message, "error");
    }})
  }

  unSelect() {
    this.selectedBuildUid = '';
    //this.getUploadedFilesList(this.selectedlayer, false);
  }
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_layer_uploads_upload_id_put</li>
 * </ol>
 *
 */
@Component({
  selector: 'conflict-dialog',
  templateUrl: './conflict-dialog.html',
  styleUrls: ['./gis-data-upload.component.css']
})

export class ConflictDialog {
  rowData: any;
  tabLabels: any = [];
  activeLink: string = '';
  background: string = 'primary';
  conflictData: any;
  selectedLayer: string;
  loader: boolean = false;
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  constructor(public dialogRef: MatDialogRef<ConflictDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiserviceService,
    private notify: NotificationService) {
    this.selectedLayer = this.data.parent.selectedlayer;
    //concole.log(this.selectedLayer);

    this.rowData = this.data.rowData;
    this.conflictData = this.rowData.conflict; //setting data source here
    // this.conflictData = TEST_DATA; //setting dummy data as source here
    this.tabLabels = this.getArray(this.conflictData);
    let key = this.tabLabels[0];
    this.activeLink = this.getLabel({ name: key.name });
    if (this.conflictData) this.getData(this.conflictData, key.name);
  }
  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  ngOninit() { }

  revalidate() {
    this.loader = true;
    let body = {}, url = `${this.selectedLayer}/uploads/${this.rowData.upload_id}`;
    body = {
      "uid": null,
      "updated_by": this.api.user_id
    }
    this.api.putGmusData(url, body).subscribe((res: any) => {
      this.loader = false;
      this.notify.notify('Success!', 'success');
      this.dialogRef.close();
      this.data.parent.getUploadedFilesList(this.selectedLayer, false);
    }, err => {
      this.loader = false;
      this.notify.notify('Could not revalidate', 'error');
      this.dialogRef.close();
    })
  }
  getLabel(tab) {
    if (tab.name.includes('update')) {
      return 'To Update';
    } else {
      return 'To Delete';
    }
  }

  changeTab(e) {
    this.activeLink = this.getLabel(e);
    this.getData(this.conflictData, e.name);
  }

  getData(data: any, key: string) {
    //concole.log('called');
    let formattedData = [], parent_list: any = [];
    if (data && data[key]) {
      parent_list = this.getArray(data[key]);
      parent_list.forEach(item => {
        let temp = {};
        if (item && item.children) {
          temp = {
            name: item.name,
            children: this.getArray(item.children, true)
          }
          formattedData.push(temp);
        }
      });
      this.dataSource.data = formattedData;
    }

  }

  getArray(data, convertToArr = false) {
    let obj = data, arr = [];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        let val = {};
        if (convertToArr) {
          val = { name: key, children: this.convertIntoObject(obj[key]) }
        } else {
          val = { name: key, children: obj[key] }
        }
        arr.push(val);
      }
    }
    return arr;
  }

  convertIntoObject(arr) {
    if (Array.isArray(arr)) {
      let newArr = [];
      arr.forEach(item => {
        newArr.push({ name: item });
      });
      return newArr;
    } else {
      return arr;
    }
  }
}

interface TreeNode {
  name: string;
  children?: TreeNode[];
}

const TEST_DATA = {
  "blocks_to_update_conflict": {
    "UP1": {
      "parcels": [
        "PKWTBL9360",
        "PKWTBL9893",
        "PKWTBL9950",
        "PKWTBL9847",
        "PKWTBL9858",
        "PKWTBL9857",
        "PKWTBL9856",
        "PKWTBL9855",
        "PKWTBL9854",
        "PKWTBL9852",
        "PKWTBL9850",
        "PKWTBL9848",
        "PKWTBL9932",
        "PKWTBS1884",
        "PKWTBS0992",
        "PKWTBS0991",
        "PKWTBL9904",
        "PKWTBS1873",
        "PKWTBS1872",
        "PKWTBS1870",
        "PKWTBS1876",
        "PKWTBS1875",
        "PKWTBL9884",
        "PKWTBL9882",
        "PKWTBL9881",
        "PKWTBL9880",
        "PKWTBL9879",
        "PKWTBL9877",
        "PKWTBL9876",
        "PKWTBL9866",
        "PKWTBL9851",
        "PKWTBL9924",
        "PKWTBL9920",
        "PKWTBL9919",
        "PKWTBL9915",
        "PKWTBL9912",
        "PKWTBL9909",
        "PKWTBL9900",
        "PKWTBL9892",
        "PKWTBL9890",
        "PKWTBL9887",
        "PKWTBL9886",
        "PKWTBS1883",
        "PKWTBS1880",
        "PKWTBS1878",
        "PKWTBS1871",
        "PKWTBS1869",
        "PKWTBS0990",
        "PKWTBL9905",
        "PKWTBL9902",
        "PKWTBL9894",
        "PKWTBL9889",
        "PKWTBL9883",
        "PKWTBL9878",
        "PKWTBL9874",
        "PKWTBL9873",
        "PKWTBL9872",
        "PKWTBL9870",
        "PKWTBL9869",
        "PKWTBL9868",
        "PKWTBL9867",
        "PKWTBL9865",
        "PKWTBL9864",
        "PKWTBL9863",
        "PKWTBL9862",
        "PKWTBL9861",
        "PKWTBL9860",
        "PKWTBL9859",
        "PKWTBL9965",
        "PKWTBS1874",
        "PKWTBL9871",
        "PKWTBS1900",
        "PKWTBS1899",
        "PKWTBS1882",
        "PKWTBS1881",
        "PKWTBS1879",
        "PKWTBS1877",
        "PKWTBS0989",
        "PKWTBS0988",
        "PKWTBS0987",
        "PKWTBS0986",
        "PKWTBS0985",
        "PKWTBS0984",
        "PKWTBS0983",
        "PKWTBS0982",
        "PKWTBL9953",
        "PKWTBL9942",
        "PKWTBL9939",
        "PKWTBL9938",
        "PKWTBL9934",
        "PKWTBL9931",
        "PKWTBL9929",
        "PKWTBL9927",
        "PKWTBL9923",
        "PKWTBL9921",
        "PKWTBL9918",
        "PKWTBL9917",
        "PKWTBL9914",
        "PKWTBL9913",
        "PKWTBL9911",
        "PKWTBL9908",
        "PKWTBL9907",
        "PKWTBL9906",
        "PKWTBL9903",
        "PKWTBL9901",
        "PKWTBL9899",
        "PKWTBL9898",
        "PKWTBL9897",
        "PKWTBL9896",
        "PKWTBL9891",
        "PKWTBL9888",
        "PKWTBL9885",
        "PKWTBL9875",
        "PKWTBL9853",
        "PKWTBL9849",
        "PKWTBL9846",
        "PKWTBS1885",
        "PKWTBL9928",
        "PKWTBL9895",
        "PKWTBL9845",
        "PKWTBL9968",
        "PKWTBL9954"
      ],
      "buildings": [
        "BKWTAA0758",
        "BKWTAA0741",
        "BKWTAA0550",
        "BKWTAA0543",
        "BKWTAA0518"
      ]
    },
    "UP2": {
      "parcels": [
        "PKWTBS1888",
        "PKWTBL9353",
        "PKWTBS1897",
        "PKWTBS1896",
        "PKWTBS1894",
        "PKWTBS1893",
        "PKWTBS1887",
        "PKWTBS1886",
        "PKWTBL9988",
        "PKWTBL9987",
        "PKWTBL9986",
        "PKWTBL9985",
        "PKWTBL9983",
        "PKWTBL9982",
        "PKWTBL9981",
        "PKWTBL9980",
        "PKWTBL9978",
        "PKWTBL9977",
        "PKWTBL9976",
        "PKWTBL9975",
        "PKWTBL9974",
        "PKWTBL9973",
        "PKWTBL9972",
        "PKWTBL9971",
        "PKWTBL9969",
        "PKWTBL9967",
        "PKWTBL9964",
        "PKWTBL9963",
        "PKWTBL9962",
        "PKWTBL9961",
        "PKWTBL9959",
        "PKWTBL9958",
        "PKWTBL9957",
        "PKWTBL9956",
        "PKWTBL9955",
        "PKWTBL9952",
        "PKWTBL9951",
        "PKWTBL9948",
        "PKWTBL9946",
        "PKWTBL9944",
        "PKWTBL9943",
        "PKWTBL9940",
        "PKWTBL9936",
        "PKWTBL9935",
        "PKWTBL9930",
        "PKWTBL9455",
        "PKWTBL9454",
        "PKWTBL9451",
        "PKWTBL9450",
        "PKWTBL9449",
        "PKWTBL9446",
        "PKWTBL9361",
        "PKWTBL9358",
        "PKWTBL9357",
        "PKWTBL9350",
        "PKWTBL9354",
        "PKWTBS1898",
        "PKWTBS1895",
        "PKWTBS1892",
        "PKWTBS1891",
        "PKWTBS1890",
        "PKWTBS1889",
        "PKWTBL9984",
        "PKWTBL9979",
        "PKWTBL9456",
        "PKWTBL9453",
        "PKWTBL9447",
        "PKWTBL9351",
        "PKWTBL9925",
        "PKWTBL9910",
        "PKWTBL9966",
        "PKWTBL9949",
        "PKWTBL9960",
        "PKWTBL9937",
        "PKWTBL9947",
        "PKWTBL9945",
        "PKWTBL9933",
        "PKWTBL9926",
        "PKWTBL9922",
        "PKWTBL9916",
        "PKWTBL9970",
        "PKWTBL9941",
        "PKWTBL9452",
        "PKWTBL9445",
        "PKWTBL9359",
        "PKWTBL9356",
        "PKWTBL9352",
        "PKWTBL9448",
        "PKWTBL9355"
      ],
      "buildings": [
        "BKWTAA0752",
        "BKWTAA0571",
        "BKWTAA0814"
      ]
    },

  },
  "blocks_to_delete_conflict": {
    "DEL1": {
      "parcels": [
        "DELTBL9895",
        "DELTBL9845",
        "DELTBL9968",
        "DELTBL9954"
      ],
      "buildings": [
        "BKWTAA0758",
        "BKWTAA0741",
        "BKWTAA0550",
        "BKWTAA0543",
        "BKWTAA0518"
      ]
    },
    "DEL2": {
      "parcels": [
        "PKWTBS1888",
        "PKWTBL9353",
      ],
      "buildings": [
        "BKWTAA0752",
        "BKWTAA0571",
        "BKWTAA0814"
      ]
    },

  }
}
