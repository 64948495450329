<div class="parent-container">
    <mat-card appearance="outlined" class="example-card">
        <mat-card-header>
            <mat-card-title class="master-title title open-sans">Calculate/Sync Data Upload</mat-card-title>
            <mat-select class="year-dropdown" [(ngModel)]="selectedYear" placeholder="Year"
                (selectionChange)="setGlobleYear()">
                <div class="search_bar">
                    <input mat-input [(ngModel)]="search_year" [ngModelOptions]="{standalone: true}"
                        placeholder="Search..." (keydown)="$event.stopPropagation();" />
                </div>
                <mat-option *ngFor="let year of yearList | searchfilter:'val':search_year" [value]="year.val">
                    {{year.val}}</mat-option>
            </mat-select>
        </mat-card-header>
        <mat-card-content>
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
            <div class="gis_data_form">
                <form [formGroup]="calculateSyncForm">
                    <div class="bottom-border">
                        <mat-radio-group [(ngModel)]="selected" [ngModelOptions]="{standalone: true}"
                            (change)="selectedForm($event.value)">
                            <mat-radio-button class="radio_text" *ngFor="let option of options" [value]="option.value">
                                {{option.label}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div>
                        <span class="fntcustom" *ngIf="fileName">{{fileName}}</span>
                    </div>
                    <div style="display: flex;margin-bottom: 10px;">
                        <div class="upload-btn-wrapper">
                            <button mat-button class="browesbtn">
                                <mat-icon svgIcon="cloud_upload" class="upicon"></mat-icon> Browse
                            </button>
                            <input type="file" name="myfile" #myFileInput (change)="handleFileInput($event)"
                                formControlName="file" accept=".zip" />
                        </div>
                        <button style="width: 100px;margin-right: 20px;" matTooltip="Upload" (click)="fileUpload()"
                            mat-raised-button color="primary">
                            <span *ngIf="(!file_check && !loading) || (file_check && !loading)">Upload</span>
                            <span *ngIf="file_check && loading">Uploading...</span>
                        </button>
                    </div>

                 </form>
                </div>
            </mat-card-content>
        </mat-card>
                  <!-- Table Data Start -->
                  <mat-card appearance="outlined" class="table-card">
                    <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-card-title class="master-title title open-sans">Calculate/Sync</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="tabs">
                        <ngx-loading [show]="loader1"
                            [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                        <div class="bottom-border">
                            <button class="margr5" *ngFor="let option of options"
                                (click)="getReports(option.value,'button')"
                                [ngClass]="{'active': getActive_(option)}">
                                {{option.label}}</button>
                        </div>
                        <div class="table-responsive">
                            <table mat-table [dataSource]="dataSource" matSort class="w-100 data-table table">
                                <ng-container *ngFor="let cols of displayedColumns">
                                    <ng-container matColumnDef="{{ cols }}">
                                        <ng-container *ngIf="cols=='upload_id' || cols=='process_name'">
                                            <th mat-header-cell mat-sort-header *matHeaderCellDef>
                                                <span class="header action cp" *ngIf="cols == 'upload_id'"> Upload ID
                                                </span>
                                                <span class="header action cp" *ngIf="cols == 'process_name'">File
                                                    Name</span>
                                            </th>
                                        </ng-container>
                                        <ng-container *ngIf="cols!='upload_id' || cols!='process_name'">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <span *ngIf="cols == 'sno'">S.No</span>
                                                <span *ngIf="cols!='sno'" class="header action cp">{{ cols }}</span>
                                            </th>
                                        </ng-container>
                                        <td mat-cell *matCellDef="let element; let i=index;">
                                            <span *ngIf="cols == 'sno'"> {{offset+i+1}}.</span>
                                            <span [ngClass]="{green: getStatus(element.status)=='Accepted'}"
                                                *ngIf="cols=='Status' && getStatus(element.status)=='Accepted'">{{getStatus(element.status)}}</span>
                                            <span [ngClass]="{red: getStatus(element.status)=='Rejected'}"
                                                *ngIf="cols=='Status' && getStatus(element.status)=='Rejected'">{{getStatus(element.status)}}</span>
                                            <span [ngClass]="{red: getStatus(element.status)=='Invalid'}"
                                                *ngIf="cols=='Status' && getStatus(element.status)=='Invalid'">{{getStatus(element.status)}}</span>
                                            <span
                                                *ngIf="cols=='Status' && getStatus(element.status)!='Invalid'   && getStatus(element.status)!='Rejected'  && getStatus(element.status)!='Accepted'">{{getStatus(element.status)}}</span>

                                            <button class="view" *ngIf="cols == 'Log File'"
                                                (click)="download_process_log(element.layer,element.process_name,element.id)">Log</button>
                                            <a target="_blank" *ngIf="cols == 'View Log'"
                                                href="{{element.errfile_link}}" class="bordered line-hith" mat-button>Validate Log</a>
                                            <a *ngIf="cols == 'View Log'" [disabled]="!isacceptView(getStatus(element.status))"
                                                target="_blank" href="{{element.accept_errfile_link}}"
                                                class="bordered line-hith ml-1" mat-button>Accept Log </a>
                                            <div *ngIf="cols=='Actions'"
                                                class="d-flex justify-content-start align-items-center">
                                                <button [disabled]="!isAccepted(getStatus(element.status))"
                                                    [ngClass]="{btndisabled: !isAccepted(getStatus(element.status))}"
                                                    class="bordered line-hith prim mr-1" mat-button
                                                    (click)="action(element, 'accept')">Accept</button>
                                                <button [disabled]="!isRejected(getStatus(element.status))"
                                                    [ngClass]="{btndisabled: !isRejected(getStatus(element.status))}"
                                                    class="bordered line-hith warn mr-1" mat-button
                                                    (click)="action(element, 'reject')">Reject</button>
                                                <button [disabled]="!showStopbutton(getStatus(element.status))"
                                                    [ngClass]="{btndisabled: !showStopbutton(getStatus(element.status))}"
                                                    class="bordered line-hith warn" mat-button
                                                    (click)="stopProcess(element,'parcels')">Stop Process</button>
                                            </div>
                                            <p matTooltip={{returnVal(element,cols)}}
                                                *ngIf="cols != 'sno' && cols != 'Parameter' && cols != 'Process Log' && cols != 'Status'"
                                                class="more mb-0">
                                                {{returnVal(element, cols)}}</p>
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
                            </table>
                        </div>
                        <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
                            [showFirstLastButtons]="true" (page)="pageEvent = $event; handlePagination($event)">
                        </mat-paginator>
                    </mat-card-content>
                </mat-card>
