import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: "app-description",
  templateUrl: "./description.component.html",
  styleUrls: ["./description.component.css"],
})
export class DescriptionComponent implements OnInit {
  description: string = "";
  parentData: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "250px",
    minHeight: "0",
    maxHeight: "auto",
    width: "100%",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter description here...",
    defaultParagraphSeparator: "",
    defaultFontName: "Open Sans sans-serif",
    defaultFontSize: "5",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "Open Sans sans-serif", name: "Open Sans, sans-serif" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
    ],
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      [
        "undo",
        "redo",
        "fontName",
        "underline",
        "strikeThrough",
        "fontName",
        "fontSize",
      ],
      ["insertImage", "insertVideo", "insertHorizontalRule", "unlink"],
    ],
  };
  neigh_uid: string = "";
  name: string = "";
  selectedlayer: any;
  loader: boolean;

  selected_lang = 1;
  language_list = [
    { val: 1, label: "English"},
    { val: 2, label: "Arabic"},
  ];

  constructor(
    public dialogRef: MatDialogRef<DescriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService
  ) {
    this.description = this.fnc.removeMetaCharacters(
      this.data?.element.description[this.selected_lang]
    );
    this.neigh_uid = this.data?.element.uid;
    this.name = this.data?.element.name;
    this.selectedlayer = this.data?.selectedlayer;
  }

  selectLang(event){
    if(this.data?.element.description[this.selected_lang]){
      this.description = this.fnc.removeMetaCharacters(this.data?.element.description[this.selected_lang]);
    }else{
      this.description = '';
    }
  }

  save() {
    //this.dialogRef.close(this.description);
    let body = [],
      description = {};
      description[this.selected_lang] = this.fnc.escapeMetaCharacters(this.description.trim());
      let record = {
        added_by: this.api.user_id,
        description: description,
        entity_id: this.data?.element.id,
        entity_name: this.selectedlayer,
        status: 1,
      };
    body.push(record);
    let url = "description";
    this.loader = true;
    this.api.patchData(url, body).subscribe(
      (res: any) => {
        this.loader = false;
        if (res == "Description updated") {
          this.notify.notify("Description successfully updated", "success",  5000);
          this.dialogRef.close(true);
        } else {
          this.notify.notify("Description not updated", "error", 5000);
        }
      },
      (err) => {
        this.loader = false;
        if (err.error.text == "Description updated") {
          this.notify.notify( "Description successfully updated","success",5000);
          this.dialogRef.close(true);
        } else {
          this.notify.notify("Description not updated", "error", 5000);
        }
      }
    );
  }
  cancel() {
    this.dialogRef.close(null);
  }
  ngOnInit(): void {}
}
