<mat-toolbar class="bdr-btm">
    <h2>
        Your Notifications
    </h2>
</mat-toolbar>
<div class="margt">
    <div class="outer-container" [ngStyle]="{'height':height+'px'}" detect-scroll (onScroll)="onScroll($event)">
        <div class="padded">
            <ng-container *ngIf="notification.length>0">
                <div class="notifybox-large" (click)="markAsRead(data)" *ngFor="let data of notification" [ngStyle]="{'background-color':data.email_status === 'sent' ? 'rgb(208, 235, 219)' : '#f6f6f6', 'cursor':data.email_status === 'read' ? 'normal' : 'pointer' }">
                    <div class="upper">
                        <div>
                            <strong class="fnt15" [ngStyle]="{'color':data.email_status === 'sent' ? '#27325f' : '#b3b3b3' }">{{data.from_user_name}}
                                <span class="fnt13">({{data.from_user_id}})</span>
                            </strong>
                        </div>
                        <div>
                            <span class="time">{{getDate(data.added_date)}} | {{getTime(data.added_date)}}</span>
                        </div>
                    </div>
                    <div>
                        <p class="msg fnt13" [ngStyle]="{'color':data.email_status === 'sent' ? '#27325f' : '#b3b3b3' }">{{data.message}}</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="notification.length==0">
                <div class="center w70 custom">
                    <ngx-loading class="cust" *ngIf="loader" [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                    <span *ngIf="!loader" class="fnt15">
                        No notifications yet...
                    </span>
                </div>
            </ng-container>
            <div class="center">
                <div class="scrolldiv" *ngIf="scroll">
                    <ngx-loading class="cust" *ngIf="scroll" [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                </div>
            </div>
        </div>
    </div>
</div>