<div class="parent-container">
  <mat-card appearance="outlined" class="example-card">
    <mat-card-header>
      <div class="w-100 mlr-2 heading" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title">Database Processes</span>
        <div class="d-flex justify-content-between search-add">
          <button mat-mini-fab (click)="getDbProcess()" matTooltip="Refresh Processes" class="refresh-btn">
            <mat-icon [ngClass]="{'rotation': loader}">sync</mat-icon>
            <!-- <i class="fa fa-spinner fa-spin"></i> -->
          </button>
          <div class="search">
            <input class="search-input" [formControl]="pname" type="text" name="search"
              placeholder="Search Process Name...">
          </div>
          <!-- <div class="show-deleted">
             Show Deleted
            <mat-slide-toggle class="deleted-row" (change)="getDeleted($event)">
              Show Deleted
            </mat-slide-toggle>
          </div>
          <button mat-raised-button (click)="openDialog('add')">
            Add Attibute
          </button> -->
        </div>
      </div>
    </mat-card-header>
    <mat-card-content class="tabs">
      <div class="table-container">
        <table mat-table matSort [dataSource]="dataSource" class=w-100>
          <ng-container *ngFor='let disCol of displayedColumns'>

            <ng-container *ngIf="disCol == 'Actions'">
              <ng-container matColumnDef="{{disCol}}">
                <th mat-header-cell *matHeaderCellDef>
                  <span class="header Action cp">{{disCol}}</span>
                </th>
                <td mat-cell *matCellDef="let element" class="pl-2 pr-2">
                  <div class="d-flex justify-content-start align-items-center">
                    <button mat-button class="grey-button" (click)="openConfirmation(element)">
                      <span class="material-icons">
                        highlight_off
                      </span>
                      STOP PROCESS</button>
                  </div>
                </td>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="disCol != 'Actions'">
              <ng-container matColumnDef="{{disCol}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  <span style="font-weight: 600;" *ngIf="disCol=='datname'">Database Name</span>
                  <span style="font-weight: 600;" *ngIf="disCol!='datname'"> {{disCol}}</span>
                </th>
                <td mat-cell *matCellDef="let element; let i=index;">
                  <span *ngIf="disCol == 'SN'"> {{i+1}} </span>
                  <div class="query" *ngIf="disCol=='Query'">
                    <p #queryCopy class="more" matTooltip={{returnVal(element,disCol)}}>
                      {{returnVal(element,disCol)}}</p>
                    <!-- <button *ngIf="disCol=='Query'" matTooltip="Copy Query to Clipboard" mat-button class="orange-btn"
                      (click)="copyQuery(queryCopy)">
                      <span class="material-icons">
                        content_copy
                      </span>
                    </button> -->
                  </div>

                  <span *ngIf="disCol!='SN' && disCol!='Query'">{{returnVal(element,disCol)}}</span>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
      </div>
      <div class="w-100" fxLayout="row">
        <mat-paginator class="w-100" [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; handlePagination($event)">
        </mat-paginator>
      </div>
      <div *ngIf="loader" class='loader-div'>
        <ngx-loading [show]="loader"></ngx-loading>
      </div>
    </mat-card-content>
  </mat-card>

</div>