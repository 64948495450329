import { Injectable } from '@angular/core';

@Injectable()
export class GisDataService {
  activeset: string = '';
  constructor() { }

  setActive(active: string): void {
    this.activeset = active;
  }

  getIsActive(key: string) {
    if (this.activeset == key) {
      return true;
    }
    return false;
  }

}
