import { Component, OnInit, ViewChild, AfterViewInit, QueryList, ViewChildren, ElementRef,  ChangeDetectorRef} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { ApiserviceService } from '../apiservice.service';
import { ShareService } from './services/share.service';
import * as $ from 'jquery';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, AfterViewInit {
  watcher: Subscription;
  username: string;
  showside: boolean = true;
  mobileview: boolean = false;
  toggleList: boolean = false;
  notifycounter: number;
  city_name: string = '';
  profile_image: string = 'assets/images/user.png';
  @ViewChild('sidenavsmall') sidenavsmall: MatSidenav;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger>;
  @ViewChild('viewHeightDiv') elementView: ElementRef;
  viewheight: number;
  //@ViewChild(AdminHeaderComponent) header;
  isHandset$: Observable<boolean> = this.media.asObservable().pipe(
    map(
      () =>
        this.media.isActive('xs') ||
        this.media.isActive('sm') ||
        this.media.isActive('lt-md')
    ),
    tap(() => this.changeDetectorRef.detectChanges()))
  constructor(private media: MediaObserver, iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute, private api: ApiserviceService, private element: ElementRef,
    private share: ShareService, private changeDetectorRef: ChangeDetectorRef) {
    // if (localStorage.getItem('profile_image')) {
    //   this.profile_image = localStorage.getItem('profile_image');
    // }
    if (localStorage.getItem('city_detail')) {
      let cityDetail = JSON.parse(localStorage.getItem('city_detail'));
      this.city_name = cityDetail.city_name;
    } else {
      if (localStorage.getItem('city_name')) {
        this.city_name = localStorage.getItem('city_name');
      }
    }
    // this.getNotifications(0, 20, false);
    this.share.adminComp = this;
    // this.watcher = media.subscribe((change: MediaChange) => {
    //   if (change.mqAlias == 'sm') {
    //     this.mobileview = true;
    //   }
    //   if (change.mqAlias == 'xs') {
    //     this.mobileview = true;
    //   }
    // });
    this.activatedRoute.url.subscribe(url => {
      if (this.sidenav) {
        setTimeout(function () {
          this.sidenav.close();
        }.bind(this), 100)
      }
    });
    iconRegistry.addSvgIcon('bell-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/bell.svg'))
      .addSvgIcon('drag1', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/drag.svg'))
      .addSvgIcon('edit-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/edit.svg'))
      .addSvgIcon('delete-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
      .addSvgIcon('move-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/move.svg'))
      .addSvgIcon('shuffle-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/shuffle.svg'))
      .addSvgIcon('down-arrow-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/down_arrow.svg'))
      .addSvgIcon('verified', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/verified.svg'))
      .addSvgIcon('floor', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/floor.svg'))
      .addSvgIcon('unit', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/unit.svg'))
      .addSvgIcon('attach', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/attach.svg'))
      .addSvgIcon('dashboard', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/dashboard.svg'))
      .addSvgIcon('copy', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/copy.svg'))
      .addSvgIcon('cross', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Cross.svg'))
      .addSvgIcon('property-listing-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/property_listing.svg'));
    this.api.user_name.subscribe(name=>{
      if(name){
        this.username = name;
      }else{
        this.username = '';
      }
    })
    this.api.profile_image.subscribe(img=>{
      if(img){
        this.profile_image = img
      }else{
        this.profile_image = 'assets/images/user.png'
      }
      
    })
  }
  // ngOnDestroy() {
  //   this.watcher.unsubscribe();
  // }
  getCity() {
    let cName;
    // if (localStorage.getItem('city_detail')) {
    //   let cityDetail = JSON.parse(localStorage.getItem('city_detail'));
    //   cName = cityDetail.city_name;
    // } else
    //  {
      if (localStorage.getItem('city_name')) {
        cName = localStorage.getItem('city_name');
      // }
    }
    return cName;
  }
  OpenUserMenu() {

  }

  ngOnInit() {
    setTimeout(() => {
      this.viewheight = (this.elementView.nativeElement.offsetHeight);
      this.share.heightService = this.viewheight;
    }, 10);
    this.isHandset$.subscribe(isHandset =>{
      this.mobileview = isHandset;
      // this.share.mobileview = this.mobileview;
   })
  }

  ngAfterViewInit() {
    this.openSideNavSmall();
    // this.triggers.forEach((item) => {
    //   //concole.log(item);
    // })
  }
  openMenu() {
    this.openSideNav()
  }

  openSideNav() {
    if (this.sidenavsmall) {
      this.sidenavsmall.close();
    }
    this.showside = false;
    setTimeout(function () {
      this.sidenav.open();
    }.bind(this), 100)
  }

  openSideNavSmall() {
    if (this.sidenav) {
      this.sidenav.close();
    }
    this.showside = true;
    setTimeout(function () {
      if (this.sidenavsmall) {
        this.sidenavsmall.open();
        setTimeout(() => {
          this.viewheight = (this.elementView.nativeElement.offsetHeight);
          this.share.heightService = this.viewheight;
        }, 10);
      }
    }.bind(this), 100)

  }

  togglelist() {
    this.toggleList = !this.toggleList;
    $('#overlay').css('display', 'block');
  }
  overlay_off() {
    $('#overlay').css('display', 'none');
    this.toggleList = false;
  }
  //Notification Area END
  sidenavClose() {
    this.openSideNavSmall();
  }
  getInitials(e) {
    let str = e;
    if (str) {
      let splited = str.split(' '), newStr: any = [], count = 0;
      if (splited.length >= 2) {
        splited.forEach((v) => {
          if (count < 2) {
            newStr.push(v.charAt(0));
            count++;
          }
        })
      } else {
        let char = splited[0];
        newStr.push(char.charAt(0))
        newStr.push(char.charAt(1))
      }
      let initials = newStr[0] + newStr[1];
      return initials;
    }
  }
}
